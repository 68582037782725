import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  styled,
  TextField,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useAxiosWrapper } from "../../../helpers/axiosWrapper";
import { useRecoilState } from "recoil";
import { codesAtom } from "../../../states";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import { Delete } from "@mui/icons-material";

const CreateBox = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
  flexDirection: "column",
}));

const EmailBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
}));

const SnackbarAlert = React.forwardRef(function SnackbarAlert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Codes = () => {
  const axiosInstance = useAxiosWrapper();
  const [_, setNewCode] = useState("");
  const [openDeleteCode, setOpenDeleteCode] = useState(false);
  const [tempCode, setTempCode] = useState("");
  const [codesArray, setCodesArray] = useRecoilState(codesAtom);
  const [snackPack, setSnackPack] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [codeCreating, setCodeCreating] = useState(false);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClick = (message, variant) => {
    setSnackPack((prev) => [
      ...prev,
      { message, variant, key: new Date().getTime() },
    ]);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const codeCreatingForm = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(codeCreatingForm),
    mode: "onTouched",
  });

  const updateCodes = () => {
    axiosInstance
      .get("codes", {}, {})
      .then((r) => {
        r.data.map((item, i) => {
          Object.assign(item, { id: i + 1 });
          return item;
        });
        setCodesArray(r.data);
      })
      .then(() => setCodeCreating(false));
  };

  useLayoutEffect(() => {
    const controller = new AbortController();

    const getCodes = () => {
      axiosInstance
        .get("codes", {}, { signal: controller.signal })
        .then((r) => {
          r.data.map((item, i) => {
            Object.assign(item, { id: i + 1 });
            return item;
          });
          setCodesArray(r.data);
        })
        .then(() => setCodeCreating(false))
        .catch(() => {});
    };

    getCodes();

    return () => controller.abort();
  }, []);

  function createCode(email) {
    setCodeCreating(true);
    axiosInstance
      .post("codes/", {
        email: email,
      })
      .then((r) => setNewCode(r.data.code))
      .then(() => updateCodes()).catch((err) => {
        console.log(err.response)
    });
  }

  function deleteCode(code) {
    axiosInstance
      .delete("codes/", {
        code: code,
      })
      .then(() => updateCodes())
      .then(() => {
        setTempCode("");
        setOpenDeleteCode(false);
        handleClick("Code deleted");
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setOpenDeleteCode(false);
          handleClick("Code in use", "error");
        }
      });
  }

  const codeColumns = [
    { field: "code", headerName: "🔤 Code" },
    { field: "email", headerName: "📮 Email", minWidth: 150, flex: 2 },
    {
      field: "status",
      headerName: "📊 Used",
      renderCell: (params) => {
        if (params.row.is_used) {
          return (
            <Chip
              sx={{ width: "100%" }}
              variant="outlined"
              color={"success"}
              size="small"
              label={"true"}
            />
          );
        } else {
          return (
            <Chip
              sx={{ width: "100%" }}
              variant="outlined"
              color={"warning"}
              size="small"
              label={"false"}
            />
          );
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => {
            setOpenDeleteCode(true);
            setTempCode(params.row.code);
          }}
        />,
      ],
    },
  ];

  return (
    <Box flex={8} sx={{ height: "78vh", padding: "10px" }}>
      <CreateBox
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Alert style={{ marginBottom: "20px", width: "100%" }} severity="info">
          Be careful while email completion
        </Alert>
        <form
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onSubmit={handleSubmit(
            (r) => {
              createCode(r.email);
            },
            (err) => {
              console.log(err);
            }
          )}
        >
          <EmailBox>
            <Controller
              name={"email"}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  name={"email"}
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label="Email"
                  type="email"
                />
              )}
              control={control}
              defaultValue={""}
            ></Controller>
          </EmailBox>
          <LoadingButton
            loading={codeCreating}
            type={"submit"}
            sx={{ marginLeft: "10px", height: "100%" }}
            onClick={handleSubmit(
              (r) => {
                createCode(r.email);
              },
              (err) => {
                console.log(err);
              }
            )}
          >
            Create
          </LoadingButton>
        </form>
      </CreateBox>
      <DataGrid columns={codeColumns} rows={codesArray} />
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        message={messageInfo ? messageInfo.message : undefined}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <SnackbarAlert
          onClose={handleClose}
          severity="success"
          color={messageInfo ? messageInfo.variant : "success"}
          sx={{ width: "100%" }}
        >
          {messageInfo ? messageInfo.message : undefined}
        </SnackbarAlert>
      </Snackbar>
      <Dialog
        open={openDeleteCode}
        maxWidth={"xs"}
        fullWidth
        onClose={() => setOpenDeleteCode(false)}
      >
        <DialogTitle>{"Delete Code"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteCode(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => deleteCode(tempCode)}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
