import Box from '@mui/material/Box'
import DownloadButton from '../../../components/DownloadButton'
import AppInfoTooltip from '../../../ui/AppInfoTooltip'
import { Button, ButtonGroup, Divider } from '@mui/material'
import { useState } from 'react'
import {
  appUserContractDocumentSigningReOpenSetNotPay,
  appUserContractDocumentSigningReOpenSetPay,
} from '../../../api/user/appUser'
import { handleFileDownload } from '../../../utils/downloadFile'
import useUserHook from '../useUser.hook'

const UserContractDocumentSigningReOpen = ({ contract }) => {
  const [isSending, setIsSending] = useState(false)
  const { fetchUser } = useUserHook()

  const handleDisapproveClick = async () => {
    setIsSending(true)

    try {
      await appUserContractDocumentSigningReOpenSetNotPay(contract.id).then(
        response => {
          fetchUser()
          setIsSending(false)
        },
      )
    } catch (error) {
      console.log('Error: ', error)
      console.log('need show error')
      setIsSending(false)
    }
  }
  const handleApproveClick = async () => {
    setIsSending(true)

    try {
      await appUserContractDocumentSigningReOpenSetPay(contract.id).then(
        response => {
          fetchUser()
          setIsSending(false)
        },
      )
    } catch (error) {
      console.log('Error: ', error)
      console.log('need show error')
      setIsSending(false)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 1,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
        <DownloadButton
          isDisabled={!contract.contract_user}
          text="Download user document"
          handleClick={() => handleFileDownload(contract.contract_user)}
        />
        {!contract.contract_user && (
          <AppInfoTooltip text="User did not upload the file!" type="error" />
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
        <DownloadButton
          isDisabled={!contract.contract_admin}
          text="Download admin document"
          handleClick={() => handleFileDownload(contract.contract_admin)}
        />
        {!contract.contract_admin && (
          <AppInfoTooltip text="No document to sign!" type="error" />
        )}
      </Box>

      <Divider />

      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        <Button disabled={isSending} onClick={handleApproveClick}>
          Approve
        </Button>
        <Button disabled={isSending} onClick={handleDisapproveClick}>
          Disapprove
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default UserContractDocumentSigningReOpen
