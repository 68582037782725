import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone'
import Typography from '@mui/material/Typography'

const UserContractDocumentPreparation = () => {
  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 1,
        height: '100%',
      }}
    >
      <HourglassTopTwoToneIcon />
      Waiting for the user to sign the contract
    </Typography>
  )
}

export default UserContractDocumentPreparation
