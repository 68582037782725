import React, { useRef, useEffect } from 'react'

import { Fancybox as NativeFancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

function AppFancybox(props) {
  const containerRef = useRef(null)

  useEffect(() => {
    const container = containerRef.current

    const delegate = props.delegate || '[data-fancybox]'
    const options = props.options || {}

    NativeFancybox.bind(container, delegate, options)

    return () => {
      NativeFancybox.unbind(container)
      NativeFancybox.close()
    }
  })

  {
    /* пример children'a
    <a data-fancybox="gallery" href="https://lipsum.app/id/60/1600x1200">
        <img src="https://lipsum.app/id/60/200x150" width="200" height="150" />
    </a>
    <a data-fancybox="gallery" href="https://lipsum.app/id/61/1600x1200">
        <img src="https://lipsum.app/id/61/200x150" width="200" height="150" />
    </a>
    */
  }
  return <div ref={containerRef}>{props.children}</div>
}

export default AppFancybox
