import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AppEuStatusButton from '../../ui/AppEuStatusButton'
import AppVerifiedButton from '../../ui/AppVerifiedButton'
import UserAdditionalInfo from '../../components/UserAdditionalInfo'
import {theme} from '../../../theme/defaultTheme'
import ToggleAccessButton from '../../components/ToggleAccessButton'
import useUserHook from './useUser.hook'
import {APPS} from '../../utils/appConstants'
import {IconButton, Tooltip} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import React, {useState} from 'react'
import ChangeCoinsModal from './ChangeCoinsModal'

const UserInfo = ({ user }) => {
  const { fetchUser } = useUserHook()
  const [isEditCoinsOpen, setIsEditCoinsOpen] = useState(false)
  const handleEditCoinsClose = () => setIsEditCoinsOpen(false)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <Typography color={theme.palette.grey[600]} variant="subtitle2">
              ID:
            </Typography>
            <Typography variant="subtitle2">{user.id}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <Typography color={theme.palette.grey[600]} variant="subtitle2">
              Name:
            </Typography>
            <Typography variant="subtitle2">{user.first_name}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <Typography color={theme.palette.grey[600]} variant="subtitle2">
              Surname:
            </Typography>
            <Typography variant="subtitle2">{user.last_name}</Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <Typography color={theme.palette.grey[600]} variant="subtitle2">
              Language:
            </Typography>
            <Typography variant="subtitle2">{user.language}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <Typography color={theme.palette.grey[600]} variant="subtitle2">
              EU:
            </Typography>
            <Typography variant="subtitle2">
              <AppEuStatusButton isEuStatus={user.eu_status} />
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <Typography color={theme.palette.grey[600]} variant="subtitle2">
              Verified:
            </Typography>
            <Typography variant="subtitle2">
              <AppVerifiedButton isVerified={user.verified} />
            </Typography>
          </Box>
        </Box>
      </Box>
      <UserAdditionalInfo user={user} />

      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1, mt: 1 }}>
        <Typography color={theme.palette.grey[600]} variant="subtitle2">
          Coins:
        </Typography>
        <Typography variant="subtitle2">{user.coins}</Typography>
        <Tooltip title="Edit coins" placement="top" arrow>
          <IconButton size='small' onClick={() => setIsEditCoinsOpen(true)}>
            <EditIcon sx={{fontSize: 16}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
        <Typography color={theme.palette.grey[600]} variant="subtitle2">
          Access to the {APPS.CRYPTO.toUpperCase()}/{APPS.FORTIS.toUpperCase()}:
        </Typography>
        <AppEuStatusButton isEuStatus={user.has_crypto_fortis} />
        <ToggleAccessButton userId={user.id} updateFunction={fetchUser} />
      </Box>

      <ChangeCoinsModal isOpen={isEditCoinsOpen} handleModalClose={handleEditCoinsClose} coins={user.coins} />
    </Box>
  )
}

export default UserInfo
