import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import { formatDateToString } from '../../utils/formatDateToString'
import UserContractCard from './UserContractCard'
import { useEffect, useState } from 'react'
import {
  appColor,
  APPS,
  DEPOSIT_STATUSES,
  DEPOSIT_STATUSES_FILTERS,
} from '../../utils/appConstants'
import UserContractDocumentSigning from './user-contract-entities/UserContractDocumentSigning'
import UserContractActive from './user-contract-entities/UserContractActive'
import UserContractCompleted from './user-contract-entities/UserContractCompleted'
import UserContractUpdated from './user-contract-entities/UserContractUpdated'
import UserContractDocumentSigningUpdated from './user-contract-entities/UserContractDocumentSigningUpdated'
import UserContractReCreation from './user-contract-entities/UserContractReCreation'
import UserContractDocumentSigningReOpen from './user-contract-entities/UserContractDocumentSigningReOpen'
import UserContractCreated from './user-contract-entities/UserContractCreated'
import UserContractDocumentPreparation from './user-contract-entities/UserContractDocumentPreparation'
import UserContractDocumentPreparationUpdated from './user-contract-entities/UserContractDocumentPreparationUpdated'
import UserContractDocumentPreparationReOpen from './user-contract-entities/UserContractDocumentPreparationReOpen'
import UserContractPaid from './user-contract-entities/UserContractPaid'
import UserContractNotPayment from './user-contract-entities/UserContractNotPayment'
import { useRecoilState } from 'recoil'
import { mobAppUserAtom } from '../../states/appUser'

const UserContractsChanger = ({
  userId,
  activeType,
  activeStatus,
  handleResetFilters,
}) => {
  const [user] = useRecoilState(mobAppUserAtom)
  const { contracts } = user.info

  const sortedContractsByDate = [...contracts].sort((obj1, obj2) => {
    const date1 = obj1.created_at
    const date2 = obj2.created_at

    if (date1 > date2) {
      return -1
    } else if (date1 < date2) {
      return 1
    } else {
      return 0
    }
  })

  const [currentContacts, setCurrentContacts] = useState(sortedContractsByDate)

  useEffect(() => {
    if (
      activeType.toLowerCase() === 'all' &&
      activeStatus.toLowerCase() === 'all'
    ) {
      return setCurrentContacts(sortedContractsByDate)
    }

    if (
      activeType.toLowerCase() === 'all' &&
      activeStatus.toLowerCase() !== 'all'
    ) {
      return setCurrentContacts(
        sortedContractsByDate.filter(
          c => c.deposit_status.toLowerCase() === activeStatus.toLowerCase(),
        ),
      )
    }

    if (
      activeType.toLowerCase() !== 'all' &&
      activeStatus.toLowerCase() === 'all'
    ) {
      return setCurrentContacts(
        sortedContractsByDate.filter(
          contract => contract.type.toLowerCase() === activeType.toLowerCase(),
        ),
      )
    }

    setCurrentContacts(
      sortedContractsByDate
        .filter(
          contract => contract.type.toLowerCase() === activeType.toLowerCase(),
        )
        .filter(
          c => c.deposit_status.toLowerCase() === activeStatus.toLowerCase(),
        ),
    )
  }, [activeType, activeStatus, user, contracts])

  return (
    <>
      {currentContacts.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: 2,
            padding: 2,
          }}
        >
          <Typography>Contracts are not found!</Typography>
          <Button
            variant="outlined"
            sx={{ width: 'fit-content' }}
            onClick={handleResetFilters}
          >
            Reset filters
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            columnGap: 2,
            rowGap: 1,
          }}
        >
          {currentContacts.map(contract => (
            <UserContractCard
              key={contract.created_at}
              deposit={contract.deposit}
              contractId={contract.id}
              contract_user={contract.contract_user}
              title={contract.title}
              avatar={contract.type[0]}
              avatarBG={() => appColor(contract.type)}
              subtitle={`${formatDateToString(contract.deposit.start_date)} ${
                contract.type.toLowerCase() !== APPS.PRIME.toLowerCase()
                  ? `- ${formatDateToString(contract.deposit.end_date)}`
                  : ''
              }`}
            >
              <UserContractCardInner
                status={contract.deposit_status}
                contract={contract}
                userId={userId}
              />
            </UserContractCard>
          ))}
        </Box>
      )}
    </>
  )
}

const UserContractCardInner = ({ status, contract, userId }) => {
  if (!DEPOSIT_STATUSES_FILTERS.includes(status)) return <Box></Box>

  if (status === DEPOSIT_STATUSES.CREATED)
    return <UserContractCreated contract={contract} userId={userId} />

  if (status === DEPOSIT_STATUSES.NOT_PAYMENT) return <UserContractNotPayment />

  if (status === DEPOSIT_STATUSES.DOCUMENT_PREPARATION)
    return <UserContractDocumentPreparation />

  if (status === DEPOSIT_STATUSES.DOCUMENT_SIGNING)
    return <UserContractDocumentSigning contract={contract} />

  if (status === DEPOSIT_STATUSES.ACTIVE)
    return <UserContractActive contract={contract} userId={userId} />

  if (status === DEPOSIT_STATUSES.COMPLETED) return <UserContractCompleted />

  if (status === DEPOSIT_STATUSES.UPDATED)
    return <UserContractUpdated contract={contract} userId={userId} />

  if (status === DEPOSIT_STATUSES.DOCUMENT_PREPARATION_UPDATED)
    return <UserContractDocumentPreparationUpdated />

  if (status === DEPOSIT_STATUSES.DOCUMENT_SIGNING_UPDATED)
    return <UserContractDocumentSigningUpdated contract={contract} />

  if (status === DEPOSIT_STATUSES.RE_CREATION)
    return <UserContractReCreation contract={contract} userId={userId} />

  if (status === DEPOSIT_STATUSES.DOCUMENT_PREPARATION_RE_OPEN)
    return <UserContractDocumentPreparationReOpen />

  if (status === DEPOSIT_STATUSES.DOCUMENT_SIGNING_RE_OPEN)
    return <UserContractDocumentSigningReOpen contract={contract} />

  if (status === DEPOSIT_STATUSES.PAID) return <UserContractPaid />
}

export default UserContractsChanger
