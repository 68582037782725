import {
  APPS,
  CRYPTO_CURRENCIES,
  FORTIS_CURRENCIES,
  LS_MOB_APP_CURRENT_USER_ID_KEY,
  PLATFORM_LIST,
} from '../../utils/appConstants'
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import AppInput from '../../ui/AppInput'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import {
  appNewDepositCreate,
  appNewPrimeDepositCreate,
} from '../../api/user/appUser'
import useUserHook from './useUser.hook'
import toast, { Toaster } from 'react-hot-toast'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import ruLocale from 'date-fns/locale/ru'

dayjs.extend(customParseFormat)

const TYPES = [
  APPS.FORTIS.toUpperCase(),
  APPS.CRYPTO.toUpperCase(),
  APPS.PRIME.toUpperCase(),
]

const CreateDepositModal = ({ isOpen, handleModalClose }) => {
  const [type, setType] = useState(TYPES[0])
  const handleTypeChange = (e, type) => !!type && setType(type)

  const [fortisCurrency, setFortisCurrency] = useState(FORTIS_CURRENCIES[0])
  const handleFortisCurrencyChange = e => setFortisCurrency(e.target.value)

  const [cryptoCurrency, setCryptoCurrency] = useState(CRYPTO_CURRENCIES[0])
  const handleCryptoCurrencyChange = e => setCryptoCurrency(e.target.value)

  const [deposit, setDeposit] = useState(0)
  const handleDepositChange = e => {
    const numericValue = e.target.value.replace(/[^0-9.]/g, '')
    const dots = numericValue.split('.').length - 1

    dots <= 1 && setDeposit(numericValue)
  }

  const [startDate, setStartDate] = useState(dayjs())
  const handleStartDateChange = value => setStartDate(value)

  const [term, setTerm] = useState(0)
  const handleTermChange = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')

    setTerm(numericValue)
  }

  const [percent, setPercent] = useState(0)
  const handlePercentChange = e => {
    const numericValue = e.target.value.replace(/[^0-9.]/g, '')
    const dots = numericValue.split('.').length - 1

    dots <= 1 && setPercent(numericValue)
  }

  const [platform, setPlatform] = useState(PLATFORM_LIST[0])
  const handlePlatformChange = e => setPlatform(e.target.value)

  const [apiKey, setApiKey] = useState('')
  const handleApiKeyChange = e => setApiKey(e.target.value)

  const [secretKey, setSecretKey] = useState('')
  const handleSecretKeyChange = e => setSecretKey(e.target.value)

  const [isCreating, setIsCreating] = useState(false)
  const { fetchUser } = useUserHook()
  const showErrorNotify = text => toast.error(text)

  const handleNewDepositCreate = async () => {
    setIsCreating(true)

    // TODO: если модалка будет переиспользоваться не на странице пользователя - будет баг из за id
    const userId = localStorage.getItem(LS_MOB_APP_CURRENT_USER_ID_KEY)
    const currency =
      type.toLowerCase() === APPS.FORTIS.toLowerCase()
        ? fortisCurrency
        : cryptoCurrency

    appNewDepositCreate(
      userId,
      type.toUpperCase(),
      +deposit,
      currency.toUpperCase(),
      +term,
      +percent,
      startDate,
    )
      .then(() => {
        fetchUser()
        handleModalClose()
      })
      .catch(() => showErrorNotify('Error of the create deposit!'))
      .finally(() => setIsCreating(false))
  }

  const handleNewPrimeDepositCreate = async () => {
    setIsCreating(true)

    // TODO: если модалка будет переиспользоваться не на странице пользователя - будет баг из за id
    const userId = localStorage.getItem(LS_MOB_APP_CURRENT_USER_ID_KEY)

    appNewPrimeDepositCreate(userId, platform.toUpperCase(), apiKey, secretKey)
      .then(() => {
        fetchUser()
        handleModalClose()
      })
      .catch(error => {
        let errorMessage = 'Error of the create deposit!'

        if (
          error.response &&
          error.response.data &&
          error.response.data.error_attributes
        ) {
          const errorAttributes = error.response.data.error_attributes

          if (errorAttributes.api_key) {
            errorMessage = `API Key Error: ${errorAttributes.api_key[0]}`
          } else if (errorAttributes.secret_key) {
            errorMessage = `Secret Key Error: ${errorAttributes.secret_key[0]}`
          }
        }

        showErrorNotify(errorMessage)
      })
      .finally(() => setIsCreating(false))
  }

  return (
    <Dialog sx={{ minWidth: '300px' }} open={isOpen} onClose={handleModalClose}>
      <DialogTitle>
        <Typography variant="subtitle1" fontWeight="bold">
          Create new deposit
        </Typography>
      </DialogTitle>
      <Divider />

      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            rowGap: 2,
          }}
        >
          <ToggleButtonGroup
            color="primary"
            value={type}
            exclusive
            disabled={isCreating}
            onChange={handleTypeChange}
          >
            {TYPES.map(type => (
              <ToggleButton size="small" value={type} key={type.id}>
                {type}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {type.toLowerCase() !== APPS.PRIME.toLowerCase() && (
            <>
              {type.toLowerCase() === APPS.FORTIS.toLowerCase() && (
                <FormControl sx={{ minWidth: '300px' }}>
                  <InputLabel id="fortis-currency-label">Currency</InputLabel>
                  <Select
                    labelId="fortis-currency-label"
                    id="fortis-currency"
                    sx={{ minWidth: '300px' }}
                    value={fortisCurrency}
                    label="Currency"
                    onChange={handleFortisCurrencyChange}
                  >
                    {FORTIS_CURRENCIES.map(currency => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {type.toLowerCase() === APPS.CRYPTO.toLowerCase() && (
                <FormControl sx={{ minWidth: '300px' }}>
                  <InputLabel id="crypto-currency-label">Currency</InputLabel>
                  <Select
                    labelId="crypto-currency-label"
                    id="crypto-currency"
                    value={cryptoCurrency}
                    label="Currency"
                    onChange={handleCryptoCurrencyChange}
                  >
                    {CRYPTO_CURRENCIES.map(currency => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <AppInput
                sx={{ minWidth: '300px' }}
                label="Deposit"
                variant="outlined"
                value={deposit}
                disabled={isCreating}
                handleChange={handleDepositChange}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={['DatePicker']}
                  adapterLocale={ruLocale}
                >
                  <DatePicker
                    sx={{ minWidth: '300px' }}
                    label="Start Date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={props => <TextField {...props} />}
                    format="DD.MM.YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>

              <AppInput
                sx={{ minWidth: '300px' }}
                label="Term mon."
                variant="outlined"
                value={term}
                disabled={isCreating}
                handleChange={handleTermChange}
              />

              <AppInput
                sx={{ minWidth: '300px' }}
                label="Total Percent"
                variant="outlined"
                value={percent}
                disabled={isCreating}
                handleChange={handlePercentChange}
              />
            </>
          )}

          {type.toLowerCase() === APPS.PRIME.toLowerCase() && (
            <>
              <FormControl sx={{ minWidth: '300px' }}>
                <InputLabel id="prime-platform-label">Platform</InputLabel>
                <Select
                  labelId="prime-platform-label"
                  id="prime-platform"
                  sx={{ minWidth: '300px' }}
                  value={platform}
                  label="Platform"
                  onChange={handlePlatformChange}
                >
                  {PLATFORM_LIST.map(platform => (
                    <MenuItem key={platform} value={platform}>
                      {platform}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <AppInput
                sx={{ minWidth: '300px' }}
                label="API key"
                variant="outlined"
                value={apiKey}
                disabled={isCreating}
                handleChange={handleApiKeyChange}
              />

              <AppInput
                sx={{ minWidth: '300px' }}
                label="Secret key"
                variant="outlined"
                value={secretKey}
                disabled={isCreating}
                handleChange={handleSecretKeyChange}
              />
            </>
          )}
        </Box>
      </DialogContent>

      <Divider />
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={
            ((!deposit || !term || !percent || isCreating) &&
              type.toLowerCase() !== APPS.PRIME.toLowerCase()) ||
            ((!platform || !apiKey || !secretKey) &&
              type.toLowerCase() === APPS.PRIME.toLowerCase())
          }
          onClick={
            type.toLowerCase() === APPS.PRIME.toLowerCase()
              ? handleNewPrimeDepositCreate
              : handleNewDepositCreate
          }
        >
          Create deposit
        </Button>
      </DialogActions>

      <Toaster position="top-right" />
    </Dialog>
  )
}

export default CreateDepositModal
