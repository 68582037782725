import Box from "@mui/material/Box";
import { useRecoilState } from "recoil";
import { mobAppUsersAtom } from "../../states/appUsers";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UserDocumentsChanger from "../../components/UserDocumentsChanger";
import Typography from "@mui/material/Typography";
import UserInfo from "./UserInfo";
import { LS_MOB_APP_CURRENT_USER_ID_KEY } from "../../utils/appConstants";
import toast, { Toaster } from "react-hot-toast";
import UserContracts from "./UserContracts";
import Button from "@mui/material/Button";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import NotificationModal from "../../components/NotificationModal";
import { useNotification } from "../../hooks/useNotification";
import useUserHook from "./useUser.hook";
import IconButton from "@mui/material/IconButton";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CreateDepositModal from "./CreateDepositModal";
import AppUpdatePageButton from "../../ui/AppUpdatePageButton";
import RemoveUserModal from "../../components/RemoveUserModal";
import { Delete } from "@mui/icons-material";
import ReportFileUpload from "./ReportFileUpload";

const UsersList = ({ users, selectedUser, setSelectedUser, fetchUser }) => {
  const selectedUserAsString = selectedUser
    ? `${selectedUser.id}. ${selectedUser.first_name} ${selectedUser.last_name}`
    : "";
  const handleUserChange = (user) => {
    const userId = +user?.split(".")[0].trim();
    const isUserExist = users.some((u) => u.id === userId);

    if (!isUserExist) {
      return;
    }

    setSelectedUser(users.find((u) => u.id === userId));
    localStorage.setItem(LS_MOB_APP_CURRENT_USER_ID_KEY, userId);
    fetchUser(userId);
  };

  useEffect((e) => {
    handleUserChange(selectedUserAsString);
  }, []);

  return (
    <Autocomplete
      sx={{ width: "300px" }}
      freeSolo
      disableClearable
      options={users.map(
        (user) => `${user.id}. ${user.first_name} ${user.last_name}`
      )}
      defaultValue={selectedUserAsString}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search user"
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
      onChange={(e, user) => handleUserChange(user)}
    />
  );
};

const UserView = () => {
  const [users] = useRecoilState(mobAppUsersAtom);
  const [selectedUser, setSelectedUser] = useState(
    users.find(
      (user) =>
        user.id === +localStorage.getItem(LS_MOB_APP_CURRENT_USER_ID_KEY)
    ) ?? users[0]
  );

  const { fetchUser, currentUser, isUserFetching } = useUserHook();

  const showErrorNotify = (text) => toast.error(text);
  const showSuccessNotify = (text) => toast.success(text);

  const {
    notification,
    activeNotificationReceiver,
    activeNotificationType,
    isConfirmModalOpen,
    openConfirmModal,
    closeConfirmModal,
    isNotificationSending,
    handleNotificationChange,
    handleNotificationTypeTabClick,
    handleSendPersonalNotification,
    handleSendNotificationForAllUsers,
  } = useNotification({
    userId: currentUser?.id,
    showSuccessNotify,
    showErrorNotify,
  });

  const [isCreateDepositModalOpen, setIsCreateDepositModalOpen] =
    useState(false);
  const handleCreateModalOpen = () => setIsCreateDepositModalOpen(true);
  const handleCreateModalClose = () => setIsCreateDepositModalOpen(false);

  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
  const handleRemoveUserModalClose = () => setIsRemoveUserModalOpen(false);
  const handleRemoveUserModalOpen = () => setIsRemoveUserModalOpen(true);

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
          <UsersList
            users={users}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            fetchUser={fetchUser}
          />
          <Tooltip title="Remove user" placement="bottom" arrow>
            <IconButton color="primary" onClick={handleRemoveUserModalOpen}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>

        <Button
          component="label"
          variant="contained"
          startIcon={<SendToMobileIcon />}
          onClick={openConfirmModal}
        >
          Send notification
        </Button>
      </Box>

      {JSON.stringify(currentUser) !== "{}" && (
        <>
          <UserInfo user={currentUser} />

          <Divider />
          <Box>
            <Typography variant="subtitle1">Referrals:</Typography>
            {currentUser.referrals?.length === 0 && (
              <Typography>User has not referrals</Typography>
            )}
            {currentUser.referrals?.length > 0 &&
              currentUser.referrals.map((referral, index) => (
                <Typography key={referral.id}>
                  {referral.fio}
                  {index + 1 !== currentUser.referrals.length && ","}
                </Typography>
              ))}
          </Box>

          <Divider />
          <Box>
            <Typography variant="subtitle1">Documents:</Typography>
            <UserDocumentsChanger
              documents={currentUser.documents}
              name={`${currentUser.first_name} ${currentUser.last_name}`}
              userId={currentUser.id}
              isVerified={currentUser.verified}
              updateDataFunction={fetchUser}
            />
          </Box>

          <Divider />

          <Box
            sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}
          >
            <Typography variant="subtitle1">Upload Report:</Typography>
            <ReportFileUpload />
          </Box>

          <Divider />
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
              <Typography variant="subtitle1">Contracts:</Typography>
              <IconButton variant="contained" onClick={handleCreateModalOpen}>
                <ControlPointIcon />
              </IconButton>
            </Box>
            {currentUser.contracts.length === 0 ? (
              <Typography>User has not contracts!</Typography>
            ) : (
              <UserContracts />
            )}
          </Box>
        </>
      )}

      <Backdrop open={isUserFetching} sx={{ color: "#fff", zIndex: 3 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <NotificationModal
        isOpen={isConfirmModalOpen}
        notification={notification}
        selectedUser={currentUser}
        isConfirmActionDisabled={isNotificationSending}
        activeNotificationReceiver={activeNotificationReceiver}
        activeNotificationType={activeNotificationType}
        handleNotificationChange={handleNotificationChange}
        handleCloseModal={closeConfirmModal}
        handleNotificationTypeTabClick={handleNotificationTypeTabClick}
        handleSendPersonalNotification={handleSendPersonalNotification}
        handleSendNotificationForAllUsers={handleSendNotificationForAllUsers}
      />

      <CreateDepositModal
        isOpen={isCreateDepositModalOpen}
        handleModalClose={handleCreateModalClose}
      />

      <RemoveUserModal
        isOpen={isRemoveUserModalOpen}
        handleClose={handleRemoveUserModalClose}
        user={currentUser}
      />

      <AppUpdatePageButton
        tooltipText="Update user page."
        updateFunction={fetchUser}
      />

      <Toaster position="top-right" />
    </Box>
  );
};

export default UserView;
