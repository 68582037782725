import React, { useState } from 'react'
import { Switch, Box, Typography, Button } from '@mui/material'
import toast, { Toaster } from 'react-hot-toast'
import useUserHook from '../useUser.hook'
import { appPrimeCommissionVisibilityUpdate } from '../../../api/user/appUser'

const UserDepositCommissionVisibilityChange = ({ deposit }) => {
  const [isActive, setIsActive] = useState(deposit.pay_commission ?? false)
  const handleVisibilityToggle = () => setIsActive(prev => !prev)
  const { fetchUser } = useUserHook()

  const showErrorNotify = text => toast.error(text)

  const [inProgress, setInProgress] = useState(false)
  const handleSaveChanges = async () => {
    setInProgress(true)

    await appPrimeCommissionVisibilityUpdate(deposit.id, isActive)
      .then(() => fetchUser())
      .catch(() => showErrorNotify("Error the visibility didn't change!"))
      .finally(() => setInProgress(false))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        rowGap: 1,
      }}
    >
      <Typography variant="subtitle2">
        Display commission payment requirements
      </Typography>
      <Switch
        checked={isActive}
        onChange={handleVisibilityToggle}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Button
        variant="contained"
        disabled={+isActive === +deposit.pay_commission || inProgress}
        onClick={handleSaveChanges}
      >
        Save changes
      </Button>

      <Toaster position="top-right" />
    </Box>
  )
}

export default UserDepositCommissionVisibilityChange
