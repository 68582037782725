import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import React, { useLayoutEffect } from "react";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useAxiosWrapper } from "../../../../../helpers/axiosWrapper";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

export const CryptoSafe = () => {
  const classes = useStyles();
  const axiosWrapper = useAxiosWrapper();
  const [yearPercentage, setYearPercentage] = React.useState();

  const CryptoSafe = Yup.object().shape({
    yearPercentage: Yup.number()
      .min(0.1, "Total percentage must be greater than 0")
      .required("Total percentage is required")
      .typeError("Total percentage must be a number"),
  });

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(CryptoSafe),
    mode: "onTouched",
  });

  useLayoutEffect(() => {
    axiosWrapper.get("getYearPercent").then((res) => {
      setYearPercentage(res.data.yearpercent);
    });
  }, []);

  const handleChangeYearPercent = (data) => {
    axiosWrapper
      .post("cryptosafe/yearPercent", {
        yearpercent: data.yearPercentage,
      })
      .then(() => setYearPercentage(data.yearPercentage));
  };

  return (
    <Box flex={8} sx={{ height: "75vh" }}>
      <form onSubmit={handleSubmit(handleChangeYearPercent)}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Controller
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                name={"yearPercentage"}
                label={"Year Percent"}
                inputRef={ref}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>%</InputAdornment>
                  ),
                }}
                inputProps={{
                  min: "0",
                  max: "100",
                }}
                className={classes.input}
                InputLabelProps={{ shrink: true }}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
            name={"yearPercentage"}
            control={control}
            defaultValue={yearPercentage}
          />
          <Button
            type="submit"
            onClick={() =>
              handleSubmit(handleChangeYearPercent, (e) => console.log(e))
            }
            sx={{ ml: "10px" }}
          >
            Save
          </Button>
        </Box>
      </form>
      {yearPercentage && (
        <Typography sx={{ mt: "10px" }}>
          current year percentage: {yearPercentage}
        </Typography>
      )}
    </Box>
  );
};
