import IconButton from '@mui/material/IconButton'
import SendToMobileIcon from '@mui/icons-material/SendToMobile'
import { Tooltip } from '@mui/material'

const SendNotificationTableButton = ({ userName, openConfirmModal }) => {
  return (
    <Tooltip
      title={`Send personal notification to user ${userName}.`}
      placement="top"
      arrow
    >
      <IconButton onClick={openConfirmModal}>
        <SendToMobileIcon />
      </IconButton>
    </Tooltip>
  )
}

export default SendNotificationTableButton
