import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { memo, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAxiosWrapper } from "../../../../helpers/axiosWrapper";
import { Delete } from "@mui/icons-material";
import moment from "moment";
import { CSRenameDialog } from "./CSRenameDialog";
import { CSChangePercent } from "./CSChangePercent";
import { IncomeCalculator } from "../../../../utils/IncomeCalculator";
import Alert from "@mui/material/Alert";
import { CSChangeDate } from "./CSChangeDate";

const PaperContainer = styled(Paper)(() => ({
  height: "100px",
  width: "150px",
  margin: "10px",
  display: "flex",
  flexDirection: "column",
}));

export const CryptoSafeCard = memo(({ cryptosafe, setCryptoSafe }) => {
  const [CryptoSafeProduct, setCryptoSafeProduct] = React.useState();
  const [uploading, setUploading] = React.useState(false);
  const axiosWrapper = useAxiosWrapper();
  const [expanded, setExpanded] = React.useState(false);
  const [deleting, setDeleting] = useState(false);
  const calc = new IncomeCalculator(
    CryptoSafeProduct?.sum,
    CryptoSafeProduct?.start_date,
    CryptoSafeProduct?.end_date,
    CryptoSafeProduct?.total_percent
  );
  const [uploadError, setUploadError] = React.useState("");

  CryptoSafeProduct &&
    console.log(
      moment(CryptoSafeProduct.end_date * 1000).diff(
        moment(CryptoSafeProduct.start_date * 1000),
        "days"
      )
    );

  const handleChange = (event) => {
    console.log(event.target.value);
    setCryptoSafeProduct(JSON.parse(event.target.value));
  };

  const handleDelete = () => {
    setDeleting(true);
    axiosWrapper
      .delete("cryptosafe/" + CryptoSafeProduct.docs.id)
      .then(() => {
        setCryptoSafe(
          cryptosafe.filter((cs) => cs.docs.id !== CryptoSafeProduct.docs?.id)
        );
        setCryptoSafeProduct(
          cryptosafe[
            cryptosafe.findIndex(
              (item) => item.docs.id === CryptoSafeProduct?.docs.id
            ) > 0
              ? cryptosafe.findIndex(
                  (item) => item.docs.id === CryptoSafeProduct?.docs.id
                ) - 1
              : 1
          ]
        );
      })
      .then(() => setDeleting(false));
  };

  useEffect(() => console.log(deleting), [deleting]);

  useEffect(() => {
    console.log(
      "product:",
      CryptoSafeProduct && Object.keys(CryptoSafeProduct).length > 0
    );
    console.log("expanded:", expanded);
    setExpanded(!!CryptoSafeProduct);
  }, [CryptoSafeProduct]);

  const handleUpload = (event) => {
    setUploadError("");
    setUploading(true);

    console.log(event.target.files[0]);

    if (event.target.files[0].size > 7000000) {
      setUploadError("size_error");
      setUploading(false);
    } else {
      let formdata = new FormData();
      formdata.append(
        "file",
        event.target.files[0],
        event.target.files[0].name
      );
      let headers = {
        "Content-Type": `image/jpeg`,
      };
      axiosWrapper
        .post(
          "users/docs_admin/" + CryptoSafeProduct.docs.id,
          formdata,
          headers
        )
        .then(() => setUploading(false));
    }
  };

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            onClick={() => {
              if (CryptoSafeProduct) {
                setExpanded((prev) => !prev);
              }
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <Typography>CryptoSafe</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControl
              sx={{
                width: "100%",
                maxWidth: "300px",
                ml: { xs: "unset", md: "10px" },
              }}
              variant="standard"
              size={"small"}
            >
              <Select
                sx={{ minWidth: "150px" }}
                autoWidth
                onClick={(event) => event.stopPropagation()}
                value={
                  CryptoSafeProduct ? JSON.stringify(CryptoSafeProduct) : ""
                }
                defaultValue={CryptoSafeProduct}
                label="Age"
                onChange={handleChange}
              >
                {cryptosafe.map((product, index) => (
                  <MenuItem key={index} value={JSON.stringify(product)}>
                    {product.contract_name === ""
                      ? product.docs.id
                      : `${product.docs.id} - ${product.contract_name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {CryptoSafeProduct && (
              <CSRenameDialog
                contractType={"cryptosafe"}
                doc_id={CryptoSafeProduct?.docs.id}
                setCryptoSafeProduct={setCryptoSafeProduct}
                setCryptoSafe={setCryptoSafe}
                cryptosafe={cryptosafe}
                currentName={CryptoSafeProduct?.contract_name}
              />
            )}
          </Box>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {CryptoSafeProduct && CryptoSafeProduct?.start_date > 0 ? (
          <>
            <div
              style={{
                height: "fit-content",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <PaperContainer>
                <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                  Deposit
                </Typography>
                <Typography
                  sx={{
                    alignSelf: "center",
                    margin: "auto",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "1.5rem",
                    wordWrap: "anywhere",
                  }}
                  variant={"h4"}
                >
                  ${CryptoSafeProduct ? CryptoSafeProduct.sum.toFixed(2) : "0"}
                </Typography>
              </PaperContainer>
              <PaperContainer>
                <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                  Term
                </Typography>
                <Typography
                  sx={{
                    alignSelf: "center",
                    margin: "auto",
                    fontSize: "1.8rem",
                  }}
                  variant={"h4"}
                >
                  {CryptoSafeProduct.term % 12
                    ? CryptoSafeProduct.term + " " + "m."
                    : CryptoSafeProduct.term / 12 + " " + "yr."}
                </Typography>
              </PaperContainer>
              <PaperContainer>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                    Total Percentage
                  </Typography>
                  <CSChangePercent
                    setCryptoSafe={setCryptoSafe}
                    cryptosafe={cryptosafe}
                    setCryptoSafeProduct={setCryptoSafeProduct}
                    doc_id={CryptoSafeProduct.docs.id}
                    CryptoSafeProduct={CryptoSafeProduct}
                  />
                </Box>
                <Typography
                  sx={{
                    alignSelf: "center",
                    margin: "auto",
                    fontSize: "1.7rem",
                  }}
                  variant={"h4"}
                >
                  {CryptoSafeProduct
                    ? CryptoSafeProduct.total_percent.toFixed(2)
                    : "0"}
                  %
                </Typography>
              </PaperContainer>
              <Paper
                sx={{
                  minHeight: "120px",
                  height: "fit-content",
                  width: "100%",
                  margin: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                    Current contract duration
                  </Typography>
                  <CSChangeDate
                    setCryptoSafeProduct={setCryptoSafeProduct}
                    doc_id={CryptoSafeProduct.docs.id}
                    setCryptoSafe={setCryptoSafe}
                    start_date={CryptoSafeProduct.start_date}
                    end_date={CryptoSafeProduct.end_date}
                    term={CryptoSafeProduct.term}
                  />
                </Box>
                <Typography
                  sx={{
                    alignSelf: "center",
                    margin: "auto",
                    fontSize: "1.7rem",
                    textAlign: "center",
                    wordWrap: "anywhere",
                  }}
                  variant={"h4"}
                >
                  {CryptoSafeProduct &&
                    `${new Date(
                      CryptoSafeProduct.start_date * 1000
                    ).toLocaleDateString("ru-RU", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}`}{" "}
                  -{" "}
                  {CryptoSafeProduct &&
                    `${new Date(
                      CryptoSafeProduct.end_date * 1000
                    ).toLocaleDateString("ru-RU", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}`}
                </Typography>
              </Paper>
              <PaperContainer>
                <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                  Balance
                </Typography>
                <Typography
                  sx={{
                    alignSelf: "center",
                    margin: "auto",
                    fontSize: "1.5rem",
                    wordWrap: "anywhere",
                    textAlign: "center",
                  }}
                  variant={"h4"}
                >
                  ${calc.getBalance().toFixed(2)}
                </Typography>
              </PaperContainer>
              <PaperContainer>
                <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                  Income Percentage
                </Typography>
                <Typography
                  sx={{
                    alignSelf: "center",
                    margin: "auto",
                    fontSize: "1.7rem",
                    textAlign: "center",
                  }}
                  variant={"h4"}
                >
                  {calc.getIncomePercent().toFixed(2)}%
                </Typography>
              </PaperContainer>
              <PaperContainer>
                <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                  Total Income
                </Typography>
                <Typography
                  sx={{
                    alignSelf: "center",
                    margin: "auto",
                    fontSize: "1.6rem",
                    wordWrap: "anywhere",
                    textAlign: "center",
                  }}
                  variant={"h4"}
                >
                  ${calc.getIncome().toFixed(2)}
                </Typography>
              </PaperContainer>
            </div>

            {CryptoSafeProduct.docs && (
              <>
                {uploadError === "size_error" && (
                  <Alert sx={{ mt: "20px" }} severity="error">
                    File size is too big. Max size is 7MB.
                  </Alert>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    marginTop: "20px",
                  }}
                >
                  <div>
                    <LoadingButton
                      loadingIndicator="Uploading…"
                      loading={uploading}
                      component="label"
                    >
                      Upload contract
                      <input
                        onChange={handleUpload}
                        hidden
                        accept="application/pdf"
                        type="file"
                      />
                    </LoadingButton>
                    <Button
                      disabled={!CryptoSafeProduct.docs.signed_contract}
                      onClick={() =>
                        window
                          .open(
                            CryptoSafeProduct.docs.signed_contract,
                            "_blank"
                          )
                          .focus()
                      }
                    >
                      Download contract
                    </Button>
                  </div>
                  <LoadingButton
                    loadingPosition="start"
                    loading={deleting}
                    variant={"contained"}
                    startIcon={<Delete />}
                    onClick={handleDelete}
                  >
                    Delete
                  </LoadingButton>
                </Box>
              </>
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography>Payment in progress...</Typography>
            <LoadingButton
              sx={{ marginTop: "10px" }}
              loadingPosition="start"
              loading={deleting}
              variant={"contained"}
              startIcon={<Delete />}
              onClick={handleDelete}
            >
              Delete
            </LoadingButton>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
});
