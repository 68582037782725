import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { Tabs } from '@mui/material'
import toast, { Toaster } from 'react-hot-toast'
import { appGetAllNotifications } from '../../api/notifications/appNotifications'
import NotificationsTable from './NotificationsTable'
import Tab from '@mui/material/Tab'
import NotificationSend from './NotificationSend'
import AppReloadButton from '../../ui/AppReloadButton'

const NotificationsView = () => {
  const [notifications, setNotifications] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const showErrorNotify = text => toast.error(text)

  const [activeTab, setActiveTab] = useState('Recieved')
  const handleChangeTab = (e, tab) => setActiveTab(tab)

  const fetchNotifications = async () => {
    setIsFetching(true)

    try {
      await appGetAllNotifications().then(response => {
        setNotifications(response.data)
        console.log('response.data', response.data)
      })
    } catch (error) {
      showErrorNotify('Error notifications fetching!')
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    fetchNotifications()
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: 1,
        }}
      >
        <Tabs value={activeTab} onChange={handleChangeTab}>
          <Tab value="Recieved" label="Recieved" />
          <Tab value="Send" label="Send" />
        </Tabs>
        <AppReloadButton
          isLoading={isFetching}
          handleClick={fetchNotifications}
        />
      </Box>

      {activeTab === 'Recieved' && (
        <NotificationsTable notifications={notifications} />
      )}
      {activeTab === 'Send' && <NotificationSend />}

      <Toaster position="top-right" />
    </Box>
  )
}

export default NotificationsView
