import React from 'react'
import { theme } from '../../theme/defaultTheme'
import Typography from '@mui/material/Typography'
import { Box, Button } from '@mui/material'

const AppNotFoundData = ({ handleReset }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 2,
        padding: 2,
        backgroundColor: theme.palette.secondary.light,
      }}
    >
      <Typography>Data not found!</Typography>
      <Button variant="outlined" onClick={handleReset}>
        Reset filters
      </Button>
    </Box>
  )
}

export default AppNotFoundData
