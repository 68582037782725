import { useEffect, useState } from "react";
import { appGetUsers } from "../../api/users/appUsers";
import { useRecoilState } from "recoil";
import { mobAppUsersAtom } from "../../states/appUsers";
import { Badge, Box, InputAdornment, Tabs, TextField } from "@mui/material";
import UsersTable from "./UsersTable";
import Tab from "@mui/material/Tab";
import toast, { Toaster } from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import AppNotFoundData from "../../ui/AppNotFoundData";
import AppReloadButton from "../../ui/AppReloadButton";
import { LS_USERS } from "../../utils/appConstants";

const UsersView = () => {
    const [isFetching, setIsFetching] = useState(true);
    const showErrorNotify = () => toast.error("Error fetching users!");

    const [users, setUsers] = useRecoilState(mobAppUsersAtom);
    const [currentUsers, setCurrentUsers] = useState(users);
    const unverifiedUsersAmount = users.filter((user) => !user.verified).length;

    const [searchTerm, setSearchTerm] = useState("");
    const handleSearch = (event) =>
        setSearchTerm(event.target.value.toLowerCase().trim());

    const [activeTab, setActiveTab] = useState("All");
    const handleChangeTab = (e, newValue) => setActiveTab(newValue);

    const handleResetFilters = () => {
        setSearchTerm("");
        setActiveTab("All");
    };

    const fetchUsers = async () => {
        setIsFetching(true);

        try {
            await appGetUsers()
                .then((res) => {
                    localStorage.setItem(LS_USERS, JSON.stringify(res.data));
                    setUsers(res.data);
                })
                .then(() => setIsFetching(false));
        } catch (error) {
            console.log("Error: ", error);
            setIsFetching(false);
            showErrorNotify();
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        const filtered = (filteredByVerifyUsers) => {
            if (searchTerm.length === 0) {
                return filteredByVerifyUsers;
            }

            return filteredByVerifyUsers.filter(
                (user) =>
                    user.first_name.toLowerCase().includes(searchTerm) ||
                    user.last_name.toLowerCase().includes(searchTerm),
            );
        };

        switch (activeTab) {
            case "All":
                setCurrentUsers(() => filtered(users));
                return;
            case "Verified":
                setCurrentUsers(() => filtered(users.filter((user) => user.verified)));
                return;
            case "Unverified":
                setCurrentUsers(() => filtered(users.filter((user) => !user.verified)));
                return;
            default:
                setCurrentUsers(users);
        }
    }, [activeTab, searchTerm, users]);

    return (
        <Box sx={{ width: "100%" }}>
            <TextField
                style={{ marginBottom: 20 }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                label="Search by Name or Surname"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleSearch}
            />

            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    columnGap: 2,
                    borderBottom: 1,
                    borderColor: "divider",
                }}
            >
                <Tabs
                    value={activeTab}
                    onChange={handleChangeTab}
                    aria-label="wrapped label tabs example"
                >
                    <Tab value="All" label="All Users" />
                    <Tab value="Verified" label="Verified" />
                    <Tab
                        value="Unverified"
                        label={
                            <Badge
                                color="error"
                                badgeContent={unverifiedUsersAmount}
                                max={99}
                            >
                                Unverified &nbsp;
                            </Badge>
                        }
                    />
                </Tabs>
                <AppReloadButton isLoading={isFetching} handleClick={fetchUsers} />
            </Box>

            <UsersTable
                rows={currentUsers}
                isFetching={isFetching}
                updateTable={fetchUsers}
            />
            {currentUsers.length === 0 && !isFetching && (
                <AppNotFoundData handleReset={handleResetFilters} />
            )}

            <Toaster position="top-right" />
        </Box>
    );
};

export default UsersView;
