export const appEndpoints = {
  AUTH: {
    ADMIN_LOGIN: '/auth/admin/login',
    ADMIN_REFRESH: '/auth/admin/refresh',
  },
  USERS: {
    GET: '/users/all-profile',
  },
  USER: {
    GET: '/users/definite-profile',
    REMOVE: 'users/admin/delete-account',

    UPDATE_ACCESS: 'users/admin/update-access-products',

    UPLOAD_REPORT: 'deposit-reports/upload',

    CHANGE_CONTRACT_TITLE: 'user-contracts/update-title',
    CONTRACT_UPLOAD: '/user-contracts/upload/admin',
    CONTRACT_UPLOAD_RE_OPEN: '/user-contracts/upload/admin/re-open',
    CONTRACT_UPLOAD_UPDATE: '/user-contracts/upload/admin/update',

    CONTRACT_CHECK_NOT_PAY: '/user-contracts/check-not-pay',
    CONTRACT_CHECK: '/user-contracts/check',
    CONTRACT_CHECK_RE_OPEN: '/user-contracts/check-re-open',
    CONTRACT_CHECK_UPDATE: '/user-contracts/check-update',

    CONTRACT_UPDATE: '/user-contracts/update-user-doc', // не изменяет статус контракта

    DOCUMENTS_VERIFY: '/documents/verify',
  },
  USER_DEPOSITS: {
    CHANGE_RATE: 'user-deposits/update-rate',
    CREATE_DEPOSIT: 'user-deposits/create-admin',
    CREATE_PRIME_DEPOSIT: 'user-deposits-prime/create-admin',
    UPDATE_PAY_COMMISSION_PRIME_DEPOSIT: 'user-deposits-prime/update-payment',
    UPDATE_AMOUNT_PRIME_DEPOSIT: 'user-deposits-prime/update-amount-start',
    REMOVE_DEPOSIT: 'user-deposits/admin/del-deposit', // только для FORTIS и CRYPTO
    REMOVE_PRIME_DEPOSIT: 'user-deposits-prime/admin/del-deposit', // только для PRIME
    TOGGLE_PRIME_APPROVAL: "user-deposits-prime/ready-use", // только для PRIME
  },
  SETTINGS: {
    GET: '/general/all-admins',
    GET_ALL_DEPOSITS: 'deposits/all',
    DEPOSIT_PERCENT_OR_COMMISSION_CHANGE: 'deposits/update-definite',
    TOGGLE_TECHNICAL_WORKS: '/general/change-technical',
  },
  NOTIFICATIONS: {
    GET_ALL: '/admin-notifications',
    PERSONAL: '/notifications/create-for-definite',
    FOR_ALL_USERS: '/notifications/create-for-all',
  },
  FINANCE: {
    ALL_TRANSACTIONS: '/withdrawal-transactions/all',
    ALL_COMMISSIONS: '/commission-payment-transactions/all',
    SET_CHECK_TRANSACTION_STATUS: 'withdrawal-transactions/check',
    SET_PAID_TRANSACTION_STATUS: 'withdrawal-transactions/paid',
    SET_REFUSAL_TRANSACTION_STATUS: 'withdrawal-transactions/refusal',
    SET_TRANSACTION_PAY: '/commission-payment-transactions/paid',
    UPLOAD_FILE: '/withdrawal-transactions/upload/admin',
    SET_COINS: 'coins/total-coin'
  },
  KEYS_PAY: {
    GET_ALL: '/keys-pay/all',
    GET_DEFINITE: 'keys-pay/definite',
    CREATE: 'keys-pay/create',
    UPDATE: 'keys-pay/update',
    DELETE: 'keys-pay/delete',
  },
}
