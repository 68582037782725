import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

const AppEuStatusButton = ({ isEuStatus }) => {
  return (
    <>
      {isEuStatus ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
    </>
  )
}

export default AppEuStatusButton
