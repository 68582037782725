import Box from '@mui/material/Box'
import { Button, Divider } from '@mui/material'
import AppInfoTooltip from '../../../ui/AppInfoTooltip'
import AppUploadFileButtons from '../../../ui/AppUploadFileButtons'
import { DEPOSIT_STATUSES } from '../../../utils/appConstants'
import {
  appUserContractCreatedStatusSetNotPay,
  appUserContractUploadDocument,
} from '../../../api/user/appUser'
import { useState } from 'react'
import useUserHook from '../useUser.hook'

const UserContractCreated = ({ contract, userId }) => {
  const [isSending, setIsSending] = useState(false)

  const { fetchUser } = useUserHook()

  const handleFileSend = async file => {
    setIsSending(true)

    try {
      const formData = new FormData()
      formData.append('file', file)

      await appUserContractUploadDocument(formData, contract.id, userId).then(
        response => {
          fetchUser()
          setIsSending(false)
        },
      )
    } catch (error) {
      console.log('Error: ', error)
      console.log('need show error')
      setIsSending(false)
    }
  }

  const handleNotPaymentSet = async () => {
    setIsSending(true)

    try {
      await appUserContractCreatedStatusSetNotPay(contract.id).then(
        response => {
          fetchUser()
          setIsSending(false)
        },
      )
    } catch (error) {
      console.log('Error: ', error)
      console.log('need show error')
      setIsSending(false)
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          columnGap: 1,
        }}
      >
        <Button variant="contained" onClick={handleNotPaymentSet}>
          Not payment
        </Button>
        <AppInfoTooltip text={`Set status ${DEPOSIT_STATUSES.NOT_PAYMENT}`} />
      </Box>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          columnGap: 1,
        }}
      >
        <AppUploadFileButtons
          isSending={isSending}
          handleFileSend={handleFileSend}
        />
        <AppInfoTooltip text="Send documents to user for signing" />
      </Box>
    </Box>
  )
}

export default UserContractCreated
