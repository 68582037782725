import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import {
  Autocomplete,
  Button,
  Divider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { NOTIFICATION_RECEIVERS } from '../../utils/appConstants'
import NotificationTypesToggler from './NotificationTypesToggler'
import Box from '@mui/material/Box'
import NotificationTextarea from './NotificationTextarea'
import NotificationModal from '../../components/NotificationModal'
import toast, { Toaster } from 'react-hot-toast'
import { useRecoilState } from 'recoil'
import { mobAppUsersAtom } from '../../states/appUsers'
import { useNotification } from '../../hooks/useNotification'
import { theme } from '../../../theme/defaultTheme'

const NotificationSend = () => {
  const [users, setUsers] = useRecoilState(mobAppUsersAtom)
  const [selectedUser, setSelectedUser] = useState(null)
  const selectedUserAsString = selectedUser
    ? `${selectedUser.id}. ${selectedUser.first_name} ${selectedUser.last_name}`
    : ''

  const handleUserSelect = user => {
    const userId = +user?.split('.')[0].trim()
    const isUserExist = users.some(u => u.id === userId)

    if (!isUserExist) {
      return
    }

    setSelectedUser(users.find(u => u.id === userId))
  }

  const showSuccessNotify = text => toast.success(text)
  const showErrorNotify = text => toast.error(text)

  const {
    notification,
    activeNotificationReceiver,
    activeNotificationType,
    isConfirmModalOpen,
    openConfirmModal,
    closeConfirmModal,
    isNotificationSending,
    handleNotificationChange,
    handleNotificationReceiverTabClick,
    handleNotificationTypeTabClick,
    handleSendPersonalNotification,
    handleSendNotificationForAllUsers,
  } = useNotification({
    userId: selectedUser?.id,
    showSuccessNotify,
    showErrorNotify,
  })

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
      <Divider />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box>
          <Typography
            sx={{ textTransform: 'uppercase', color: theme.palette.grey[600] }}
            variant="subtitle2"
          >
            Send to:
          </Typography>
          <ToggleButtonGroup
            color="primary"
            value={activeNotificationReceiver}
            exclusive
            onChange={handleNotificationReceiverTabClick}
            aria-label="Platform"
          >
            <ToggleButton size="small" value={NOTIFICATION_RECEIVERS.PERSONAL}>
              {NOTIFICATION_RECEIVERS.PERSONAL}
            </ToggleButton>
            <ToggleButton
              size="small"
              value={NOTIFICATION_RECEIVERS.FOR_ALL_USERS}
            >
              {NOTIFICATION_RECEIVERS.FOR_ALL_USERS}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box>
          <Typography
            sx={{ textTransform: 'uppercase', color: theme.palette.grey[600] }}
            variant="subtitle2"
          >
            Type of notification:
          </Typography>
          <NotificationTypesToggler
            activeNotificationType={activeNotificationType}
            handleNotificationTypeClick={handleNotificationTypeTabClick}
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
        {activeNotificationReceiver === NOTIFICATION_RECEIVERS.PERSONAL && (
          <Autocomplete
            sx={{ width: '100%' }}
            freeSolo
            disableClearable
            options={users.map(
              user => `${user.id}. ${user.first_name} ${user.last_name}`,
            )}
            value={selectedUserAsString}
            renderInput={params => (
              <TextField
                {...params}
                label="Search user"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
            onChange={(e, user) => handleUserSelect(user)}
          />
        )}

        {activeNotificationReceiver ===
          NOTIFICATION_RECEIVERS.FOR_ALL_USERS && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '56px',
            }}
            variant="subtitle1"
          >
            Every user will receive a notification.
          </Typography>
        )}

        <NotificationTextarea
          notification={notification}
          handleNotificationChange={handleNotificationChange}
        />

        <Button
          sx={{ width: 'fit-content' }}
          variant="outlined"
          disabled={
            (activeNotificationReceiver === NOTIFICATION_RECEIVERS.PERSONAL &&
              !selectedUser) ||
            notification.length === 0
          }
          onClick={openConfirmModal}
        >
          Confirm sending
        </Button>
      </Box>

      <NotificationModal
        isOpen={isConfirmModalOpen}
        notification={notification}
        selectedUser={selectedUser}
        isConfirmActionDisabled={isNotificationSending}
        activeNotificationReceiver={activeNotificationReceiver}
        activeNotificationType={activeNotificationType}
        handleNotificationChange={handleNotificationChange}
        handleCloseModal={closeConfirmModal}
        handleNotificationTypeTabClick={handleNotificationTypeTabClick}
        handleSendPersonalNotification={handleSendPersonalNotification}
        handleSendNotificationForAllUsers={handleSendNotificationForAllUsers}
      />

      <Toaster position="top-right" />
    </Box>
  )
}

export default NotificationSend
