import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Divider } from '@mui/material'
import Typography from '@mui/material/Typography'

const AppConfirmModal = ({
  isOpen,
  isConfirmActionDisabled,
  handleApproveAction,
  handleCloseModal,
  children,
}) => {
  return (
    <Dialog sx={{ minWidth: '300px' }} open={isOpen} onClose={handleCloseModal}>
      <DialogTitle>
        <Typography variant="subtitle1" fontWeight="bold">
          Confirmation
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="success"
          disabled={isConfirmActionDisabled}
          onClick={handleApproveAction}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AppConfirmModal
