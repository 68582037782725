// LS - localStorage
import { blue, green, red, yellow } from "@mui/material/colors";

export const LS_MOB_APP_ACCESS_TOKEN_KEY = "mobAppAccessToken";
export const LS_MOB_APP_REFRESH_TOKEN_KEY = "mobAppRefreshToken";
export const LS_MOB_APP_CURRENT_USER_ID_KEY = "mobAppCurrentUserId";
export const LS_USERS = "mobAppUsers";

// views
export const views = {
  auth: "auth",
  users: "users",
  user: "user",
  finance: "finance",
  notifications: "notifications",
  keys: "keys",
  settings: "settings",
};

const ALL_STATUSES_FILTER = "All";

// TODO: плохое название
export const APPS = {
  FORTIS: "Fortis",
  CRYPTO: "Crypto",
  PRIME: "Prime",
};
export const APPS_FILTERS = [
  ALL_STATUSES_FILTER,
  APPS.FORTIS,
  APPS.CRYPTO,
  APPS.PRIME,
];

export const CURRENCIES = {
  USDT: "USDT",
  BTC: "BTC",
  USDC: "USDC",
  ETH: "ETH",
  DOT: "DOT",
  LINK: "LINK",
};
export const CURRENCY_LIST = [
  CURRENCIES.USDT,
  CURRENCIES.BTC,
  CURRENCIES.USDC,
  CURRENCIES.ETH,
  CURRENCIES.DOT,
  CURRENCIES.LINK,
];
export const FORTIS_CURRENCIES = [CURRENCIES.USDT, CURRENCIES.USDC];
export const CRYPTO_CURRENCIES = [
  CURRENCIES.BTC,
  CURRENCIES.ETH,
  CURRENCIES.DOT,
  CURRENCIES.LINK,
];

export const PLATFORMS = {
  BYBIT: "BYBIT",
  BINANCE: "BINANCE",
};
export const PLATFORM_LIST = [PLATFORMS.BYBIT, PLATFORMS.BINANCE];

export const NOTIFICATION_RECEIVERS = {
  PERSONAL: "Particular user",
  FOR_ALL_USERS: "All users",
};

export const NOTIFICATION_TYPES = {
  PROMOTION: "Promotion",
  WARNING: "Warning",
  REPORT: "Report",
  SUCCESSFUL: "Successful",
};

export const TRANSACTION_STATUSES = {
  CREATED: "Created",
  DOCUMENT_PREPARATION: "Document preparation",
  DOCUMENT_SIGNING: "Document signing",
  CHECK: "Check",
  REFUSAL: "Refusal",
  PAID: "Paid",
};

export const COMMISSION_STATUSES = {
  CREATED: "Created",
  CHECK: "Check",
  NOT_PAYMENT: "Not Payment",
  PAID: "Paid",
};

export const DEPOSIT_STATUSES = {
  RE_CREATION: "Re-creation",
  CREATED: "Created",
  UPDATED: "Updated",
  NOT_PAYMENT: "Not payment",
  DOCUMENT_PREPARATION: "Document preparation",
  DOCUMENT_SIGNING: "Document signing",
  DOCUMENT_PREPARATION_RE_OPEN: "Document preparation re-open",
  DOCUMENT_SIGNING_RE_OPEN: "Document signing re-open",
  DOCUMENT_PREPARATION_UPDATED: "Document preparation updated",
  DOCUMENT_SIGNING_UPDATED: "Document signing updated",
  ACTIVE: "Active",
  COMPLETED: "Completed",
  PAID: "Paid",
};
export const DEPOSIT_STATUSES_FILTERS = [
  ALL_STATUSES_FILTER,
  DEPOSIT_STATUSES.CREATED,
  DEPOSIT_STATUSES.NOT_PAYMENT,
  DEPOSIT_STATUSES.DOCUMENT_PREPARATION,
  DEPOSIT_STATUSES.DOCUMENT_SIGNING,
  DEPOSIT_STATUSES.ACTIVE,
  DEPOSIT_STATUSES.COMPLETED,
  DEPOSIT_STATUSES.UPDATED,
  DEPOSIT_STATUSES.DOCUMENT_PREPARATION_UPDATED,
  DEPOSIT_STATUSES.DOCUMENT_SIGNING_UPDATED,
  DEPOSIT_STATUSES.RE_CREATION,
  DEPOSIT_STATUSES.DOCUMENT_PREPARATION_RE_OPEN,
  DEPOSIT_STATUSES.DOCUMENT_SIGNING_RE_OPEN,
  DEPOSIT_STATUSES.PAID,
];

export const REPORT_TYPES = {
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
};
export const REPORT_TYPES_LIST = [REPORT_TYPES.MONTHLY, REPORT_TYPES.QUARTERLY];

export const appColor = (type) => {
  const lowerType = type.toLowerCase();
  const forits = APPS.FORTIS.toLowerCase();
  const crypto = APPS.CRYPTO.toLowerCase();
  const prime = APPS.PRIME.toLowerCase();

  return lowerType === forits
      ? green[500]
      : lowerType === crypto
          ? yellow[800]
          : lowerType === prime
              ? red[500]
              : blue[500];
};

export const userFullName = (name, surname) => `${name} ${surname}`;
