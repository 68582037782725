import React from 'react'
import Box from '@mui/material/Box'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import Typography from '@mui/material/Typography'

const UserContractCompleted = () => {
  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 1,
        height: '100%',
      }}
    >
      <HourglassEmptyIcon />
      Contract expired!
    </Typography>
  )
}

export default UserContractCompleted
