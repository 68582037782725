import React, { useState } from "react";
import { useAxiosWrapper } from "../../../../helpers/axiosWrapper";
import { Edit, Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

export const MPEditStartBalance = ({
  doc_id,
  setMaxiProfitProduct,
  setMaxiProfitArray,
  startBalance,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const axiosWrapper = useAxiosWrapper();
  const classes = useStyles();

  const editStartBalanceForm = Yup.object().shape({
    startBalance: Yup.number()
      .min(1, "Start balance must be greater than 0")
      .required("Start balance is required")
      .typeError("Start balance must be a number"),
  });

  const { handleSubmit, control, reset, setValue } = useForm({
    resolver: yupResolver(editStartBalanceForm),
    mode: "onTouched",
  });

  const handleOpen = () => {
    setOpen(true);
    setValue("startBalance", startBalance);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleEditStartBalance = (data) => {
    setLoading(true);
    axiosWrapper
      .put(`/maxiprofit/${doc_id}/${Number(data.startBalance)}`)
      .then(() => {
        setMaxiProfitArray((prev) => {
          return prev.map((item) => {
            if (item.docs.id === doc_id) {
              return { ...item, start_balance: data.startBalance };
            } else {
              return item;
            }
          });
        });
        setMaxiProfitProduct((prev) =>
          prev ? { ...prev, start_balance: data.startBalance } : prev
        );
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        handleClose();
      });
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        size={"small"}
        disableRipple
        disableTouchRipple
      >
        <Edit fontSize="inherit" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change Start Balance</DialogTitle>
        <form>
          <DialogContent>
            <Controller
              render={({ field: { ref, onChange, ...field }, fieldState }) => (
                <TextField
                  style={{ marginTop: "20px" }}
                  type={"number"}
                  name={"startBalance"}
                  label={"Start Balance"}
                  inputRef={ref}
                  fullWidth
                  autoFocus
                  variant="standard"
                  onChange={(event) => {
                    onChange(event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>$</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: "0",
                    max: "100",
                  }}
                  className={classes.input}
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
              name={"startBalance"}
              control={control}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              startIcon={<Save />}
              loadingPosition="start"
              loading={loading}
              onClick={handleSubmit(handleEditStartBalance, (err) =>
                console.log(err)
              )}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
