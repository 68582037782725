import { styled } from "@mui/material/styles";
import { useState } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Chip, CircularProgress } from "@mui/material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import Box from "@mui/material/Box";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const AppUploadFileButtons = ({
                                  uploadText,
                                  sendText,
                                  handleFileSend,
                                  isSending,
                              }) => {
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        setFile(file);
    };
    const handleFileDelete = () => setFile(null);

    const emitFile = () => {
        handleFileSend(file, setFile);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: 1,
                maxWidth: "300px",
                width: "100%",
            }}
        >
            <Button
                sx={{ width: "fit-content" }}
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
            >
                {uploadText ?? "Upload file"}
                <VisuallyHiddenInput
                    type="file"
                    accept=".doc,.docx,.pdf"
                    onChange={handleFileChange}
                />
            </Button>
            {!!file && (
                <Chip
                    sx={{
                        height: "auto",
                        justifyContent: "space-between",
                        borderRadius: "4px",
                        "& .MuiChip-label": {
                            display: "block",
                            whiteSpace: "normal",
                        },
                    }}
                    label={file.name}
                    onDelete={handleFileDelete}
                />
            )}
            <Button
                sx={{ marginTop: 1, width: "fit-content" }}
                disabled={!file || isSending}
                component="label"
                variant="contained"
                startIcon={
                    isSending ? (
                        <CircularProgress color="inherit" size={16} />
                    ) : (
                        <ForwardToInboxIcon />
                    )
                }
                onClick={emitFile}
            >
                {sendText ?? "Send file"}
            </Button>
        </Box>
    );
};

export default AppUploadFileButtons;
