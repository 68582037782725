import React, { useState } from "react";
import AppUploadFileButtons from "../../ui/AppUploadFileButtons";
import Box from "@mui/material/Box";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
    LS_MOB_APP_CURRENT_USER_ID_KEY,
    REPORT_TYPES,
    REPORT_TYPES_LIST,
} from "../../utils/appConstants";
import { appReportUpload } from "../../api/user/appUser";
import useUserHook from "./useUser.hook";
import toast, { Toaster } from "react-hot-toast";

const ReportFileUpload = () => {
    const { fetchUser } = useUserHook();
    const userId = localStorage.getItem(LS_MOB_APP_CURRENT_USER_ID_KEY);
    const showErrorNotify = (text) => toast.error(text);
    const showSuccessNotify = (text) => toast.success(text);

    const [type, setType] = useState(REPORT_TYPES.MONTHLY);
    const [isSending, setIsSending] = useState(false);
    const handleTypeToggle = (e, value) => value !== null && setType(value);

    const handleFileSend = async (file, setFile) => {
        setIsSending(true);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", type);

        await appReportUpload(userId, formData)
            .then(() => {
                showSuccessNotify("File successfully send");
                fetchUser();
                setFile(null);
            })
            .catch(() => showErrorNotify("Error the report didn't send!"))
            .finally(() => setIsSending(false));
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
            <ToggleButtonGroup
                color="primary"
                value={type}
                exclusive
                onChange={handleTypeToggle}
                aria-label="Platform"
            >
                {REPORT_TYPES_LIST.map((type, index) => (
                    <ToggleButton size="small" value={type} key={`${type}-${index}`}>
                        {type}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>

            <AppUploadFileButtons
                isSending={isSending}
                handleFileSend={handleFileSend}
            />

            <Toaster position="top-right" />
        </Box>
    );
};

export default ReportFileUpload;
