import AuthView from './views/auth-view/AuthView'
import { views } from './utils/appConstants'
import UsersView from './views/users-view/UsersView'
import { useRecoilState } from 'recoil'
import { mobAppViewAtom } from './states/appViews'
import { useEffect } from 'react'
import { mobAppIsAuthorizedAtom } from './states/appIsAuthorized'
import SettingsView from './views/settings-view/SettingsView'
import UserView from './views/user-view/UserView'
import NotificationsView from './views/notifications-view/NotificationsView'
import FinanceView from './views/finance-view/FinanceView'
import KeysView from './views/keys-view/KeysView'

const MobileApp = () => {
  const [currentView, setCurrentView] = useRecoilState(mobAppViewAtom)
  const [isLoggedIn] = useRecoilState(mobAppIsAuthorizedAtom)

  useEffect(() => {
    !isLoggedIn && setCurrentView(views.auth)
  }, [isLoggedIn])

  switch (currentView) {
    case views.auth:
      return <AuthView />
    case views.users:
      return <UsersView />
    case views.user:
      return <UserView />
    case views.finance:
      return <FinanceView />
    case views.notifications:
      return <NotificationsView />
    case views.keys:
      return <KeysView />
    case views.settings:
      return <SettingsView />
    default:
      return <></>
  }
}

export default MobileApp
