import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { APPS } from '../../../utils/appConstants'
import { formatDateToString } from '../../../utils/formatDateToString'
import SendToMobileIcon from '@mui/icons-material/SendToMobile'
import Button from '@mui/material/Button'
import React from 'react'
import { useNotification } from '../../../hooks/useNotification'
import NotificationModal from '../../../components/NotificationModal'
import toast, { Toaster } from 'react-hot-toast'
import { useRecoilState } from 'recoil'
import { mobAppUsersAtom } from '../../../states/appUsers'
import AppCountdownTimer from '../../../ui/AppCountdownTimer'

const UserContractActive = ({ contract, userId }) => {
  const { deposit } = contract
  const [users] = useRecoilState(mobAppUsersAtom)
  const currentUser = users.find(user => user.id === userId)

  const showSuccessNotify = text => toast.success(text)
  const showErrorNotify = text => toast.error(text)

  const {
    notification,
    activeNotificationReceiver,
    activeNotificationType,
    isConfirmModalOpen,
    openConfirmModal,
    closeConfirmModal,
    isNotificationSending,
    handleNotificationChange,
    handleNotificationTypeTabClick,
    handleSendPersonalNotification,
    handleSendNotificationForAllUsers,
  } = useNotification({
    userId: userId,
    showSuccessNotify,
    showErrorNotify,
  })

  const dateOfEnd = (deletedDate, createdDate, term) => {
    if (deletedDate) {
      return deletedDate
    } else {
      const dateOfCreate = new Date(createdDate)

      return dateOfCreate.setMonth(dateOfCreate.getMonth() + term)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
      }}
    >
      {contract.type.toLowerCase() !== APPS.PRIME.toLowerCase() && (
        <>
          <Typography>
            Contract expiration date:
            <Typography fontWeight="bold">
              {formatDateToString(
                dateOfEnd(deposit.deleted_at, deposit.created_at, deposit.term),
              )}
            </Typography>
          </Typography>

          <AppCountdownTimer
            targetDate={dateOfEnd(
              deposit.deleted_at,
              deposit.created_at,
              deposit.term,
            )}
            timerName="Contract"
          />
        </>
      )}

      <Button
        sx={{ margin: '0 auto' }}
        component="label"
        variant="contained"
        startIcon={<SendToMobileIcon />}
        onClick={openConfirmModal}
      >
        Send notification
      </Button>

      <NotificationModal
        isOpen={isConfirmModalOpen}
        notification={notification}
        selectedUser={currentUser}
        isConfirmActionDisabled={isNotificationSending}
        activeNotificationReceiver={activeNotificationReceiver}
        activeNotificationType={activeNotificationType}
        handleNotificationChange={handleNotificationChange}
        handleCloseModal={closeConfirmModal}
        handleNotificationTypeTabClick={handleNotificationTypeTabClick}
        handleSendPersonalNotification={handleSendPersonalNotification}
        handleSendNotificationForAllUsers={handleSendNotificationForAllUsers}
      />

      <Toaster position="top-right" />
    </Box>
  )
}

export default UserContractActive
