import React from 'react'
import { Button } from '@mui/material'
import { APPS } from '../utils/appConstants'
import { appProductsAccessUpdate } from '../api/user/appUser'
import toast, { Toaster } from 'react-hot-toast'

// Вынесено отдельно, чтобы в дальнейшем было просто "выпилить кнопку"
const ToggleAccessButton = ({ userId, updateFunction }) => {
  const showErrorNotify = text => toast.error(text)
  const handleClick = async () => {
    await appProductsAccessUpdate(+userId)
      .then(() => {
        updateFunction()
      })
      .catch(() => {
        showErrorNotify('Error!')
      })
  }

  return (
    <>
      <Button variant="contained" onClick={handleClick}>
        Toggle {APPS.CRYPTO}/{APPS.FORTIS} access
      </Button>
      <Toaster position="top-right" />
    </>
  )
}

export default ToggleAccessButton
