import { useLocation, Navigate } from "react-router-dom";
import React from "react";

export function RequireAuth({ needValidate, to, children }) {
  let location = useLocation();

  if (!needValidate || needValidate === "") {
    return (
      <Navigate
        to={to}
        state={{ from: location, reason: "Your session has expired" }}
        replace
      />
    );
  }

  return children;
}
