import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAxiosWrapper } from "../../../../helpers/axiosWrapper";
import { CSRenameDialog } from "../CryptoSafeDialog/CSRenameDialog";
import { MPEditStartBalance } from "./MPEditStartBalance";
import Alert from "@mui/material/Alert";
import { Delete } from "@mui/icons-material";

const PaperContainer = styled(Paper)(() => ({
  minHeight: "100px",
  height: "fit-content",
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const MaxiProfitCard = memo(
  ({ maxiprofitArray, setMaxiProfitArray }) => {
    const [MaxiProfitProduct, setMaxiProfitProduct] = React.useState();
    const [uploading, setUploading] = React.useState(false);
    const axiosWrapper = useAxiosWrapper();
    const [expanded, setExpanded] = React.useState(false);
    const [uploadError, setUploadError] = React.useState("");
    const [cache, setCache] = useState([]);
    const [deleting, setDeleting] = useState(false);

    const handleDelete = () => {
      setDeleting(true);
      axiosWrapper
        .delete("maxiprofit/del/" + MaxiProfitProduct.docs.id)
        .then(() => {
          setMaxiProfitArray(
            maxiprofitArray.filter(
              (mp) => mp.docs.id !== MaxiProfitProduct?.docs?.id
            )
          );
          setMaxiProfitProduct(
            maxiprofitArray[
              maxiprofitArray.findIndex(
                (item) => item.docs.id === MaxiProfitProduct?.docs.id
              ) > 0
                ? maxiprofitArray.findIndex(
                    (item) => item.docs.id === MaxiProfitProduct?.docs.id
                  ) - 1
                : 1
            ]
          );
        })
        .then(() => setDeleting(false));
    };

    const handleChange = (event) => {
      setMaxiProfitProduct(JSON.parse(event.target.value));
    };

    const handleUpload = (event) => {
      setUploading(true);
      setUploadError("");

      if (event.target.files[0].size > 7000000) {
        setUploadError("size_error");
        setUploading(false);
      } else {
        let formdata = new FormData();
        formdata.append(
          "file",
          event.target.files[0],
          event.target.files[0].name
        );
        let headers = {
          "Content-Type": `image/jpeg`,
        };
        axiosWrapper
          .post(
            "users/docs_admin/" + MaxiProfitProduct?.docs.id,
            formdata,
            headers
          )
          .then(() => setUploading(false));
      }
    };

    useLayoutEffect(() => {
      MaxiProfitProduct &&
        axiosWrapper
          .get(`/maxiprofit/${MaxiProfitProduct?.docs.id}/cache`, {}, {})
          .then((response) => {
            setCache(response.data);
          });
    }, [MaxiProfitProduct]);

    useEffect(() => {
      setExpanded(!!MaxiProfitProduct);
    }, [MaxiProfitProduct]);

    const getAlltimeProfit = () => {
      if (MaxiProfitProduct?.start_balance !== -1) {
        return (
          MaxiProfitProduct?.balance.balance - MaxiProfitProduct?.start_balance
        );
      } else {
        return (
          MaxiProfitProduct?.balance?.balance -
          MaxiProfitProduct?.graph.chart[0]?.balance
        );
      }
    };

    const getMonthProfit = () => {
      let monthStart;
      let monthEnd = MaxiProfitProduct?.balance.balance;

      for (let i = 0; i < cache?.length; i++) {
        console.log(cache[i]);
        console.log(
          new Date(cache[i]?.updated * 1000)
            .toLocaleString("en-US", { month: "long" })
            .substring(0, 3) ===
            new Date()
              .toLocaleString("en-US", { month: "long" })
              .substring(0, 3)
        );
        if (
          new Date(cache[i]?.updated * 1000)
            .toLocaleString("en-US", { month: "long" })
            .substring(0, 3) ===
          new Date().toLocaleString("en-US", { month: "long" }).substring(0, 3)
        ) {
          monthStart = cache[i]?.balance.balance;

          break;
        }
      }

      console.log(monthStart, monthEnd);

      return monthEnd - monthStart;
    };

    const getDayProfit = () => {
      let currentDayBalance;
      let prevDayBalance;

      currentDayBalance = MaxiProfitProduct?.balance.balance;

      cache.forEach((e) => {
        if (new Date(e.updated * 1000).getDate() === new Date().getDate() - 1) {
          prevDayBalance = e.balance.balance;
        }
      });

      if (prevDayBalance === undefined) {
        cache.forEach((e) => {
          if (new Date(e.updated * 1000).getDate() === new Date().getDate()) {
            prevDayBalance = e.balance.balance;
          }
        });
      }

      return currentDayBalance - prevDayBalance;
    };

    const NaNStringSafety = (str) => {
      if (str === "NaN") {
        return "—";
      } else {
        return str;
      }
    };

    return (
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              onClick={() => {
                if (MaxiProfitProduct) {
                  setExpanded((prev) => !prev);
                }
              }}
            />
          }
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography>MaxiProfit</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControl
                sx={{
                  width: "100%",
                  maxWidth: "300px",
                  ml: { xs: "unset", md: "10px" },
                }}
                variant="standard"
                size={"small"}
              >
                <Select
                  sx={{ minWidth: "150px" }}
                  autoWidth
                  onClick={(event) => event.stopPropagation()}
                  value={
                    MaxiProfitProduct ? JSON.stringify(MaxiProfitProduct) : ""
                  }
                  label="Age"
                  onChange={handleChange}
                >
                  {maxiprofitArray.map((product, index) => (
                    <MenuItem key={index} value={JSON.stringify(product)}>
                      {product.contract_name === ""
                        ? product.docs.id
                        : `${product.docs.id} - ${product.contract_name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {MaxiProfitProduct && (
                <CSRenameDialog
                  doc_id={MaxiProfitProduct?.docs.id}
                  setCryptoSafeProduct={setMaxiProfitProduct}
                  setCryptoSafe={setMaxiProfitArray}
                  cryptosafe={maxiprofitArray}
                  currentName={MaxiProfitProduct.contract_name}
                  contractType={"maxiprofit"}
                />
              )}
            </Box>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <PaperContainer sx={{ margin: "10px 0" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Start Balance
              </Typography>
              <MPEditStartBalance
                setMaxiProfitArray={setMaxiProfitArray}
                maxiprofitArray={maxiprofitArray}
                doc_id={MaxiProfitProduct?.docs.id}
                setMaxiProfitProduct={setMaxiProfitProduct}
                startBalance={MaxiProfitProduct?.start_balance}
              />
            </Box>
            <Typography
              sx={{
                alignSelf: "center",
                margin: "auto",
                width: "100%",
                textAlign: "center",
                fontSize: "1.8rem",
              }}
              variant={"h4"}
            >
              {MaxiProfitProduct
                ? MaxiProfitProduct.start_balance > 0
                  ? "$" + MaxiProfitProduct.start_balance.toFixed(2)
                  : "Empty"
                : "$0"}
            </Typography>
          </PaperContainer>
          <Box
            sx={{
              height: "fit-content",
              display: "grid",
              gridTemplateColumns: {
                sm: "repeat(auto-fill, minmax(150px, 1fr))",
                xl: "repeat(auto-fill, minmax(220px, 1fr))",
              },
              alignItems: "center",
              gridGap: "10px",
            }}
          >
            <PaperContainer>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Balance
              </Typography>
              <Typography
                sx={{
                  alignSelf: "center",
                  margin: "auto",
                  width: "100%",
                  textAlign: "center",
                  fontSize: "1.8rem",
                }}
                variant={"h4"}
              >
                $
                {MaxiProfitProduct
                  ? MaxiProfitProduct.balance.balance.toFixed(2)
                  : "0"}
              </Typography>
            </PaperContainer>
            <PaperContainer>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Total Income
              </Typography>
              <Typography
                sx={{ alignSelf: "center", margin: "auto", fontSize: "1.8rem" }}
                variant={"h4"}
              >
                $
                {MaxiProfitProduct && cache.length > 0
                  ? NaNStringSafety(getAlltimeProfit().toFixed(2))
                  : "0"}
              </Typography>
            </PaperContainer>
            <PaperContainer>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Income per Month
              </Typography>
              <Typography
                sx={{ alignSelf: "center", margin: "auto", fontSize: "1.8rem" }}
                variant={"h4"}
              >
                $
                {MaxiProfitProduct && cache.length > 0
                  ? NaNStringSafety(getMonthProfit().toFixed(2))
                  : "0"}
              </Typography>
            </PaperContainer>
            <PaperContainer>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Income per Day
              </Typography>
              <Typography
                sx={{ alignSelf: "center", margin: "auto", fontSize: "1.8rem" }}
                variant={"h4"}
              >
                $
                {MaxiProfitProduct && cache.length > 0
                  ? NaNStringSafety(getDayProfit().toFixed(2))
                  : "0"}
              </Typography>
            </PaperContainer>
            <PaperContainer>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Month profit percent
              </Typography>
              <Typography
                sx={{ alignSelf: "center", margin: "auto", fontSize: "1.8rem" }}
                variant={"h4"}
              >
                {MaxiProfitProduct && cache.length > 0
                  ? cache[0].balance.monthPercentProfit
                  : "0"}
                %
              </Typography>
            </PaperContainer>
            <PaperContainer>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Day profit percent
              </Typography>
              <Typography
                sx={{ alignSelf: "center", margin: "auto", fontSize: "1.8rem" }}
                variant={"h4"}
              >
                {MaxiProfitProduct && cache[0]
                  ? cache[0].balance.dayPercentProfit
                  : "0"}
                %
              </Typography>
            </PaperContainer>
          </Box>
          {MaxiProfitProduct && MaxiProfitProduct.docs && (
            <>
              {uploadError === "size_error" && (
                <Alert sx={{ mt: "20px" }} severity="error">
                  File size is too big. Max size is 7MB.
                </Alert>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <div>
                  <LoadingButton
                    loadingIndicator="Uploading…"
                    loading={uploading}
                    component="label"
                  >
                    Upload contract
                    <input
                      onChange={handleUpload}
                      hidden
                      accept="application/pdf"
                      type="file"
                    />
                  </LoadingButton>
                  <Button
                    disabled={!MaxiProfitProduct.docs.signed_contract}
                    onClick={() =>
                      window
                        .open(MaxiProfitProduct.docs.signed_contract, "_blank")
                        .focus()
                    }
                  >
                    Download contract
                  </Button>
                </div>
                <LoadingButton
                  loadingPosition="start"
                  loading={deleting}
                  variant={"contained"}
                  startIcon={<Delete />}
                  onClick={handleDelete}
                >
                  Delete
                </LoadingButton>
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
);
