import Box from '@mui/material/Box'
import AppUploadFileButtons from '../../../ui/AppUploadFileButtons'
import AppInfoTooltip from '../../../ui/AppInfoTooltip'
import { useState } from 'react'
import { appUserContractUploadUpdateDocument } from '../../../api/user/appUser'
import useUserHook from '../useUser.hook'

const UserContractUpdated = ({ contract, userId }) => {
  const [isSending, setIsSending] = useState(false)

  const { fetchUser } = useUserHook()

  const handleFileSend = async file => {
    setIsSending(true)

    try {
      const formData = new FormData()
      formData.append('file', file)

      await appUserContractUploadUpdateDocument(
        formData,
        contract.id,
        userId,
      ).then(response => {
        fetchUser()
        setIsSending(false)
      })
    } catch (error) {
      console.log('Error: ', error)
      console.log('need show error')
      setIsSending(false)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: 1,
      }}
    >
      <AppUploadFileButtons
        isSending={isSending}
        handleFileSend={handleFileSend}
      />
      <AppInfoTooltip text="Upload the document to the user for contract extension." />
    </Box>
  )
}

export default UserContractUpdated
