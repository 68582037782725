import axiosInstance from '../appAxiosInstance'
import {appEndpoints} from '../appEndpoints'

export const appGetAllTransactions = () =>
  axiosInstance.get(appEndpoints.FINANCE.ALL_TRANSACTIONS)

export const appGetAllCommissions = () =>
  axiosInstance.get(appEndpoints.FINANCE.ALL_COMMISSIONS)

export const appSetSuccessPaymentTransaction = transactionId =>
  axiosInstance.patch(
    `${appEndpoints.FINANCE.SET_TRANSACTION_PAY}/${transactionId}`,
    { paid: true },
  )

export const appSetFailedPaymentTransaction = transactionId =>
  axiosInstance.patch(
    `${appEndpoints.FINANCE.SET_TRANSACTION_PAY}/${transactionId}`,
    { paid: false },
  )

export const appSetCheckTransactionStatus = transactionId =>
  axiosInstance.patch(
    `${appEndpoints.FINANCE.SET_CHECK_TRANSACTION_STATUS}/${transactionId}`,
  )
export const appSetPaidTransactionStatus = transactionId =>
  axiosInstance.patch(
    `${appEndpoints.FINANCE.SET_PAID_TRANSACTION_STATUS}/${transactionId}`,
  )
export const appSetRefusalTransactionStatus = (transactionId, message) =>
  axiosInstance.patch(
    `${appEndpoints.FINANCE.SET_REFUSAL_TRANSACTION_STATUS}/${transactionId}`,
    { message },
  )

export const appUploadTransactionFile = (formData, transactionId) =>
  axiosInstance.post(
    `${appEndpoints.FINANCE.UPLOAD_FILE}/${transactionId}`,
    formData,
  )

export const appSetUserCoins = (userId, coins) =>
  axiosInstance.post(
    `${appEndpoints.FINANCE.SET_COINS}/${userId}`,
    {coins}
  )