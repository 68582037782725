import { useEffect, useId, useState } from 'react'
import { appGetAllKeysPay } from '../../api/keys-pay/appKeysPay'
import Box from '@mui/material/Box'
import KeyCard from './KeyCard'
import { InputAdornment, Tabs, TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { APPS, APPS_FILTERS } from '../../utils/appConstants'
import Tab from '@mui/material/Tab'
import AppReloadButton from '../../ui/AppReloadButton'

const KeysSearch = ({ searchTerm, handleSearch }) => {
  return (
    <TextField
      sx={{ width: '100%', marginBottom: 2 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      label="Search by Network or Key"
      variant="outlined"
      fullWidth
      value={searchTerm}
      onChange={handleSearch}
    />
  )
}

const KeysView = () => {
  const [cards, setCards] = useState([])
  const [typeTab, setTypeTab] = useState('All')
  const handleTypeTabChange = (e, tab) => setTypeTab(tab)
  const [filteredCards, setFilteredCards] = useState(cards)

  const [isFetching, setIsFetching] = useState(true)

  const [searchTerm, setSearchTerm] = useState('')
  const handleSearch = event =>
    setSearchTerm(event.target.value.toLowerCase().trim())

  const createCardId = useId()

  const fetchAllKeys = async () => {
    setIsFetching(true)

    try {
      await appGetAllKeysPay().then(response => {
        setCards(response.data)
      })
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    const filtered = filteredByNetworkOrKey => {
      if (searchTerm.length === 0) {
        return filteredByNetworkOrKey
      }

      return filteredByNetworkOrKey.filter(
        card =>
          card.network.toLowerCase().includes(searchTerm) ||
          card.key.toLowerCase().includes(searchTerm),
      )
    }

    switch (typeTab) {
      case 'All':
        setFilteredCards(() => filtered(cards))
        return
      case APPS.FORTIS:
        setFilteredCards(() =>
          filtered(
            cards.filter(card => card.type === APPS.FORTIS.toUpperCase()),
          ),
        )
        return
      case APPS.CRYPTO:
        setFilteredCards(() =>
          filtered(
            cards.filter(card => card.type === APPS.CRYPTO.toUpperCase()),
          ),
        )
        return
      case APPS.PRIME:
        setFilteredCards(() =>
          filtered(
            cards.filter(card => card.type === APPS.PRIME.toUpperCase()),
          ),
        )
        return
      default:
        setFilteredCards(cards)
        return
    }
  }, [searchTerm, typeTab, cards])

  useEffect(() => {
    fetchAllKeys()
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <KeysSearch searchTerm={searchTerm} handleSearch={handleSearch} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          columnGap: 2,
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs value={typeTab} onChange={handleTypeTabChange}>
          {APPS_FILTERS.map(type => (
            <Tab value={type} key={type} label={type} />
          ))}
        </Tabs>
        <AppReloadButton isLoading={isFetching} handleClick={fetchAllKeys} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          gap: 2,
        }}
      >
        <KeyCard
          card={{
            id: createCardId,
            currency: '',
            type: '',
            network: '',
            key: '',
          }}
          isCreateCard={true}
        />
        {filteredCards.map(card => (
          <KeyCard key={card.id} card={card} updateKeys={fetchAllKeys} />
        ))}
      </Box>
    </Box>
  )
}

export default KeysView
