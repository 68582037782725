import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useState } from 'react'
import { theme } from '../../../theme/defaultTheme'
import UserDocumentsChanger from '../../components/UserDocumentsChanger'
import LinearProgress from '@mui/material/LinearProgress'
import UserAdditionalInfo from '../../components/UserAdditionalInfo'
import AppVerifiedButton from '../../ui/AppVerifiedButton'
import AppEuStatusButton from '../../ui/AppEuStatusButton'
import NotificationModal from '../../components/NotificationModal'
import { useNotification } from '../../hooks/useNotification'
import toast, { Toaster } from 'react-hot-toast'
import GoToProfileTableButton from '../../components/table-buttons/GoToProfileTableButton'
import { userFullName } from '../../utils/appConstants'
import SendNotificationTableButton from '../../components/table-buttons/SendNotificationTableButton'
import ToggleAccessButton from '../../components/ToggleAccessButton'

function Row({ row, updateTable }) {
  const [open, setOpen] = useState(false)

  const showSuccessNotify = text => toast.success(text)
  const showErrorNotify = text => toast.error(text)

  const {
    notification,
    activeNotificationReceiver,
    activeNotificationType,
    isConfirmModalOpen,
    openConfirmModal,
    closeConfirmModal,
    isNotificationSending,
    handleNotificationChange,
    handleNotificationTypeTabClick,
    handleSendPersonalNotification,
    handleSendNotificationForAllUsers,
  } = useNotification({ userId: row.id, showSuccessNotify, showErrorNotify })

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell width={24}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="left" width={35}>
          {row.id}
        </TableCell>
        <TableCell>{row.first_name}</TableCell>
        <TableCell>{row.last_name}</TableCell>
        <TableCell align="center" width={60}>
          <AppVerifiedButton isVerified={row.verified} />
        </TableCell>
        <TableCell align="center" width={60}>
          {row.language}
        </TableCell>
        <TableCell align="center" width={60}>
          <AppEuStatusButton isEuStatus={row.eu_status} />
        </TableCell>
        <TableCell align="center" width={60}>
          <AppEuStatusButton isEuStatus={row.has_crypto_fortis} />
        </TableCell>
        <TableCell align="center">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              columnGap: 1,
            }}
          >
            <GoToProfileTableButton
              userName={userFullName(row.first_name, row.last_name)}
              userId={row.id}
            />
            <SendNotificationTableButton
              userName={userFullName(row.first_name, row.last_name)}
              openConfirmModal={openConfirmModal}
            />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                columnGap: 4,
                rowGap: 2,
                margin: 1,
                width: '100%',
              }}
            >
              <UserAdditionalInfo user={row} />
              <ToggleAccessButton
                userId={row.id}
                updateFunction={updateTable}
              />

              <UserDocumentsChanger
                name={`${row.first_name} ${row.last_name}`}
                documents={row.documents}
                userId={row.id}
                isVerified={row.verified}
                updateDataFunction={updateTable}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <NotificationModal
        isOpen={isConfirmModalOpen}
        notification={notification}
        selectedUser={row}
        isConfirmActionDisabled={isNotificationSending}
        activeNotificationReceiver={activeNotificationReceiver}
        activeNotificationType={activeNotificationType}
        handleNotificationChange={handleNotificationChange}
        handleCloseModal={closeConfirmModal}
        handleNotificationTypeTabClick={handleNotificationTypeTabClick}
        handleSendPersonalNotification={handleSendPersonalNotification}
        handleSendNotificationForAllUsers={handleSendNotificationForAllUsers}
      />

      <Toaster position="top-right" />
    </>
  )
}

const UsersTable = ({ rows, isFetching, updateTable }) => {
  return (
    <TableContainer sx={{ maxHeight: '65vh' }} component={Paper}>
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ color: theme.palette.grey[600] }} align="left">
              ID
            </TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }}>Name</TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }}>
              Surname
            </TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }} align="center">
              Verified
            </TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }} align="center">
              Language
            </TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }} align="center">
              EU
            </TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }} align="center">
              C/F
            </TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }} align="center">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetching && (
            <TableRow>
              <TableCell sx={{ padding: 0 }} colSpan={8}>
                <LinearProgress style={{ width: '100%' }} />
              </TableCell>
            </TableRow>
          )}
          {rows.map(row => (
            <Row key={row.name} row={row} updateTable={updateTable} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UsersTable
