import { LS_MOB_APP_CURRENT_USER_ID_KEY, views } from '../../utils/appConstants'
import { useRecoilState } from 'recoil'
import { mobAppViewAtom } from '../../states/appViews'
import IconButton from '@mui/material/IconButton'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import { Tooltip } from '@mui/material'

const GoToProfileTableButton = ({ userName, userId }) => {
  const [currentView, setCurrentView] = useRecoilState(mobAppViewAtom)

  const handleButtonClick = () => {
    localStorage.setItem(LS_MOB_APP_CURRENT_USER_ID_KEY, userId)
    setCurrentView(views.user)
  }

  return (
    <Tooltip title={`Go to profile ${userName}.`} placement="top" arrow>
      <IconButton onClick={handleButtonClick}>
        <PersonSearchIcon />
      </IconButton>
    </Tooltip>
  )
}

export default GoToProfileTableButton
