import React, { useState } from 'react'
import useUserHook from '../useUser.hook'
import toast, { Toaster } from 'react-hot-toast'
import { appPrimeStartAmountUpdate } from '../../../api/user/appUser'
import Box from '@mui/material/Box'
import AppInput from '../../../ui/AppInput'
import { Button } from '@mui/material'

const UserDepositAmountChange = ({ deposit }) => {
  const [amount, setAmount] = useState(deposit.amount_start ?? 0)

  const handleAmountChange = e => {
    const numericValue = e.target.value.replace(/[^0-9.]/g, '')
    const dots = numericValue.split('.').length - 1

    dots <= 1 && setAmount(numericValue)
  }

  const [inProgress, setInProgress] = useState(false)
  const { fetchUser } = useUserHook()
  const showErrorNotify = text => toast.error(text)

  const handleSaveChanges = async () => {
    setInProgress(true)

    await appPrimeStartAmountUpdate(deposit.id, +amount)
      .then(() => fetchUser())
      .catch(error => showErrorNotify("Error the amount didn't change!"))
      .finally(() => setInProgress(false))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        rowGap: 1,
      }}
    >
      <AppInput
        sx={{ marginBottom: 1 }}
        value={amount}
        handleChange={handleAmountChange}
      />
      <Button
        variant="contained"
        disabled={+amount === +deposit.current_interest_rate || inProgress}
        onClick={handleSaveChanges}
      >
        Save changes
      </Button>

      <Toaster position="top-right" />
    </Box>
  )
}

export default UserDepositAmountChange
