import axiosInstance from '../appAxiosInstance'
import { appEndpoints } from '../appEndpoints'

export const appGetAllKeysPay = () =>
  axiosInstance.get(appEndpoints.KEYS_PAY.GET_ALL)
export const appGetDefiniteKeysPay = id =>
  axiosInstance.get(`${appEndpoints.KEYS_PAY.GET_DEFINITE}/${id}}`, id)
export const appCreateKeyPay = obj =>
  axiosInstance.post(appEndpoints.KEYS_PAY.CREATE, obj)
export const appUpdateKeyPay = (id, key) =>
  axiosInstance.patch(`${appEndpoints.KEYS_PAY.UPDATE}/${id}`, key)
export const appDeleteKeyPay = id =>
  axiosInstance.delete(`${appEndpoints.KEYS_PAY.DELETE}/${id}`)
