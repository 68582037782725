import IconButton from '@mui/material/IconButton'
import { theme } from '../../theme/defaultTheme'
import { Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const AppRemoveButton = ({ sx, tooltipText, handleClick }) => {
  return (
    <Tooltip title={tooltipText ?? 'Remove?'} placement="top" arrow>
      <IconButton
        color="primary"
        aria-label="round-button"
        sx={{
          ...sx,
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          padding: '4px',
          zIndex: 1,
          color: theme.palette.primary.main,
          backgroundColor: '#ffffff',
          '&:hover': {
            backgroundColor: '#ffffff',
            color: theme.palette.primary.main,
          },
        }}
        onClick={handleClick}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default AppRemoveButton
