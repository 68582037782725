import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  styled,
  TextField,
} from "@mui/material";
import { UserDialogBox } from "../../UserDialog";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ruLocale from "date-fns/locale/ru";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAxiosWrapper } from "../../../../helpers/axiosWrapper";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";

const TextFieldBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
}));

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

export const CryptoSafeCreateDialog = ({ code, setCryptoSafe, disabled }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const axiosWrapper = useAxiosWrapper();

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const createCSForm = Yup.object().shape({
    term: Yup.number()
      .min(1, "Term must be greater than 0")
      .max(120)
      .required("Term is required")
      .typeError("Term must be a number"),
    sum: Yup.number()
      .min(1, "Deposit must be greater than 0")
      .max(999999999999)
      .required("Deposit is required")
      .typeError("Deposit must be a number"),
    contractName: Yup.string().required("Contract name is required"),
    startDate: Yup.date()
      .typeError("Start date should be correct")
      .required("Start date is required"),
    endDate: Yup.date()
      .when("startDate", (startDate, schema) => {
        return schema.test({
          test: (endDate) =>
            startDate.getTime() && endDate.getTime() > startDate.getTime(),
          message: "Max should be > min",
        });
      })
      .typeError("End date should be correct")
      .required("End date is required"),

    yearPercentage: Yup.number()
      .min(0.1, "Total percentage must be greater than 0")
      .required("Total percentage is required")
      .typeError("Total percentage must be a number"),
    totalPercentage: Yup.number()
      .min(0.1, "Total percentage must be greater than 0")
      .required("Total percentage is required")
      .typeError("Total percentage must be a number"),
  });

  const { handleSubmit, control, reset, setValue, getValues } = useForm({
    resolver: yupResolver(createCSForm),
    mode: "onTouched",
    defaultValues: {
      term: 1,
      sum: 1,
      totalPercentage: 1,
    },
  });

  const handleCreate = (data) => {
    console.log(data);
    setLoading(true);
    axiosWrapper
      .post(
        "cryptosafe/" + code,
        {
          code: code,
          term: data.term,
          sum: data.sum,
          contract_name: data.contractName,
          start_date: Math.floor(data.startDate.getTime() / 1000),
          end_date: Math.floor(data.endDate.getTime() / 1000),
          total_percent: data.totalPercentage,
        },
        {}
      )
      .then((res) => {
        if (res.status === 200) {
          setCryptoSafe((prev) =>
            prev && prev.length > 0 ? [...prev, res.data] : [res.data]
          );
          setLoading(false);
          reset();
          handleClose();
        }
      });
  };

  return (
    <>
      <Button disabled={disabled} onClick={() => setOpen(true)}>
        Add CryptoSafe
      </Button>
      <UserDialogBox open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          Create CryptoSafe
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#FFF",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(
              (e) => console.log(e),
              (e) => console.log(e)
            )}
          >
            <TextFieldBox sx={{ marginTop: "20px" }}>
              <Controller
                render={({
                  field: { ref, onChange, ...field },
                  fieldState,
                }) => (
                  <TextField
                    type={"number"}
                    name={"term"}
                    label={"Term"}
                    inputRef={ref}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={"end"}>mon.</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      min: "1",
                      max: "120",
                    }}
                    onChange={(event) => {
                      onChange(event);
                      setValue(
                        "endDate",
                        moment(Number(getValues("startDate"))).add(
                          Number(event.target.value),
                          "months"
                        )
                      );
                      setValue(
                        "totalPercentage",
                        (getValues("yearPercentage") / 12) * event.target.value
                      );
                    }}
                    className={classes.input}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
                name={"term"}
                control={control}
                defaultValue={""}
              />
            </TextFieldBox>
            <TextFieldBox sx={{ marginTop: "20px" }}>
              <Controller
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    name={"contractName"}
                    label={"Contract name"}
                    inputRef={ref}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
                name={"contractName"}
                control={control}
                defaultValue={""}
              />
            </TextFieldBox>
            <TextFieldBox>
              <Controller
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    type={"number"}
                    name={"sum"}
                    label={"Deposit"}
                    inputRef={ref}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={"start"}>$</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      min: "1",
                      max: "999999999999",
                    }}
                    className={classes.input}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
                name={"sum"}
                control={control}
                defaultValue={""}
              />
            </TextFieldBox>
            <TextFieldBox>
              <Controller
                name="startDate"
                control={control}
                defaultValue={new Date()}
                render={({ field: { onChange, ...restField }, fieldState }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ruLocale}
                  >
                    <DatePicker
                      label="Start date"
                      disableFuture
                      onChange={(event) => {
                        onChange(event);
                        setValue(
                          "endDate",
                          moment(event).add(Number(getValues("term")), "months")
                        );
                        setStartDate(event);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!fieldState.error?.message}
                          helperText={fieldState.error?.message}
                          fullWidth
                        />
                      )}
                      {...restField}
                    />
                  </LocalizationProvider>
                )}
              />
            </TextFieldBox>
            <TextFieldBox>
              <Controller
                name="endDate"
                control={control}
                defaultValue={
                  new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                }
                render={({ field: { onChange, ...restField }, fieldState }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ruLocale}
                  >
                    <DatePicker
                      readOnly
                      label="End date"
                      disablePast
                      onChange={(event) => {
                        onChange(event);
                        setEndDate(event);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!fieldState.error?.message}
                          helperText={fieldState.error?.message}
                          fullWidth
                        />
                      )}
                      {...restField}
                    />
                  </LocalizationProvider>
                )}
              />
            </TextFieldBox>
            <TextFieldBox>
              <Controller
                render={({
                  field: { ref, onChange, ...field },
                  fieldState,
                }) => (
                  <TextField
                    type={"number"}
                    name={"yearPercentage"}
                    label={"Year Percent"}
                    inputRef={ref}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={"end"}>%</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      min: "0",
                      max: "100",
                    }}
                    onChange={(event) => {
                      onChange(event.target.value);
                      setValue(
                        "totalPercentage",
                        (event.target.value / 12) * getValues("term")
                      );
                    }}
                    className={classes.input}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
                name={"yearPercentage"}
                control={control}
                defaultValue={""}
              />
            </TextFieldBox>
            <TextFieldBox>
              <Controller
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    disabled
                    type={"number"}
                    name={"totalPercentage"}
                    label={"Total Percent"}
                    inputRef={ref}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={"end"}>%</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      min: "0",
                      max: "100",
                    }}
                    className={classes.input}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
                name={"totalPercentage"}
                control={control}
                defaultValue={""}
              />
            </TextFieldBox>
            <DialogActions>
              <LoadingButton
                loading={loading}
                onClick={handleSubmit(handleCreate, (e) => console.log(e))}
                color={"success"}
              >
                Создать
              </LoadingButton>
            </DialogActions>
          </form>
        </DialogContent>
      </UserDialogBox>
    </>
  );
};
