import { appGetUserById } from '../../api/user/appUser'
import { useRecoilState } from 'recoil'
import { mobAppUserAtom } from '../../states/appUser'
import { LS_MOB_APP_CURRENT_USER_ID_KEY } from '../../utils/appConstants'

const UseUserHook = () => {
  const [user, setUser] = useRecoilState(mobAppUserAtom)
  const { info: currentUser, isFetching: isUserFetching } = user

  const fetchUser = async () => {
    setUser(prev => ({
      ...prev,
      isFetching: true,
    }))

    await appGetUserById(
      localStorage.getItem(LS_MOB_APP_CURRENT_USER_ID_KEY ?? 1),
    )
      .then(response => {
        setUser(prev => ({
          info: response.data,
          isFetching: false,
        }))
      })
      .catch(error => {
        console.log(error)

        setUser(prev => ({
          ...prev,
          isFetching: false,
        }))
      })
  }

  return {
    currentUser,
    isUserFetching,
    fetchUser,
  }
}

export default UseUserHook
