import { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const AppCountdownTimer = ({ targetDate, timerName }) => {
  const [timer, setTimer] = useState({
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const [timerExpired, setTimerExpired] = useState(false)
  const timerIntervalRef = useRef(null)

  const calculateTimeRemaining = () => {
    const now = new Date()
    const difference = targetDate - now

    if (difference <= 0) {
      clearInterval(timerIntervalRef.current)
      setTimerExpired(true)
      setTimer({ months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 })
    } else {
      const months = Math.floor(difference / (1000 * 60 * 60 * 24 * 30))
      const days = Math.floor(
        (difference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24),
      )
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((difference % (1000 * 60)) / 1000)

      setTimer({
        months: months,
        days: days,
        hours: hours < 10 ? `0${hours}` : hours,
        minutes: minutes < 10 ? `0${minutes}` : minutes,
        seconds: seconds < 10 ? `0${seconds}` : seconds,
      })
    }
  }

  useEffect(() => {
    timerIntervalRef.current = setInterval(() => {
      calculateTimeRemaining()
    }, 1000)

    return () => clearInterval(timerIntervalRef.current)
  }, [targetDate])

  return (
    <Box>
      {timerExpired ? (
        <Typography>Time ran out!</Typography>
      ) : (
        <Typography>
          {timerName ?? 'Timer'} expires in:
          <Typography fontWeight="bold">
            {timer.months}months {timer.days}days {timer.hours}:{timer.minutes}:
            {timer.seconds}
          </Typography>
        </Typography>
      )}
    </Box>
  )
}

export default AppCountdownTimer
