import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#AB1916",
      light: "#E31015",
    },
    secondary: {
      main: "#D1D1D4",
      light: "#F9F9F9",
      dark: "#404042",
    },
    background: {
      default: "#F1F3F5",
    },
    status: {
      error: '#d31216',
      success: '#50b400',
    }
  },
});
