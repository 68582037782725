import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'

const AppVerifiedButton = ({ isVerified }) => {
  return (
    <>
      {isVerified ? (
        <ThumbUpOffAltIcon color="success" />
      ) : (
        <ThumbDownOffAltIcon color="error" />
      )}
    </>
  )
}

export default AppVerifiedButton
