import { useEffect, useState } from 'react'
import {
  appGetAllCommissions,
  appSetFailedPaymentTransaction,
  appSetSuccessPaymentTransaction,
} from '../../api/finance/appFinance'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import AppReloadButton from '../../ui/AppReloadButton'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import LinearProgress from '@mui/material/LinearProgress'
import TableContainer from '@mui/material/TableContainer'
import { COMMISSION_STATUSES, userFullName } from '../../utils/appConstants'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff'
import AppNotFoundData from '../../ui/AppNotFoundData'
import NotificationModal from '../../components/NotificationModal'
import toast, { Toaster } from 'react-hot-toast'
import { useNotification } from '../../hooks/useNotification'
import GoToProfileTableButton from '../../components/table-buttons/GoToProfileTableButton'
import SendNotificationTableButton from '../../components/table-buttons/SendNotificationTableButton'

const CommissionsTable = ({ activeStatus, resetStatus }) => {
  const [commissions, setCommissions] = useState([])
  const [isFetchingCommission, setIsFetchingCommission] = useState(false)

  const [sortedCommissions, setSortedCommissions] = useState(commissions)
  const resetFilters = () => {
    setSortedCommissions(commissions)
    resetStatus()
  }

  const confirmPaymentCommission = async paymentId => {
    try {
      await appSetSuccessPaymentTransaction(paymentId).then(respone => {
        fetchCommissions()
      })
    } catch (error) {
      console.log(error)
    }
  }
  const rejectPaymentCommission = async paymentId => {
    try {
      await appSetFailedPaymentTransaction(paymentId).then(respone => {
        fetchCommissions()
      })
    } catch (error) {
      console.log(error)
    }
  }

  const fetchCommissions = async () => {
    setIsFetchingCommission(true)

    try {
      appGetAllCommissions().then(response => {
        setCommissions(response.data)

        setIsFetchingCommission(false)
      })
    } catch (error) {
      console.log('Error: ', error)

      setIsFetchingCommission(false)
    }
  }

  useEffect(() => {
    if (activeStatus === 'All') {
      setSortedCommissions(commissions)

      return
    }

    setSortedCommissions(
      commissions.filter(commission => commission.status === activeStatus),
    )
  }, [activeStatus, commissions])

  useEffect(() => {
    fetchCommissions()
  }, [])

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <AppReloadButton
            isLoading={isFetchingCommission}
            handleClick={fetchCommissions}
          />
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>Full name</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Network</TableCell>
            <TableCell align="center">Wallet</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetchingCommission && (
            <TableRow>
              <TableCell sx={{ padding: 0 }} colSpan={8}>
                <LinearProgress style={{ width: '100%' }} />
              </TableCell>
            </TableRow>
          )}
          {sortedCommissions.map(commission => (
            <Row
              key={commission.id}
              row={commission}
              confirmPaymentCommission={confirmPaymentCommission}
              rejectPaymentCommission={rejectPaymentCommission}
            />
          ))}
          {sortedCommissions.length === 0 && !isFetchingCommission && (
            <TableRow>
              <TableCell sx={{ padding: 0 }} colSpan={8}>
                <AppNotFoundData handleReset={resetFilters} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Row = ({ row, confirmPaymentCommission, rejectPaymentCommission }) => {
  const { user } = row

  const showSuccessNotify = text => toast.success(text)
  const showErrorNotify = text => toast.error(text)

  const {
    notification,
    activeNotificationReceiver,
    activeNotificationType,
    isConfirmModalOpen,
    openConfirmModal,
    closeConfirmModal,
    isNotificationSending,
    handleNotificationChange,
    handleNotificationTypeTabClick,
    handleSendPersonalNotification,
    handleSendNotificationForAllUsers,
  } = useNotification({ userId: user.id, showSuccessNotify, showErrorNotify })

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell>{userFullName(user.first_name, user.last_name)}</TableCell>
        <TableCell>
          {row.amount} {row.amount_currency}
        </TableCell>
        <TableCell align="center">{row.status}</TableCell>
        <TableCell align="center">{row.network}</TableCell>
        <TableCell align="center">{row.wallet}</TableCell>
        <TableCell>{row.created_at}</TableCell>
        <TableCell align="center">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              columnGap: 1,
            }}
          >
            <Tooltip title="Confirm payment commission." placement="top" arrow>
              <IconButton
                disabled={row.status !== COMMISSION_STATUSES.CHECK}
                onClick={() => confirmPaymentCommission(row.id)}
              >
                <CreditScoreIcon
                  color={
                    row.status === COMMISSION_STATUSES.CHECK
                      ? 'success'
                      : 'disabled'
                  }
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Didn't recieve commission." placement="top" arrow>
              <IconButton
                disabled={row.status !== COMMISSION_STATUSES.CHECK}
                onClick={() => rejectPaymentCommission(row.id)}
              >
                <CreditCardOffIcon
                  color={
                    row.status === COMMISSION_STATUSES.CHECK
                      ? 'error'
                      : 'disabled'
                  }
                />
              </IconButton>
            </Tooltip>
            <GoToProfileTableButton
              userName={userFullName(user.first_name, user.last_name)}
              userId={user.id}
            />
            <SendNotificationTableButton
              userName={userFullName(user.first_name, user.last_name)}
              openConfirmModal={openConfirmModal}
            />
          </Box>
        </TableCell>
      </TableRow>

      <NotificationModal
        isOpen={isConfirmModalOpen}
        notification={notification}
        selectedUser={user}
        isConfirmActionDisabled={isNotificationSending}
        activeNotificationReceiver={activeNotificationReceiver}
        activeNotificationType={activeNotificationType}
        handleNotificationChange={handleNotificationChange}
        handleCloseModal={closeConfirmModal}
        handleNotificationTypeTabClick={handleNotificationTypeTabClick}
        handleSendPersonalNotification={handleSendPersonalNotification}
        handleSendNotificationForAllUsers={handleSendNotificationForAllUsers}
      />

      <Toaster position="top-right" />
    </>
  )
}

export default CommissionsTable
