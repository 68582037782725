import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import AppFancybox from "../ui/AppFancybox";
import AppInput from "../ui/AppInput";
import AppConfirmModal from "../ui/AppConfirmModal";
import { theme } from "../../theme/defaultTheme";
import { appDocumentsVerify } from "../api/user/appUser";
import toast, { Toaster } from "react-hot-toast";

//FIXME: в данном файле запутанная верстка, т.к. в самом конце пришлось быстро переделывать фронт
const UserDocumentsChanger = ({
  name,
  userId,
  isVerified,
  documents,
  updateDataFunction,
}) => {
  const {
    passport_1,
    passport_2,
    residence_permit_1,
    residence_permit_2,
    proofAddress,
  } = documents;

  const documentsList = [
    {
      type: "Passport",
      link1: passport_1,
      link2: passport_2,
    },
    {
      type: "Residence permit",
      link1: residence_permit_1,
      link2: residence_permit_2,
    },
    {
      type: "Proof address",
      link: proofAddress,
    },
  ];

  const [documentsComment, setDocumentsComment] = useState("");
  const handleDocumentsCommentChange = (event) =>
    setDocumentsComment(event.target.value);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const handleOpenConfirmModal = () => setIsConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setIsConfirmModalOpen(false);

  const [isSending, setIsSending] = useState(false);
  const showErrorNotify = (text) => toast.error(text);

  const handleDisapproveModalOpen = () => {
    setIsApproveAction(false);

    handleOpenConfirmModal();
  };
  const handleApproveModalOpen = () => {
    setIsApproveAction(true);

    handleOpenConfirmModal();
  };

  // FIXME: вынужденный костыль для экономии времени
  const [isApproveAction, setIsApproveAction] = useState(false);
  const handleModalConfirmAction = async () => {
    setIsSending(true);

    // бэк ожидает сообщение без комментария - действием подтверждения
    // с комментарием - отправка документов на переделывание
    const message = isApproveAction ? "" : documentsComment;

    try {
      await appDocumentsVerify(userId, isApproveAction, message).then(() =>
        updateDataFunction()
      );
    } catch (error) {
      console.log("Error: ", error);

      showErrorNotify("Error the status didn't change!");
    } finally {
      setIsSending(false);
      setIsConfirmModalOpen(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, 300px)",
          flexWrap: "wrap",
          columnGap: 2,
          rowGap: 1,
        }}
      >
        {documentsList.map((document, index) => (
          <Box
            sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
            key={`${document}-${index}`}
          >
            <AppFancybox
              options={{
                Carousel: {
                  infinite: false,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  columnGap: "12px",
                  rowGap: "8px",
                }}
              >
                {document.type !== "Proof address" &&
                  [document.link1, document.link2].map((link, i) =>
                    link ? (
                      <a data-fancybox="gallery" href={link}>
                        <Button
                          sx={{ width: "100%" }}
                          color="primary"
                          variant="outlined"
                          disabled={!link}
                        >
                          Show {i + 1} {document.type}
                        </Button>
                      </a>
                    ) : (
                      <Button
                        color="primary"
                        variant="outlined"
                        disabled={!link}
                      >
                        Show {i + 1} {document.type}
                      </Button>
                    )
                  )}

                {document.type === "Proof address" && (
                  <>
                    {document.link ? (
                      <a data-fancybox="gallery" href={document.link}>
                        <Button
                          sx={{ width: "100%" }}
                          color="primary"
                          variant="outlined"
                          disabled={!document.link}
                        >
                          Show {document.type}
                        </Button>
                      </a>
                    ) : (
                      <Button
                        color="primary"
                        variant="outlined"
                        disabled={!document.link}
                      >
                        Show {document.type}
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </AppFancybox>
          </Box>
        ))}
      </Box>

      {!isVerified && (
        <>
          <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
            <AppInput
              sx={{ marginTop: 1 }}
              rows={4}
              variant="outlined"
              isMultiline
              label={`Disapprove comment for ${name}`}
              value={documentsComment}
              handleChange={handleDocumentsCommentChange}
            />
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
              <Button
                variant="outlined"
                sx={{ width: "fit-content" }}
                onClick={handleDisapproveModalOpen}
              >
                Disapprove
              </Button>
              <Button
                variant="outlined"
                color="success"
                sx={{ width: "fit-content" }}
                onClick={handleApproveModalOpen}
              >
                Approve
              </Button>
            </Box>
          </Box>

          <AppConfirmModal
            isOpen={isConfirmModalOpen}
            handleCloseModal={handleCloseConfirmModal}
            handleApproveAction={handleModalConfirmAction}
            isConfirmActionDisabled={
              isSending || (!isApproveAction && documentsComment.length === 0)
            }
          >
            <Typography variant="subtitle2" color={theme.palette.grey[600]}>
              Change documents status to{" "}
              {isApproveAction ? "Approved" : "Disapproved"}?
            </Typography>
            {!isVerified && !isApproveAction && (
              <AppInput
                sx={{ marginTop: 3, width: "100%" }}
                rows={4}
                variant="outlined"
                isMultiline
                label={`Disapprove comment for ${name}`}
                value={documentsComment}
                handleChange={handleDocumentsCommentChange}
              />
            )}
          </AppConfirmModal>
        </>
      )}

      <Toaster position="top-right" />
    </Box>
  );
};

export default UserDocumentsChanger;
