import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'
import { formatDateToString } from '../utils/formatDateToString'
import { theme } from '../../theme/defaultTheme'

const UserAdditionalInfo = ({ user }) => {
  const { phone_number, email, created_at, updated_at } = user

  const handlePhoneClick = () => (window.location.href = `tel:${phone_number}`)
  const handleEmailClick = () => (window.location.href = `mailto:${email}`)

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        columnGap: 4,
        rowGap: 2,
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.grey[600] }}
          >
            Phone:{' '}
          </Typography>
          <Link variant="subtitle2" href="#" onClick={handlePhoneClick}>
            {phone_number}
          </Link>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.grey[600] }}
          >
            Email:{' '}
          </Typography>
          <Link variant="subtitle2" href="#" onClick={handleEmailClick}>
            {email}
          </Link>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}
          component="div"
          align="right"
        >
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.grey[600] }}
          >
            Created at:{' '}
          </Typography>
          <Typography variant="subtitle2">
            {formatDateToString(created_at)}
          </Typography>
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}
          component="div"
          align="right"
        >
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.grey[600] }}
          >
            Last activity:{' '}
          </Typography>
          <Typography variant="subtitle2">
            {formatDateToString(updated_at)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default UserAdditionalInfo
