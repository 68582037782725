import { useRecoilState } from "recoil";
import { authAtom } from "../states";
import axios from "axios";

export { useAxiosWrapper };
const token = process.env.REACT_APP_MOBILE_APP_AUTH_TOKEN

function useAxiosWrapper() {
  const [auth, setAuth] = useRecoilState(authAtom);
  const controller = new AbortController();

  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    delete: request("DELETE"),
  };

  function request(method) {
    return (url, data, rest) => {
      return axios
        .request({
          ...rest,
          baseURL:
            process.env.REACT_APP_NODE_ENV === "development"
                ? 'https://test.api.da-capital.io/v1'
                : 'https://demo.api.da-capital.io/v1',
          url: url,
          method: method,
          headers: { ...authHeader() },
          data: data,
          signal: controller.signal,
        })
        .catch((e) => handleError(e)).then((value) => delete value.headers['token']);
    };
  }

  function authHeader() {
    return auth ? { Authorization: `Bearer ${auth}`, token: token } : {token: token};
  }

  function handleError(error) {
    if (error.response && error.response.data.detail === "Invalid auth token") {
      controller.abort();
      localStorage.removeItem("user");
      setAuth("");
    }
    return Promise.reject(error);
  }
}
