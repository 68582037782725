import { CloudDownload } from '@mui/icons-material'
import { Button } from '@mui/material'

const DownloadButton = ({ sx, text, isDisabled, handleClick }) => {
  return (
    <Button
      sx={sx}
      disabled={isDisabled}
      component="label"
      variant="contained"
      startIcon={<CloudDownload />}
      onClick={handleClick}
    >
      {text ?? 'Download file'}
    </Button>
  )
}

export default DownloadButton
