import Box from '@mui/material/Box'
import CommissionsTable from './CommissionsTable'
import TransactionsTable from './transactions/TransactionsTable'
import Tab from '@mui/material/Tab'
import { FormControl, InputLabel, MenuItem, Select, Tabs } from '@mui/material'
import { useState } from 'react'
import {
  COMMISSION_STATUSES,
  TRANSACTION_STATUSES,
} from '../../utils/appConstants'

const commissionStatuses = [
  COMMISSION_STATUSES.PAID,
  COMMISSION_STATUSES.CHECK,
  COMMISSION_STATUSES.CREATED,
  COMMISSION_STATUSES.NOT_PAYMENT,
]

const transactionStatuses = [
  TRANSACTION_STATUSES.CHECK,
  TRANSACTION_STATUSES.CREATED,
  TRANSACTION_STATUSES.PAID,
  TRANSACTION_STATUSES.REFUSAL,
  TRANSACTION_STATUSES.DOCUMENT_PREPARATION,
  TRANSACTION_STATUSES.DOCUMENT_SIGNING,
]

const FinanceView = () => {
  const [activeTab, setActiveTab] = useState('Transactions')
  const handleChangeTab = (e, tab) => setActiveTab(tab)

  const [activeCommissionStatus, setActiveCommissionStatus] = useState('All')
  const handleActiveCommissionStatusChange = e =>
    setActiveCommissionStatus(e.target.value)
  const handleActiveCommissionStatusReset = () =>
    setActiveCommissionStatus('All')

  const [activeTransactionStatus, setActiveTransactionStatus] = useState('All')
  const handleActiveTransactionStatusChange = e =>
    setActiveTransactionStatus(e.target.value)
  const handleActiveTransactionStatusReset = () =>
    setActiveTransactionStatus('All')

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          rowGap: 1,
          columnGap: 2,
        }}
      >
        <Tabs
          value={activeTab}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="wrapped label tabs example"
          onChange={handleChangeTab}
        >
          <Tab value="Transactions" label="Transactions (FORTIS, CRYPTO)" />
          <Tab value="Commissions" label="Commissions (PRIME)" />
        </Tabs>

        {activeTab === 'Transactions' && (
          <StatusFilters
            selectWidth={210}
            statuses={transactionStatuses}
            value={activeTransactionStatus}
            handleChange={handleActiveTransactionStatusChange}
          />
        )}
        {activeTab === 'Commissions' && (
          <StatusFilters
            selectWidth={150}
            statuses={commissionStatuses}
            value={activeCommissionStatus}
            handleChange={handleActiveCommissionStatusChange}
          />
        )}
      </Box>
      {activeTab === 'Transactions' && (
        <TransactionsTable
          activeStatus={activeTransactionStatus}
          resetStatus={handleActiveTransactionStatusReset}
        />
      )}
      {activeTab === 'Commissions' && (
        <CommissionsTable
          activeStatus={activeCommissionStatus}
          resetStatus={handleActiveCommissionStatusReset}
        />
      )}
    </Box>
  )
}

const StatusFilters = ({ statuses, value, handleChange, selectWidth }) => {
  return (
    <FormControl sx={{ minWidth: selectWidth ?? 150 }} size="small">
      <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={value}
        defaultValue="All"
        label="Status"
        onChange={handleChange}
      >
        <MenuItem value="All">All</MenuItem>
        {statuses.map(status => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default FinanceView
