import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Edit, Save } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import { useAxiosWrapper } from "../../../../helpers/axiosWrapper";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

export const CSChangePercent = ({
  doc_id,
  setCryptoSafeProduct,
  setCryptoSafe,
  CryptoSafeProduct,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPercentage, setTotalPercentage] = useState();
  const axiosWrapper = useAxiosWrapper();
  const classes = useStyles();

  const createCSForm = Yup.object().shape({
    yearPercentage: Yup.number()
      .min(0.1, "Year percentage must be greater than 0")
      .required("Year percentage is required")
      .typeError("Year percentage must be a number"),
  });

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(createCSForm),
    mode: "onTouched",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTotalPercentage(null);
    reset();
  };

  const handleChangePercent = () => {
    setLoading(true);
    axiosWrapper
      .put(`cryptosafe/totalPercent/${doc_id}/${totalPercentage}`)
      .then(() => {
        setCryptoSafe((prev) => {
          return prev.map((item) => {
            if (item.docs.id === doc_id) {
              return { ...item, total_percent: totalPercentage };
            } else {
              return item;
            }
          });
        });
        setCryptoSafeProduct((prev) =>
          prev ? { ...prev, total_percent: totalPercentage } : prev
        );
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        size={"small"}
        disableRipple
        disableTouchRipple
      >
        <Edit fontSize="inherit" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change Percent</DialogTitle>
        <DialogContent>
          <form style={{ marginTop: "20px" }}>
            <Controller
              render={({ field: { ref, onChange, ...field }, fieldState }) => (
                <TextField
                  type={"number"}
                  name={"yearPercentage"}
                  label={"Year Percent"}
                  inputRef={ref}
                  fullWidth
                  autoFocus
                  variant="standard"
                  onChange={(event) => {
                    onChange(event.target.value);
                    setTotalPercentage(
                      (event.target.value / 12) * CryptoSafeProduct.term
                    );
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>%</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: "0",
                    max: "100",
                  }}
                  className={classes.input}
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
              name={"yearPercentage"}
              control={control}
              defaultValue={12.5}
            />
          </form>
          <TextField
            sx={{ marginTop: "20px" }}
            label={"Total Percentage"}
            value={totalPercentage ? totalPercentage : "0"}
            variant={"standard"}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            disabled={!totalPercentage}
            startIcon={<Save />}
            loadingPosition="start"
            loading={loading}
            onClick={handleSubmit(handleChangePercent, (err) =>
              console.log(err)
            )}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
