export class IncomeCalculator {
  constructor(deposit, start_date, end_date, total_percent) {
    this.deposit = deposit;
    this.start_date = start_date;
    this.end_date = end_date;
    this.total_percent = total_percent;
  }

  deposit; // сумма вклада
  start_date; // дата начала вклада в unixtime
  end_date; // дата окончания вклада в unixtime
  total_percent; // процент по вкладу (на весь срок)

  /**Returns a percentage per day. */
  getDayPercent() {
    return this.total_percent / ((this.end_date - this.start_date) / 86400);
  }
  /**Returns a number of days since CryptoSafe was created. */
  getDaysPass() {
    return (Date.now() / 1000 - this.start_date) / 86400;
  }
  /**Returns an income percentage of CryptoSafe for the current day from the moment of its creation. */
  getIncomePercent() {
    return this.getDayPercent() * this.getDaysPass();
  }
  /**Returns an income for the current day. */
  getIncome() {
    return (this.deposit * this.getIncomePercent()) / 100;
  }
  /**Returns a balance for the current day. */
  getBalance() {
    return this.deposit + this.getIncome();
  }
}
