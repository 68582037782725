import { useEffect, useState } from 'react'
import {
  appGetAllTransactions,
  appSetCheckTransactionStatus,
} from '../../../api/finance/appFinance'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import AppReloadButton from '../../../ui/AppReloadButton'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import LinearProgress from '@mui/material/LinearProgress'
import TableContainer from '@mui/material/TableContainer'
import { TRANSACTION_STATUSES, userFullName } from '../../../utils/appConstants'
import toast, { Toaster } from 'react-hot-toast'
import { useNotification } from '../../../hooks/useNotification'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Collapse from '@mui/material/Collapse'
import NotificationModal from '../../../components/NotificationModal'
import { formatDateToString } from '../../../utils/formatDateToString'
import AppNotFoundData from '../../../ui/AppNotFoundData'
import GoToProfileTableButton from '../../../components/table-buttons/GoToProfileTableButton'
import SendNotificationTableButton from '../../../components/table-buttons/SendNotificationTableButton'
import { Button, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AppUploadFileButtons from '../../../ui/AppUploadFileButtons'
import axiosInstance from '../../../api/appAxiosInstance'
import { appEndpoints } from '../../../api/appEndpoints'
import { theme } from '../../../../theme/defaultTheme'
import DownloadButton from '../../../components/DownloadButton'
import { handleFileDownload } from '../../../utils/downloadFile'
import TransactionStatusChanger from './TransactionStatusChanger'

const TransactionsTable = ({ activeStatus, resetStatus }) => {
  const [transactions, setTransactions] = useState([])
  const [isFetchingTransaction, setIsFetchingTransaction] = useState(false)

  const [sortedTransactions, setSortedTransactions] = useState(transactions)
  const resetFilters = () => {
    setSortedTransactions(transactions)
    resetStatus()
  }

  const fetchTransactions = async () => {
    setIsFetchingTransaction(true)

    try {
      await appGetAllTransactions().then(response => {
        setTransactions(response.data)

        setIsFetchingTransaction(false)
      })
    } catch (error) {
      console.log('Error: ', error)

      setIsFetchingTransaction(false)
    }
  }

  useEffect(() => {
    fetchTransactions()
  }, [])

  useEffect(() => {
    if (activeStatus === 'All') {
      setSortedTransactions(transactions)

      return
    }

    setSortedTransactions(
      transactions.filter(commission => commission.status === activeStatus),
    )
  }, [activeStatus, transactions])

  return (
    <TableContainer sx={{ maxHeight: '65vh' }} component={Paper}>
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <AppReloadButton
            isLoading={isFetchingTransaction}
            handleClick={fetchTransactions}
          />
          <TableRow>
            <TableCell />
            <TableCell sx={{ color: theme.palette.grey[600] }}>ID</TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }}>
              Full name
            </TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }} align="right">
              Amount
            </TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }} align="left">
              Status
            </TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }} align="left">
              Network
            </TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }} align="left">
              Wallet
            </TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }} align="left">
              Date
            </TableCell>
            <TableCell sx={{ color: theme.palette.grey[600] }} align="center">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetchingTransaction && (
            <TableRow>
              <TableCell sx={{ padding: 0 }} colSpan={9}>
                <LinearProgress style={{ width: '100%' }} />
              </TableCell>
            </TableRow>
          )}
          {sortedTransactions.map(commission => (
            <Row
              key={commission.id}
              row={commission}
              updateTable={fetchTransactions}
            />
          ))}

          {sortedTransactions.length === 0 && !isFetchingTransaction && (
            <TableRow>
              <TableCell sx={{ padding: 0 }} colSpan={9}>
                <AppNotFoundData handleReset={resetFilters} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const STATUSES_WHITE_LIST = [
  TRANSACTION_STATUSES.CHECK,
  TRANSACTION_STATUSES.PAID,
  TRANSACTION_STATUSES.REFUSAL,
  TRANSACTION_STATUSES.DOCUMENT_SIGNING,
]

const Row = ({ row, updateTable }) => {
  const { user } = row

  const [isRowOpen, setIsRowOpen] = useState(false)

  const showSuccessNotify = text => toast.success(text)
  const showErrorNotify = text => toast.error(text)

  const {
    notification,
    activeNotificationReceiver,
    activeNotificationType,
    isConfirmModalOpen,
    openConfirmModal,
    closeConfirmModal,
    isNotificationSending,
    handleNotificationChange,
    handleNotificationTypeTabClick,
    handleSendPersonalNotification,
    handleSendNotificationForAllUsers,
  } = useNotification({ userId: user.id, showSuccessNotify, showErrorNotify })

  const [isSending, setIsSending] = useState(false)
  const handleFileSend = async file => {
    setIsSending(true)

    try {
      const formData = new FormData()
      formData.append('file', file)

      // не понял почему не отрабатывает
      // await appUploadTransactionFile(transactionId: row.id, formData: file)
      await axiosInstance
        .post(`${appEndpoints.FINANCE.UPLOAD_FILE}/${row.id}`, formData)
        .then(() => updateTable())
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setIsSending(false)
    }
  }

  const handleCheckStatusChange = async id => {
    setIsSending(true)

    try {
      await appSetCheckTransactionStatus(id).then(() => updateTable())
    } catch (error) {
      console.log('Error: ', error)

      showErrorNotify('Error changing transaction status')
    } finally {
      setIsSending(false)
    }
  }

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell width={24}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsRowOpen(!isRowOpen)}
          >
            {isRowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell>{userFullName(user.first_name, user.last_name)}</TableCell>
        <TableCell align="right">
          {row.amount} {row.amount_currency}
        </TableCell>
        <TableCell align="left">{row.status}</TableCell>
        <TableCell align="left">{row.network}</TableCell>
        <TableCell align="left">{row.wallet}</TableCell>
        <TableCell align="left">
          {formatDateToString(row.created_at_date)}
        </TableCell>
        <TableCell align="center">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              columnGap: 1,
            }}
          >
            <GoToProfileTableButton
              userName={userFullName(user.first_name, user.last_name)}
              userId={user.id}
            />
            <SendNotificationTableButton
              userName={userFullName(user.first_name, user.last_name)}
              openConfirmModal={openConfirmModal}
            />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={9}>
          <Collapse in={isRowOpen} timeout="auto" unmountOnExit>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                gap: 2,
                width: '100%',
                padding: '16px 0 16px 16px',
                boxSizing: 'border-box',
              }}
            >
              <Box sx={{ width: 'fit-content' }}>
                <Typography variant="subtitle1" color={theme.palette.grey[600]}>
                  Files:
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    columnGap: 3,
                    rowGap: 2,
                  }}
                >
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}
                  >
                    <DownloadButton
                      isDisabled={!row.contract_user}
                      text="Download user file"
                      handleClick={() => handleFileDownload(row.contract_user)}
                    />
                    {!row.contract_user && (
                      <Tooltip
                        title="User document is not loaded!"
                        placement="top"
                        arrow
                      >
                        <InfoOutlinedIcon sx={{ fontSize: 16 }} color="info" />
                      </Tooltip>
                    )}
                  </Box>
                  {(row.status === TRANSACTION_STATUSES.CHECK ||
                    row.status === TRANSACTION_STATUSES.CREATED) && (
                    <Box>
                      <AppUploadFileButtons
                        sendText="Send file for user"
                        uploadText="Upload file for user"
                        isSending={isSending}
                        handleFileSend={handleFileSend}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              {row.status === TRANSACTION_STATUSES.DOCUMENT_SIGNING && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    color={theme.palette.grey[600]}
                  >
                    Take into processing:
                  </Typography>
                  <Button
                    variant="outlined"
                    disabled={isSending}
                    onClick={() => handleCheckStatusChange(row.id)}
                  >
                    Set status Check
                  </Button>
                </Box>
              )}
              {row.status === TRANSACTION_STATUSES.CHECK && (
                <TransactionStatusChanger
                  transactionId={row.id}
                  showErrorNotify={showErrorNotify}
                  updateTable={updateTable}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <NotificationModal
        isOpen={isConfirmModalOpen}
        notification={notification}
        selectedUser={user}
        isConfirmActionDisabled={isNotificationSending}
        activeNotificationReceiver={activeNotificationReceiver}
        activeNotificationType={activeNotificationType}
        handleNotificationChange={handleNotificationChange}
        handleCloseModal={closeConfirmModal}
        handleNotificationTypeTabClick={handleNotificationTypeTabClick}
        handleSendPersonalNotification={handleSendPersonalNotification}
        handleSendNotificationForAllUsers={handleSendNotificationForAllUsers}
      />

      <Toaster position="top-right" />
    </>
  )
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export default TransactionsTable
