import { UserDialogBox } from "../UserDialog/UserDialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { memo, useLayoutEffect } from "react";
import { useAxiosWrapper } from "../../helpers/axiosWrapper";
import { IncomeCalculator } from "../../utils/IncomeCalculator";
import Skeleton from "@mui/material/Skeleton";

const PaperContainer = styled(Paper)(() => ({
  height: "100px",
  width: "150px",
  margin: "10px",
  display: "flex",
  flexDirection: "column",
}));

export const CryptoSafeDialog = memo(
  ({ open, handleClose, product_id, user_code }) => {
    const [CryptoSafe, setCryptoSafe] = React.useState();
    const axiosWrapper = useAxiosWrapper();
    const [loading, setLoading] = React.useState(false);
    const [user, setUser] = React.useState();

    const calc = new IncomeCalculator(
      CryptoSafe?.sum,
      CryptoSafe?.start_date,
      CryptoSafe?.end_date,
      CryptoSafe?.total_percent
    );

    useLayoutEffect(() => {
      if (open) {
        setLoading(true);
        axiosWrapper
          .get("cryptosafe/product/" + product_id)
          .then(({ data }) => {
            setCryptoSafe(data);
          })
          .then(() => {
            axiosWrapper.get(`users/${user_code}`).then(({ data }) => {
              setUser(data);
              setLoading(false);
            });
          });
      }

      return () => {
        setCryptoSafe(undefined);
      };
    }, [open, product_id, user_code]);

    return (
      <UserDialogBox
        maxWidth={"sm"}
        fullWidth
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          CryptoSafe Product Info
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#FFF",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px 0",
              }}
            >
              <Typography variant={"h4"}>
                {!loading ? (
                  user?.name + " " + user?.surname
                ) : (
                  <Skeleton sx={{ minWidth: { xs: "150px", xl: "300px" } }} />
                )}
              </Typography>
            </Box>
            <div
              style={{
                height: "fit-content",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <PaperContainer>
                <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                  Deposit
                </Typography>
                <Typography
                  sx={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "1.5rem",
                    wordWrap: "anywhere",
                  }}
                  variant={"h4"}
                >
                  {loading ? (
                    <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                  ) : CryptoSafe ? (
                    `$${CryptoSafe?.sum.toFixed(2)}`
                  ) : (
                    "$0"
                  )}
                </Typography>
              </PaperContainer>
              <PaperContainer>
                <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                  Term
                </Typography>
                <Typography
                  sx={{
                    alignSelf: "center",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    fontSize: "1.8rem",
                  }}
                  variant={"h4"}
                >
                  {loading ? (
                    <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                  ) : CryptoSafe?.term % 12 ? (
                    CryptoSafe?.term + " " + "m."
                  ) : (
                    CryptoSafe?.term / 12 + " " + "yr."
                  )}
                </Typography>
              </PaperContainer>
              <PaperContainer>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                    Total Percentage
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    fontSize: "1.7rem",
                  }}
                  variant={"h4"}
                >
                  {loading ? (
                    <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                  ) : CryptoSafe ? (
                    `${CryptoSafe?.total_percent.toFixed(2)}%`
                  ) : (
                    "0%"
                  )}
                </Typography>
              </PaperContainer>
              <Paper
                sx={{
                  minHeight: "120px",
                  height: "fit-content",
                  width: "100%",
                  margin: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                    Current contract duration
                  </Typography>
                </Box>
                {CryptoSafe &&
                CryptoSafe?.start_date &&
                CryptoSafe.start_date > 0 ? (
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                      fontSize: "1.7rem",
                      wordWrap: "anywhere",
                      margin: "auto 0",
                    }}
                    variant={"h4"}
                  >
                    {loading ? (
                      <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                    ) : (
                      CryptoSafe &&
                      `${new Date(
                        CryptoSafe?.start_date * 1000
                      ).toLocaleDateString("ru-RU", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}`
                    )}{" "}
                    -{" "}
                    {loading ? (
                      <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                    ) : (
                      CryptoSafe &&
                      `${new Date(
                        CryptoSafe?.end_date * 1000
                      ).toLocaleDateString("ru-RU", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}`
                    )}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                      fontSize: "1.7rem",
                      wordWrap: "anywhere",
                      margin: "auto 0",
                    }}
                    variant={"h4"}
                  >
                    {loading ? (
                      <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                    ) : (
                      "Payment in progress..."
                    )}
                  </Typography>
                )}
              </Paper>
              <PaperContainer>
                <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                  Balance
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    fontSize: "1.5rem",
                    wordWrap: "anywhere",
                    textAlign: "center",
                  }}
                  variant={"h4"}
                >
                  {loading ? (
                    <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                  ) : (
                    `$${calc.getBalance().toFixed(2)}`
                  )}
                </Typography>
              </PaperContainer>
              <PaperContainer>
                <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                  Income Percentage
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    fontSize: "1.7rem",
                    textAlign: "center",
                  }}
                  variant={"h4"}
                >
                  {loading ? (
                    <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                  ) : (
                    `${calc.getIncomePercent().toFixed(2)}%`
                  )}
                </Typography>
              </PaperContainer>
              <PaperContainer>
                <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                  Total Income
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    fontSize: "1.6rem",
                    wordWrap: "anywhere",
                    textAlign: "center",
                  }}
                  variant={"h4"}
                >
                  {loading ? (
                    <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                  ) : (
                    `$${calc.getIncome().toFixed(2)}`
                  )}
                </Typography>
              </PaperContainer>
            </div>
          </>
        </DialogContent>
      </UserDialogBox>
    );
  }
);
