import { Box, Paper, styled, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "./Login.css";
import LoginIcon from "@mui/icons-material/Login";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { authAtom } from "../../states";
import { useLocation, useNavigate } from "react-router-dom";
import { useAxiosWrapper } from "../../helpers/axiosWrapper";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Alert from "@mui/material/Alert";
import axios from "axios";
import {
    LS_MOB_APP_ACCESS_TOKEN_KEY,
    LS_MOB_APP_REFRESH_TOKEN_KEY
} from "../../tsfc-mobile-application/utils/appConstants";

const LogoBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexFlow: "column",
  marginTop: "10px",
}));
const baseURL =
    process.env.REACT_APP_NODE_ENV === 'development'
        ? 'https://test.api.da-capital.io/v1'
        : 'https://demo.api.da-capital.io/v1'

const InputBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexFlow: "column",
  gap: "10px",
  marginBottom: "10px",
}));

export const Login = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const setAuth = useSetRecoilState(authAtom);
  const axiosInstance = useAxiosWrapper();
  const { state } = useLocation();
  const [error, setError] = useState(state?.reason);

  const loginForm = Yup.object().shape({
    login: Yup.string().required("Login is required"),
    password: Yup.string().required("Password is required"),
  });

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(loginForm),
    mode: "onTouched",
  });

  const adminLogin = async (data) => {
    setLoading(true);

      try {
          console.log('baseURL', baseURL)
          const dataAuth = await axios.post(`${baseURL}/auth/admin/login`,{
              username: data.login,
              password: data.password,
          }, {
              headers: {
                  token: process.env.REACT_APP_MOBILE_APP_AUTH_TOKEN
              }
          })
            console.log('dataAuth', dataAuth)
          if (dataAuth) {
              localStorage.setItem("user", JSON.stringify(dataAuth.data.accessToken));
              localStorage.setItem(LS_MOB_APP_ACCESS_TOKEN_KEY, dataAuth.data.accessToken)
              localStorage.setItem(
                  LS_MOB_APP_REFRESH_TOKEN_KEY,
                  dataAuth.data.refreshToken,
              )
              setAuth(dataAuth.data.accessToken);
              setLoading(false);
              navigate("/", { replace: true });
          }
      } catch (e) {
          console.log(e)
      }

    // axiosInstance
    //   .post("/auth/admin/login", {
    //       username: data.login,
    //     password: data.password,
    //   })
    //   .then((r) => {
    //       console.log('r.data', r)
    //     localStorage.setItem("user", JSON.stringify(r.data.accessToken));
    //     setAuth(r.data.accessToken);
    //     setLoading(false);
    //     navigate("/", { replace: true });
    //   })
    //   .catch((e) => {
    //     console.log(e.response);
    //     setLoading(false);
    //     setError(e.response.data.detail);
    //   });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
      style={{
        backgroundImage: `url("https://s3.da-capital.io/static/background/admin_login.jpg")`,
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          padding: "10px 60px",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
        }}
        elevation={20}
      >
        <LogoBox>
          <img
            src="https://s3.da-capital.io/static/brand/logo_admin.svg"
            alt="logotype"
            width={120}
          />
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              marginBottom: "26px",
            }}
            style={{ userSelect: "none" }}
          >
            Admin Panel
          </Typography>
        </LogoBox>
        {error && (
          <Alert sx={{ marginBottom: "26px" }} severity="error">
            {error}
          </Alert>
        )}
        <form
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
          onSubmit={handleSubmit(
            (r) => adminLogin(r),
            (e) => {
              console.error(e);
            }
          )}
        >
          <InputBox>
            <Controller
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  name={"login"}
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label="Login"
                  type="text"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
              name={"login"}
              control={control}
              defaultValue={""}
            />

            <Controller
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  name={"password"}
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label="Password"
                  type="password"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
              name={"password"}
              control={control}
              defaultValue={""}
            />
          </InputBox>
          <LoadingButton
            type={"submit"}
            loading={loading}
            onClick={handleSubmit(
              (r) => adminLogin(r),
              (e) => {
                console.error(e);
              }
            )}
            variant="contained"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            startIcon={<LoginIcon />}
          >
            Sign in
          </LoadingButton>
        </form>
      </Paper>
    </Box>
  );
};
