import { Autocomplete, Typography } from "@mui/material";
import { styled, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import Fuse from "fuse.js";
import { useSetRecoilState } from "recoil";
import { userDialogAtom } from "../states";

const SearchFieldBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.light,
  padding: "0 10px",
  borderRadius: theme.shape.borderRadius,
  width: "40%",
}));

const SearchIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginRight: "5px",
  transform: "scale(-1, 1)",
}));

const useStyles = makeStyles({
  input: {
    padding: "4px !important",
    "& input + fieldset": {
      border: "none",
    },
  },
});

export const SearchField = ({ array }) => {
  const [searchKey, setSearchKey] = useState("");
  const setUserDialog = useSetRecoilState(userDialogAtom);

  const options = {
    includeMatches: false,
    findAllMatches: false,
    minMatchCharLength: 3,
    keys: ["code", "email", "name", "surname"],
  };

  const fuse = new Fuse(array, options);

  const classes = useStyles();

  const handleSearch = (user_object) => {
    setUserDialog({
      isOpen: true,
      code: user_object.code,
    });
    setSearchKey("");
  };

  return (
    <SearchFieldBox
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <SearchIcon />
      <Autocomplete
        sx={{ width: "100%" }}
        freeSolo
        clearOnEscape
        disableClearable
        handleHomeEndKeys
        clearOnBlur
        inputValue={searchKey}
        renderOption={(props, option) => (
          <li {...props}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {option.name} {option.surname}
              <Typography variant={"caption"} sx={{ color: "text.secondary" }}>
                {option.code}
              </Typography>
            </div>
          </li>
        )}
        filterOptions={(x) => x}
        onChange={(event, value) => handleSearch(value)}
        options={fuse.search(searchKey).map((item) => item.item)}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            sx={{ border: "none" }}
            {...params}
            InputProps={{
              ...params.InputProps,
              className: classes.input,
              type: "search",
            }}
            onChange={(e) => setSearchKey(e.target.value)}
          />
        )}
      />
    </SearchFieldBox>
  );
};
