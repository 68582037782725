import React, { useState } from 'react'
import useUserHook from '../useUser.hook'
import toast, { Toaster } from 'react-hot-toast'
import { appDepositRateChange } from '../../../api/user/appUser'
import Box from '@mui/material/Box'
import AppInput from '../../../ui/AppInput'
import { Button } from '@mui/material'

const UserDepositPercentChange = ({ deposit }) => {
  const [percent, setPercent] = useState(deposit.current_interest_rate ?? 0)
  const handlePercentChange = e => {
    const numericValue = e.target.value.replace(/[^0-9.]/g, '')
    const dots = numericValue.split('.').length - 1

    dots <= 1 && setPercent(numericValue)
  }

  const [inProgress, setInProgress] = useState(false)
  const { fetchUser } = useUserHook()
  const showErrorNotify = text => toast.error(text)

  const handleSaveChanges = async () => {
    setInProgress(true)

    await appDepositRateChange(deposit.id, +percent)
      .then(() => fetchUser())
      .catch(() => showErrorNotify("Error the percent didn't change!"))
      .finally(() => setInProgress(false))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        rowGap: 1,
      }}
    >
      <AppInput
        sx={{ marginBottom: 1 }}
        value={percent}
        handleChange={handlePercentChange}
      />
      <Button
        variant="contained"
        disabled={+percent === +deposit.current_interest_rate || inProgress}
        onClick={handleSaveChanges}
      >
        Save changes
      </Button>

      <Toaster position="top-right" />
    </Box>
  )
}

export default UserDepositPercentChange
