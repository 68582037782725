import React, { useEffect, useState } from 'react'
import { TRANSACTION_STATUSES } from '../../../utils/appConstants'
import {
  appSetPaidTransactionStatus,
  appSetRefusalTransactionStatus,
} from '../../../api/finance/appFinance'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { theme } from '../../../../theme/defaultTheme'
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material'
import AppInput from '../../../ui/AppInput'

const TransactionStatusChanger = ({
  transactionId,
  updateTable,
  showErrorNotify,
}) => {
  const defaultValue = TRANSACTION_STATUSES.REFUSAL
  const [activeStatus, setActiveStatus] = useState(defaultValue)
  const handleStatusChange = e =>
    e.target.value && setActiveStatus(e.target.value)

  const [refusalReason, setRefusalReason] = useState('')
  const handleRefusalReasonChange = e => setRefusalReason(e.target.value)

  const [isSending, setIsSending] = useState(false)

  const handleChangeStatus = async (status, id) => {
    setIsSending(true)

    try {
      status === TRANSACTION_STATUSES.PAID &&
        (await appSetPaidTransactionStatus(id).then(() => updateTable()))
      status === TRANSACTION_STATUSES.REFUSAL &&
        (await appSetRefusalTransactionStatus(id, refusalReason).then(() =>
          updateTable(),
        ))
    } catch (error) {
      console.log('Error: ', error)

      showErrorNotify('Error changing transaction status')
    } finally {
      setIsSending(false)
    }
  }

  useEffect(() => {
    setActiveStatus(defaultValue)
  }, [defaultValue])

  return (
    <Box>
      <Typography variant="subtitle1" color={theme.palette.grey[600]}>
        Set status:
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
          width: 'fit-content',
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={activeStatus}
          exclusive
          onChange={handleStatusChange}
        >
          <ToggleButton size="small" value={TRANSACTION_STATUSES.REFUSAL}>
            {TRANSACTION_STATUSES.REFUSAL}
          </ToggleButton>
          <ToggleButton size="small" value={TRANSACTION_STATUSES.PAID}>
            {TRANSACTION_STATUSES.PAID}
          </ToggleButton>
        </ToggleButtonGroup>
        {activeStatus === TRANSACTION_STATUSES.REFUSAL && (
          <AppInput
            sx={{ minWidth: '300px' }}
            rows={4}
            variant="outlined"
            isMultiline
            label="Reason of refusal"
            value={refusalReason}
            handleChange={handleRefusalReasonChange}
          />
        )}
        <Button
          sx={{ width: 'fit-content' }}
          disabled={
            isSending ||
            (activeStatus === TRANSACTION_STATUSES.REFUSAL &&
              refusalReason.length === 0)
          }
          variant="outlined"
          onClick={() => handleChangeStatus(activeStatus, transactionId)}
        >
          Save status
        </Button>
      </Box>
    </Box>
  )
}

export default TransactionStatusChanger
