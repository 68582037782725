export const handleFileDownload = filePath => {
  if (!filePath) {
    return
  }

  const downloadLink = document.createElement('a')
  downloadLink.href = filePath
  downloadLink.target = '_blank'
  downloadLink.click()
}
