import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "./components";
import { Login, Main } from "./Pages/index";
import { authAtom } from "./states/auth";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mobAppUsersAtom } from "./tsfc-mobile-application/states/appUsers";
import { useEffect } from "react";
import { LS_USERS } from "./tsfc-mobile-application/utils/appConstants";

export const App = () => {
    const auth = useRecoilValue(authAtom);
    const setUsers = useSetRecoilState(mobAppUsersAtom);

    useEffect(() => {
        const storageUsers = localStorage.getItem(LS_USERS);

        storageUsers && setUsers(JSON.parse(storageUsers));
    }, []);

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <RequireAuth needValidate={auth} to={"login"}>
                        <Main />
                    </RequireAuth>
                }
            />
            <Route path="login" element={<Login />} />
            <Route
                path="*"
                element={
                    <RequireAuth needValidate={auth} to={"login"}>
                        <Main />
                    </RequireAuth>
                }
            />
        </Routes>
    );
};
