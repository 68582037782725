import { Modal } from "@mui/material";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

export const ImageViewer = ({ image, isOpen, handleClose }) => {
  return (
    <Modal open={isOpen} sx={{ width: "100%" }} onClose={handleClose}>
      <div>
        <TransformWrapper
          centerOnInit
          minScale={-5}
          initialScale={1}
          limitToBounds={false}
        >
          <TransformComponent wrapperStyle={{ width: "100%" }}>
            <img src={image} alt="test" />
          </TransformComponent>
        </TransformWrapper>
      </div>
    </Modal>
  );
};
