import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { NOTIFICATION_TYPES } from '../../utils/appConstants'

const NotificationTypesToggler = ({
  activeNotificationType,
  handleNotificationTypeClick,
}) => {
  const notificationTypes = [
    NOTIFICATION_TYPES.PROMOTION,
    NOTIFICATION_TYPES.REPORT,
    NOTIFICATION_TYPES.WARNING,
    NOTIFICATION_TYPES.SUCCESSFUL,
  ]

  return (
    <ToggleButtonGroup
      color="primary"
      value={activeNotificationType}
      exclusive
      onChange={handleNotificationTypeClick}
      aria-label="Platform"
    >
      {notificationTypes.map(type => (
        <ToggleButton key={type} size="small" value={type}>
          {type}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default NotificationTypesToggler
