import React, { useState } from 'react'
import useUserHook from '../useUser.hook'
import toast, { Toaster } from 'react-hot-toast'
import { appUserDocUpdate } from '../../../api/user/appUser'
import Box from '@mui/material/Box'
import DownloadButton from '../../../components/DownloadButton'
import { handleFileDownload } from '../../../utils/downloadFile'
import AppUploadFileButtons from '../../../ui/AppUploadFileButtons'

const UserDepositDocumentsChange = ({ contract_user, contractId, userId }) => {
  const [isSending, setIsSending] = useState(false)
  const { fetchUser } = useUserHook()
  const showErrorNotify = text => toast.error(text)

  const handleFileSend = async file => {
    setIsSending(true)

    const formData = new FormData()
    formData.append('file', file)

    await appUserDocUpdate(contractId, userId, formData)
      .then(() => fetchUser())
      .catch(() => showErrorNotify("Error the document didn't change!"))
      .finally(() => setIsSending(false))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        rowGap: 3,
      }}
    >
      <DownloadButton
        isDisabled={!contract_user}
        text="Download user contract"
        handleClick={() => handleFileDownload(contract_user)}
      />
      <AppUploadFileButtons
        uploadText="Upload new contract"
        sendText="Send new contract"
        isSending={isSending}
        handleFileSend={handleFileSend}
      />

      <Toaster position="top-right" />
    </Box>
  )
}

export default UserDepositDocumentsChange
