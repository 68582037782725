import React, { useEffect, useState } from "react";
import { useAxiosWrapper } from "../../../../helpers/axiosWrapper";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  TextField,
} from "@mui/material";
import { Edit, Save } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";

const TextFieldBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
}));

export const CSChangeDate = ({
  doc_id,
  setCryptoSafeProduct,
  setCryptoSafe,
  start_date,
  end_date,
  term,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const axiosWrapper = useAxiosWrapper();

  const changeDate = Yup.object().shape({
    start_date: Yup.date()
      .typeError("Start date should be correct")
      .required("Start date is required"),
    end_date: Yup.date()
      .when("start_date", (start_date, schema) => {
        return schema.test({
          test: (end_date) =>
            start_date.getTime() && end_date.getTime() > start_date.getTime(),
          message: "Max should be > min",
        });
      })
      .typeError("End date should be correct")
      .required("End date is required"),
  });

  const { handleSubmit, control, reset, setValue } = useForm({
    resolver: yupResolver(changeDate),
    mode: "onTouched",
    defaultValues: {
      term: 1,
      sum: 1,
      totalPercentage: 1,
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setValue("start_date", start_date * 1000);
    setValue("end_date", end_date * 1000);
  }, [start_date, end_date]);

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleChangeDate = (data) => {
    setLoading(true);
    console.log(data);
    axiosWrapper
      .post(`/cryptosafe/${doc_id}/changeDate`, {
        start_date: Math.floor(data.start_date.getTime() / 1000),
        end_date: Math.floor(data.end_date.getTime() / 1000),
      })
      .then(() => {
        setCryptoSafe((prev) => {
          return prev.map((item) => {
            if (item.docs.id === doc_id) {
              return {
                ...item,
                start_date: Math.floor(data.start_date.getTime() / 1000),
                end_date: Math.floor(data.end_date.getTime() / 1000),
              };
            } else {
              return item;
            }
          });
        });
        setCryptoSafeProduct((prev) =>
          prev
            ? {
                ...prev,
                start_date: Math.floor(data.start_date.getTime() / 1000),
                end_date: Math.floor(data.end_date.getTime() / 1000),
              }
            : prev
        );
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <IconButton size="small" onClick={handleClickOpen}>
        <Edit fontSize="small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change Dates</DialogTitle>
        <form onSubmit={handleSubmit(handleChangeDate, (e) => console.log(e))}>
          <DialogContent>
            <TextFieldBox>
              <Controller
                name="start_date"
                control={control}
                render={({ field: { onChange, ...restField }, fieldState }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ruLocale}
                  >
                    <DatePicker
                      label="Start date"
                      disablePast
                      onChange={(event) => {
                        onChange(event);
                        setValue("end_date", moment(event).add(term, "months"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!fieldState.error?.message}
                          helperText={fieldState.error?.message}
                          fullWidth
                        />
                      )}
                      {...restField}
                    />
                  </LocalizationProvider>
                )}
              />
            </TextFieldBox>
            <TextFieldBox>
              <Controller
                name="end_date"
                control={control}
                render={({ field: { onChange, ...restField }, fieldState }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ruLocale}
                  >
                    <DatePicker
                      readOnly
                      label="End date"
                      disablePast
                      onChange={(event) => {
                        onChange(event);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!fieldState.error?.message}
                          helperText={fieldState.error?.message}
                          fullWidth
                        />
                      )}
                      {...restField}
                    />
                  </LocalizationProvider>
                )}
              />
            </TextFieldBox>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <LoadingButton
              startIcon={<Save />}
              loadingPosition="start"
              loading={loading}
              onClick={handleSubmit(handleChangeDate, (e) => console.log(e))}
              color="primary"
            >
              Change
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
