import AppInput from '../../ui/AppInput'

const NotificationTextarea = ({ notification, handleNotificationChange }) => {
  return (
    <AppInput
      sx={{ minWidth: '300px' }}
      rows={6}
      variant="outlined"
      isMultiline
      label="Notification"
      value={notification}
      handleChange={handleNotificationChange}
    />
  )
}

export default NotificationTextarea
