import axios from 'axios'
import {LS_MOB_APP_ACCESS_TOKEN_KEY, LS_MOB_APP_REFRESH_TOKEN_KEY,} from '../utils/appConstants'

const baseURL =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? 'https://test.api.da-capital.io/v1'
    : 'https://demo.api.da-capital.io/v1'
const token = process.env.REACT_APP_MOBILE_APP_AUTH_TOKEN
const instance = axios.create({
  baseURL: baseURL,
  headers: {
    token: token,
  },
})

instance.interceptors.request.use(
  async config => {
    const token = localStorage.getItem(LS_MOB_APP_ACCESS_TOKEN_KEY)

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    if (config.method === 'post' && config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }

    return config
  },
  error => Promise.reject(error),
)

instance.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalRequest = error.config

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      const refreshToken = localStorage.getItem(LS_MOB_APP_REFRESH_TOKEN_KEY)

      return axios
        .get(`${baseURL}/auth/admin/refresh`, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
            token: token,
          },
        })
        .then(res => {
          if (res.status === 200) {
            const newAccessToken = res.data.accessToken
            localStorage.setItem(LS_MOB_APP_ACCESS_TOKEN_KEY, newAccessToken)
            localStorage.setItem(
              LS_MOB_APP_REFRESH_TOKEN_KEY,
              res.data.refreshToken,
            )
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

            return axios.request(originalRequest);
          }
        })
        .catch(err => {
          if (err.response.status === 400) {
            localStorage.removeItem(LS_MOB_APP_ACCESS_TOKEN_KEY)
            localStorage.removeItem(LS_MOB_APP_REFRESH_TOKEN_KEY)

            window.location.reload()
          }
        })
    }

    return Promise.reject(error)
  },
)

export default instance
