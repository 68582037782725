import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import AppInput from '../../ui/AppInput'
import {
  Autocomplete,
  Divider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import { APPS, CURRENCY_LIST } from '../../utils/appConstants'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  appCreateKeyPay,
  appDeleteKeyPay,
  appUpdateKeyPay,
} from '../../api/keys-pay/appKeysPay'
import AppConfirmModal from '../../ui/AppConfirmModal'
import toast, { Toaster } from 'react-hot-toast'

const TYPES = [
  APPS.FORTIS.toUpperCase(),
  APPS.CRYPTO.toUpperCase(),
  APPS.PRIME.toUpperCase(),
]

const KeyCard = ({ card, updateKeys, isCreateCard }) => {
  const [isSending, setIsSending] = useState(false)
  const [isEditable, setIsEditable] = useState(false)

  const [type, setType] = useState(card.type)
  const handleTypeChange = (e, type) => setType(type)

  const [currency, setCurrency] = useState(card.currency)
  const handleCurrencyChange = currency => setCurrency(currency)

  const [network, setNetwork] = useState(card.network)
  const handleNetworkChange = e => setNetwork(e.target.value)

  const [key, setKey] = useState(card.key)
  const handleKeyChange = e => setKey(e.target.value)

  const resetFields = () => {
    setType(card.type)
    setCurrency(card.currency)
    setNetwork(card.network)
    setKey(card.key)
  }
  const handleChangesDecline = () => {
    resetFields()
    setIsEditable(false)
  }

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const handleOpenConfirmModal = () => setIsConfirmModalOpen(true)
  const handleCloseConfirmModal = () => setIsConfirmModalOpen(false)

  const isCreateButtonDisabled =
    type.length === 0 ||
    currency.length === 0 ||
    network.length === 0 ||
    key.length === 0 ||
    isSending

  const showSuccessNotify = text => toast.success(text)
  const showErrorNotify = text => toast.error(text)

  const handleSaveChanges = async () => {
    setIsSending(true)

    const newKey = {
      type,
      currency,
      network,
      key,
    }

    try {
      await appUpdateKeyPay(card.id, newKey).then(() => {
        updateKeys()

        showSuccessNotify('The key has been successfully changed!')
        setIsEditable(false)
      })
    } catch (error) {
      console.log('Error: ', error)
      showErrorNotify('Error! The key was not changed!')
    } finally {
      setIsSending(false)
    }
  }

  const handleKeyDelete = async () => {
    setIsSending(true)

    try {
      await appDeleteKeyPay(card.id).then(() => {
        updateKeys()
        showSuccessNotify('The key has been successfully deleted!')

        setIsEditable(false)
      })
    } catch (error) {
      console.log('Error: ', error)
      showErrorNotify('Error! The key was not deleted!')
    } finally {
      setIsSending(false)
    }
  }

  const handleKeyCreate = async () => {
    setIsSending(true)

    const newKey = {
      type,
      currency,
      network,
      key,
    }

    try {
      await appCreateKeyPay(newKey).then(() => {
        updateKeys()
      })
    } catch (error) {
      console.log('Error: ', error)
      showErrorNotify('Error! The key was not created!')
    } finally {
      setIsSending(false)
      resetFields()
    }
  }

  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardContent
          sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                columnGap: 1,
              }}
            >
              <Typography
                sx={{ textTransform: 'uppercase' }}
                variant="subtitle1"
              >
                {isCreateCard ? 'Create new key' : `Key № ${card.id}`}
              </Typography>
              {!isCreateCard && (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}
                >
                  <Tooltip title="Edit key" placement="top" arrow>
                    <IconButton onClick={() => setIsEditable(true)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove key" placement="top" arrow>
                    <IconButton onClick={handleOpenConfirmModal}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
            <Divider />
          </Box>

          <ToggleButtonGroup
            color="primary"
            value={type}
            exclusive
            disabled={!isEditable && !isCreateCard}
            onChange={handleTypeChange}
            aria-label="Platform"
          >
            {TYPES.map(type => (
              <ToggleButton size="small" value={type} key={type.id}>
                {type}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <Autocomplete
            sx={{ minWidth: '300px' }}
            freeSolo
            disableClearable
            options={CURRENCY_LIST}
            defaultValue={currency}
            disabled={!isEditable && !isCreateCard}
            renderInput={params => (
              <TextField
                {...params}
                label="Currency"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
            onChange={(e, user) => handleCurrencyChange(user)}
          />

          <AppInput
            variant="outlined"
            label="Network"
            value={network}
            disabled={!isEditable && !isCreateCard}
            handleChange={handleNetworkChange}
          />

          <AppInput
            variant="outlined"
            label="Key"
            value={key}
            disabled={!isEditable && !isCreateCard}
            handleChange={handleKeyChange}
          />
        </CardContent>
        <CardActions>
          {isEditable && !isCreateCard && (
            <Button
              color="error"
              size="small"
              variant="outlined"
              onClick={handleChangesDecline}
            >
              Decline changes
            </Button>
          )}
          {isEditable && !isCreateCard && (
            <Button
              color="success"
              size="small"
              variant="outlined"
              onClick={handleSaveChanges}
            >
              Save changes
            </Button>
          )}
          {isCreateCard && (
            <Button
              color="success"
              size="small"
              variant="outlined"
              disabled={isCreateButtonDisabled}
              onClick={handleKeyCreate}
            >
              Create key
            </Button>
          )}
        </CardActions>
      </Card>
      <AppConfirmModal
        isOpen={isConfirmModalOpen}
        handleCloseModal={handleCloseConfirmModal}
        handleApproveAction={handleKeyDelete}
        isConfirmActionDisabled={isSending}
      >
        <Typography variant="subtitle1">
          Confirm deletion of the key {card.key}?
        </Typography>
      </AppConfirmModal>

      <Toaster position="top-right" />
    </>
  )
}

export default KeyCard
