import { UserDialogBox } from "../UserDialog/UserDialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { memo, useLayoutEffect, useState } from "react";
import { useAxiosWrapper } from "../../helpers/axiosWrapper";
import Skeleton from "@mui/material/Skeleton";
import {
  getAlltimeProfit,
  getDayProfit,
  getMonthProfit,
  NaNStringSafety,
} from "../../utils/MaxiProfitCalculator";

const PaperContainer = styled(Paper)(() => ({
  minHeight: "100px",
  height: "fit-content",
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const MaxiProfitDialog = memo(
  ({ open, handleClose, product_id, user_code }) => {
    const [MaxiProfit, setMaxiProfit] = useState();
    const [cache, setCache] = useState();
    const axiosWrapper = useAxiosWrapper();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState();

    useLayoutEffect(() => {
      if (open) {
        setLoading(true);
        axiosWrapper
          .get("maxiprofit/product/" + product_id)
          .then(({ data }) => {
            setMaxiProfit(data);
            axiosWrapper
              .get(`maxiprofit/${data?.docs.id}/cache`)
              .then(({ data }) => {
                setCache(data);
              })
              .then(() => {
                axiosWrapper.get(`users/${user_code}`).then(({ data }) => {
                  setUser(data);
                  setLoading(false);
                });
              });
          })
          .catch(() => setLoading(false));
      }
    }, [open, product_id, user_code]);

    return (
      <UserDialogBox
        maxWidth={"sm"}
        fullWidth
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          MaxiProfit Product Info
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#FFF",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <Typography variant={"h4"}>
              {!loading ? (
                user?.name + " " + user?.surname
              ) : (
                <Skeleton sx={{ minWidth: { xs: "150px", xl: "300px" } }} />
              )}
            </Typography>
          </Box>
          <PaperContainer sx={{ margin: "10px 0" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Start Balance
              </Typography>
            </Box>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                textAlign: "center",
                fontSize: "1.8rem",
                margin: "auto 0",
              }}
              variant={"h4"}
            >
              {loading ? (
                <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
              ) : MaxiProfit ? (
                MaxiProfit?.start_balance > 0 ? (
                  "$" + MaxiProfit?.start_balance.toFixed(2)
                ) : (
                  "Empty"
                )
              ) : (
                "$0"
              )}
            </Typography>
          </PaperContainer>
          <Box
            sx={{
              height: "fit-content",
              display: "grid",
              gridTemplateColumns: {
                sm: "repeat(auto-fill, minmax(150px, 1fr))",
                xl: "repeat(auto-fill, minmax(220px, 1fr))",
              },
              alignItems: "center",
              gridGap: "10px",
            }}
          >
            <PaperContainer>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Balance
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  textAlign: "center",
                  fontSize: "1.8rem",
                  margin: "auto 0",
                }}
                variant={"h4"}
              >
                {loading ? (
                  <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                ) : MaxiProfit ? (
                  `$${MaxiProfit?.balance.balance.toFixed(2)}`
                ) : (
                  "$0"
                )}
              </Typography>
            </PaperContainer>
            <PaperContainer>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Total Income
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  fontSize: "1.8rem",
                  margin: "auto 0",
                }}
                variant={"h4"}
              >
                {loading ? (
                  <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                ) : MaxiProfit && cache && cache?.length > 0 ? (
                  `$${NaNStringSafety(getAlltimeProfit(MaxiProfit).toFixed(2))}`
                ) : (
                  "$0"
                )}
              </Typography>
            </PaperContainer>
            <PaperContainer>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Income per Month
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  fontSize: "1.8rem",
                  margin: "auto 0",
                }}
                variant={"h4"}
              >
                {loading ? (
                  <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                ) : MaxiProfit && cache && cache?.length > 0 ? (
                  `$${NaNStringSafety(
                    getMonthProfit(MaxiProfit, cache).toFixed(2)
                  )}`
                ) : (
                  "$0"
                )}
              </Typography>
            </PaperContainer>
            <PaperContainer>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Income per Day
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  fontSize: "1.8rem",
                  margin: "auto 0",
                }}
                variant={"h4"}
              >
                {loading ? (
                  <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                ) : MaxiProfit && cache && cache?.length > 0 ? (
                  `$${NaNStringSafety(
                    getDayProfit(MaxiProfit, cache).toFixed(2)
                  )}`
                ) : (
                  "$0"
                )}
              </Typography>
            </PaperContainer>
            <PaperContainer>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Month profit percent
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  margin: "auto 0",
                  fontSize: "1.8rem",
                }}
                variant={"h4"}
              >
                {loading ? (
                  <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                ) : MaxiProfit && cache && cache?.length > 0 ? (
                  `${cache[0]?.balance.monthPercentProfit}%`
                ) : (
                  "0%"
                )}
              </Typography>
            </PaperContainer>
            <PaperContainer>
              <Typography variant={"subtitle2"} sx={{ m: "5px 0 0 5px" }}>
                Day profit percent
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  margin: "auto 0",
                  fontSize: "1.8rem",
                }}
                variant={"h4"}
              >
                {loading ? (
                  <Skeleton sx={{ width: "100%", margin: "0 20px" }} />
                ) : MaxiProfit && cache && cache[0] ? (
                  `${cache[0]?.balance.dayPercentProfit}%`
                ) : (
                  "0%"
                )}
              </Typography>
            </PaperContainer>
          </Box>
        </DialogContent>
      </UserDialogBox>
    );
  }
);
