import React, { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { Button, Divider } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import toast, { Toaster } from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import AppInput from "../../ui/AppInput";
import { appSetUserCoins } from "../../api/finance/appFinance";
import useUserHook from "./useUser.hook";

const ChangeCoinsModal = ({ coins, isOpen, handleModalClose }) => {
  const [currentCoins, setCurrentCoins] = useState(coins);
  const [isFetching, setIsFetching] = useState(false);
  const { fetchUser, currentUser, isUserFetching } = useUserHook();
  const showErrorNotify = (text) => toast.error(text);

  const handleCoinsChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");

    setCurrentCoins(numericValue);
  };
  const handleCancel = () => {
    // setCurrentCoins(coins);
    handleModalClose();
  };

  const handleSubmit = async () => {
    setIsFetching(true);

    await appSetUserCoins(currentUser.id, currentCoins)
      .then(() => {
        fetchUser();
        handleModalClose();
      })
      .catch(() => showErrorNotify("Error coins changing"))
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    setCurrentCoins(coins);
  }, [coins]);

  return (
    <Dialog sx={{ minWidth: "300px" }} open={isOpen} onClose={handleCancel}>
      <DialogTitle>
        <Typography variant="subtitle1" fontWeight="bold">
          Change coins amount
        </Typography>
      </DialogTitle>
      <Divider />

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            rowGap: 2,
          }}
        >
          <AppInput
            label="Coins"
            value={currentCoins}
            handleChange={handleCoinsChange}
          />
        </Box>
      </DialogContent>

      <Divider />
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="success"
          disabled={coins === currentCoins || isFetching}
          onClick={handleSubmit}
        >
          Change
        </Button>
      </DialogActions>

      <Toaster position="top-right" />
    </Dialog>
  );
};

export default ChangeCoinsModal;
