import { TextField } from '@mui/material'

const AppInput = ({
  sx,
  focused,
  isMultiline,
  rows,
  variant,
  label,
  value,
  disabled,
  handleChange,
}) => {
  return (
    <TextField
      sx={sx}
      rows={rows}
      focused={focused}
      multiline={isMultiline}
      label={label ?? ''}
      variant={variant ?? 'standard'}
      value={value}
      disabled={disabled}
      onChange={handleChange}
    />
  )
}

export default AppInput
