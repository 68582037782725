import React, { useEffect } from "react";
import { Box, Drawer } from "@mui/material";
import Navbar from "../../components/Navbar";
import { Codes } from "./blocks/Codes";
import Sidebar from "../../components/Sidebar";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useAxiosWrapper } from "../../helpers/axiosWrapper";
import { UserDialog } from "../../components/UserDialog/UserDialog";
import {
  unverifiedAtom,
  verifiedAtom,
  newAtom,
  codesAtom,
  userDialogAtom,
  currentTabAtom,
  imageViewerAtom,
} from "../../states";
import { ImageViewer } from "../../components/ImageViewer";
import { Clients } from "./blocks/Clients/Clients";
import { FinanceOverview } from "./blocks/FinanceOverview/FinanceOverview";
import { Settings } from "./blocks/Settings/Settings";
import { Currencies } from "./blocks/Currencies/Currencies";
import { Terminal } from "./blocks/Terminal/Terminal";
import MobileApp from "../../tsfc-mobile-application/mobileApp";
import UsersView from "../../tsfc-mobile-application/views/users-view/UsersView";
import UserView from "../../tsfc-mobile-application/views/user-view/UserView";
import FinanceView from "../../tsfc-mobile-application/views/finance-view/FinanceView";
import NotificationsView from "../../tsfc-mobile-application/views/notifications-view/NotificationsView";
import KeysView from "../../tsfc-mobile-application/views/keys-view/KeysView";
import SettingsView from "../../tsfc-mobile-application/views/settings-view/SettingsView";

export function Main() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const currentTab = useRecoilValue(currentTabAtom);
  const [unverified, setUnverfied] = useRecoilState(unverifiedAtom);
  const setVerified = useSetRecoilState(verifiedAtom);
  const [_new, setNew] = useRecoilState(newAtom);
  const setCodes = useSetRecoilState(codesAtom);
  const axiosInstance = useAxiosWrapper();
  const [userDialog, setUserDialog] = useRecoilState(userDialogAtom);
  const [imageViewer, setImageViewer] = useRecoilState(imageViewerAtom);

  useEffect(() => {
    Promise.all([
      axiosInstance
        .get("users", {}, { params: { verified: false } })
        .then((r) => {
          r.data.map((item) => {
            Object.assign(item);
            return item;
          });
          setUnverfied(r.data);
        }),
      axiosInstance
        .get("users", {}, { params: { verified: true } })
        .then((r) => {
          r.data.map((item) => {
            Object.assign(item);
            return item;
          });
          setVerified(r.data);
        }),
      axiosInstance.get("codes", {}, {}).then((r) => {
        r.data.map((item, i) => {
          Object.assign(item, { id: i + 1 });
          return item;
        });
        setCodes(r.data);
      }),
      axiosInstance.get("users", {}, {}).then((r) => {
        r.data.map((item) => {
          Object.assign(item);
          return item;
        });
        setNew(r.data);
      }),
    ]);
  }, []);

  useEffect(() => {
    localStorage.setItem("lastSeen", currentTab.toString());
  }, [currentTab]);

  const handleUserDialogClose = () => {
    setUserDialog({ isOpen: !userDialog.isOpen, code: undefined });
  };

  const handleImageViewerClose = () => {
    setImageViewer({ isOpen: !imageViewer.isOpen, image: "" });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box>
      <Navbar
        handleDrawerToggle={handleDrawerToggle}
        unverifiedBadge={unverified}
      />
      <Box component="nav" sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
          }}
        >
          <Sidebar />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
          }}
          open
        >
          <Sidebar />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          marginLeft: { md: "240px" },
          display: "flex",
          marginTop: "64px",
        }}
      >
        {currentTab === 0 && <UsersView />}
        {currentTab === 1 && <UserView />}
        {currentTab === 2 && <FinanceView />}
        {currentTab === 3 && <NotificationsView />}
        {currentTab === 4 && <KeysView />}
        {currentTab === 5 && <SettingsView />}
        {currentTab === 6 && <MobileApp />}
      </Box>
      <UserDialog
        open={userDialog.isOpen}
        code={userDialog.code}
        handleClose={handleUserDialogClose}
      />
      {imageViewer.isOpen && (
        <ImageViewer
          isOpen={imageViewer.isOpen}
          image={imageViewer.image}
          handleClose={handleImageViewerClose}
        />
      )}
    </Box>
  );
}
