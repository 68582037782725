import Box from '@mui/material/Box'
import AppUploadFileButtons from '../../../ui/AppUploadFileButtons'
import { useState } from 'react'
import { appUserContractUploadReOpenDocument } from '../../../api/user/appUser'
import useUserHook from '../useUser.hook'

const UserContractReCreation = ({ contract, userId }) => {
  const [isSending, setIsSending] = useState(false)

  const { fetchUser } = useUserHook()

  const handleFileSend = async file => {
    setIsSending(true)

    try {
      const formData = new FormData()
      formData.append('file', file)

      await appUserContractUploadReOpenDocument(
        formData,
        contract.id,
        userId,
      ).then(response => {
        fetchUser()
        setIsSending(false)
      })
    } catch (error) {
      console.log('Error: ', error)
      console.log('need show error')
      setIsSending(false)
    }
  }

  return (
    <Box>
      <AppUploadFileButtons
        isSending={isSending}
        handleFileSend={handleFileSend}
      />
    </Box>
  )
}

export default UserContractReCreation
