import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { theme } from '../../theme/defaultTheme'

const AppUpdatePageButton = ({ tooltipText, updateFunction }) => {
  return (
    <Tooltip title={tooltipText ?? 'Update page.'} placement="top" arrow>
      <IconButton
        color="primary"
        aria-label="round-button"
        sx={{
          position: 'fixed',
          bottom: '2%',
          right: '2%',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          padding: '10px',
          zIndex: 1,
          color: '#fff',
          opacity: 0.5,
          backgroundColor: theme.palette.primary.main,
          transition: 'opacity 0.3s ease-in-out',
          '&:hover': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
          },
        }}
        onClick={updateFunction}
      >
        <RotateLeftIcon />
      </IconButton>
    </Tooltip>
  )
}

export default AppUpdatePageButton
