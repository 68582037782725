import React, { useState } from 'react'
import Box from '@mui/material/Box'
import {
  Autocomplete,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import UserContractsChanger from './UserContractsChanger'
import { APPS, DEPOSIT_STATUSES_FILTERS } from '../../utils/appConstants'
import { useRecoilState } from 'recoil'
import { mobAppUserAtom } from '../../states/appUser'

const UserContracts = () => {
  const [user] = useRecoilState(mobAppUserAtom)
  const { info: currentUser } = user

  const contractTypeTabs = ['All', APPS.FORTIS, APPS.CRYPTO, APPS.PRIME]
  const [activeContractTypeTab, setActiveContractTypeTab] = useState('All')
  const handleActiveContractTypeTabClick = (e, tab) =>
    tab !== null && setActiveContractTypeTab(tab)

  const [activeContractStatusTab, setActiveContractStatusTab] = useState('All')

  const handleResetContractFiltersClick = () => {
    setActiveContractTypeTab('All')
    setActiveContractStatusTab('All')
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          columnGap: 2,
          rowGap: 2,
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={activeContractTypeTab}
          exclusive
          onChange={handleActiveContractTypeTabClick}
          aria-label="Platform"
        >
          {contractTypeTabs.map((tab, index) => (
            <ToggleButton size="small" value={tab} key={`${tab}-${index}`}>
              {tab}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Autocomplete
          sx={{ width: '300px' }}
          size="small"
          freeSolo
          disableClearable
          options={DEPOSIT_STATUSES_FILTERS}
          defaultValue={activeContractStatusTab}
          value={activeContractStatusTab}
          renderInput={params => (
            <TextField
              {...params}
              label="Status"
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
          onChange={(e, tab) => setActiveContractStatusTab(tab)}
        />
      </Box>
      <UserContractsChanger
        userId={currentUser.id}
        activeType={activeContractTypeTab}
        setActiveType={setActiveContractTypeTab}
        activeStatus={activeContractStatusTab}
        setActiveStatus={setActiveContractStatusTab}
        handleResetFilters={handleResetContractFiltersClick}
      />
    </Box>
  )
}

export default UserContracts
