import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'

const AppInfoTooltip = ({ text, iconSize, type }) => {
  return (
    <Tooltip title={text} placement="top" arrow>
      <InfoOutlinedIcon
        sx={{ fontSize: iconSize ?? 18 }}
        color={type ?? 'info'}
      />
    </Tooltip>
  )
}

export default AppInfoTooltip
