import axiosInstance from '../appAxiosInstance'
import { appEndpoints } from '../appEndpoints'

export const appGetAllNotifications = () =>
  axiosInstance.get(appEndpoints.NOTIFICATIONS.GET_ALL)

export const appSendPersonalNotification = (id, body) =>
  axiosInstance.post(`${appEndpoints.NOTIFICATIONS.PERSONAL}/${id}`, body)

export const appSendNotificationForAllUsers = body =>
  axiosInstance.post(appEndpoints.NOTIFICATIONS.FOR_ALL_USERS, body)
