import { Replay } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'

const AppReloadButton = ({ isLoading, handleClick }) => {
  return (
    <LoadingButton
      loading={isLoading}
      startIcon={<Replay />}
      loadingPosition="start"
      size="small"
      onClick={handleClick}
    >
      Reload
    </LoadingButton>
  )
}

export default AppReloadButton
