import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { Edit, Save } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useAxiosWrapper } from "../../../../helpers/axiosWrapper";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export const CSRenameDialog = ({
  doc_id,
  setCryptoSafeProduct,
  setCryptoSafe,
  currentName,
  contractType = "cryptosafe",
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const axiosWrapper = useAxiosWrapper();

  const createCSForm = Yup.object().shape({
    contractName: Yup.string()
      .min(1, "Name must be at least 1 character")
      .max(24, "Name must be less than 24 characters")
      .required("Name is required")
      .required("Name is required"),
  });

  const { handleSubmit, control, reset, setValue } = useForm({
    resolver: yupResolver(createCSForm),
  });

  const handleClickOpen = () => {
    setOpen(true);
    setValue("contractName", currentName);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleRename = (data) => {
    setLoading(true);
    axiosWrapper
      .put(
        `/${
          contractType === "cryptosafe" ? "cryptosafe" : "maxiprofit/nm"
        }/${doc_id}/${data.contractName}`
      )
      .then(() => {
        setCryptoSafe((prev) => {
          return prev.map((item) => {
            if (item.docs.id === doc_id) {
              return { ...item, contract_name: data.contractName };
            } else {
              return item;
            }
          });
        });
        setCryptoSafeProduct((prev) =>
          prev ? { ...prev, contract_name: data.contractName } : prev
        );
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log(currentName);
  }, [currentName]);

  return (
    <>
      <IconButton size="small" onClick={handleClickOpen}>
        <Edit fontSize="small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Rename Cryptosafe</DialogTitle>
        <form onSubmit={handleSubmit(handleRename, (e) => console.log(e))}>
          <DialogContent>
            <Controller
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  name={"contractName"}
                  inputRef={ref}
                  fullWidth
                  autoFocus
                  margin="dense"
                  label="Contract name"
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  variant="standard"
                />
              )}
              name={"contractName"}
              control={control}
              defaultValue={currentName}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <LoadingButton
              startIcon={<Save />}
              loadingPosition="start"
              loading={loading}
              onClick={handleSubmit(handleRename, (e) => console.log(e))}
              color="primary"
            >
              Rename
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
