import React, { useState } from 'react'
import AppAccordion from '../../../ui/AppAccordion'
import Typography from '@mui/material/Typography'
import { FormControl, InputLabel, MenuItem } from '@mui/material'
import Select from '@mui/material/Select'
import {
  APPS,
  LS_MOB_APP_CURRENT_USER_ID_KEY,
} from '../../../utils/appConstants'
import UserDepositDocumentsChange from './UserDepositDocumentsChange'
import UserDepositAmountChange from './UserDepositAmountChange'
import UserDepositTitleChange from './UserDepositTitleChange'
import UserDepositPercentChange from './UserDepositPercentChange'
import UserDepositCommissionVisibilityChange from './UserDepositCommissionVisibilityChange'

const UserDepositChanger = ({ deposit, title, contract_user, contractId }) => {
  const isPrime = deposit.type.toLowerCase() === APPS.PRIME.toLowerCase()

  const options = [
    { key: 'title', value: 'title', visibility: true },
    { key: 'percent', value: 'percent', visibility: true },
    { key: 'contract', value: 'contract', visibility: true },
    { key: 'startAmount', value: 'start amount', visibility: true },
    {
      key: 'comVisibility',
      value: 'toggle commission visibility',
      visibility: isPrime,
    },
  ]
  const [activeOption, setActiveOption] = useState('title')

  const handleActiveTabClick = e => setActiveOption(e.target.value)

  return (
    <AppAccordion
      titleJSX={
        <Typography variant="subtitle2">
          Change contract(status will not be changed)
        </Typography>
      }
    >
      <FormControl sx={{ marginBottom: 2 }} fullWidth>
        <InputLabel>Change</InputLabel>
        <Select
          value={activeOption}
          label="Change"
          onChange={handleActiveTabClick}
        >
          {options.map(
            option =>
              option.visibility && (
                <MenuItem size="small" value={option.value} key={option.key}>
                  {option.value}
                </MenuItem>
              ),
          )}
        </Select>
      </FormControl>

      {activeOption === 'title' && <UserDepositTitleChange contractId={contractId} title={title} />}
      {activeOption === 'percent' && (
        <UserDepositPercentChange deposit={deposit} />
      )}
      {activeOption === 'contract' && (
        <UserDepositDocumentsChange
          contract_user={contract_user}
          userId={localStorage.getItem(LS_MOB_APP_CURRENT_USER_ID_KEY)}
          contractId={contractId}
        />
      )}
      {activeOption === 'start amount' && (
        <UserDepositAmountChange deposit={deposit} />
      )}
      {activeOption === 'toggle commission visibility' && isPrime && (
        <UserDepositCommissionVisibilityChange deposit={deposit} />
      )}
    </AppAccordion>
  )
}

export default UserDepositChanger
