import { NOTIFICATION_RECEIVERS } from '../utils/appConstants'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import NotificationTypesToggler from '../views/notifications-view/NotificationTypesToggler'
import NotificationTextarea from '../views/notifications-view/NotificationTextarea'
import AppConfirmModal from '../ui/AppConfirmModal'
import React from 'react'

const NotificationModal = ({
  isOpen,
  isConfirmActionDisabled,
  activeNotificationReceiver,
  selectedUser,
  notification,
  activeNotificationType,
  handleNotificationChange,
  handleNotificationTypeTabClick,
  handleCloseModal,
  handleSendPersonalNotification,
  handleSendNotificationForAllUsers,
}) => {
  return (
    <AppConfirmModal
      isOpen={isOpen}
      isConfirmActionDisabled={isConfirmActionDisabled}
      handleCloseModal={handleCloseModal}
      handleApproveAction={
        activeNotificationReceiver === NOTIFICATION_RECEIVERS.PERSONAL
          ? handleSendPersonalNotification
          : handleSendNotificationForAllUsers
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
        <Typography>
          Send notification for{' '}
          {activeNotificationReceiver === NOTIFICATION_RECEIVERS.PERSONAL &&
            !!selectedUser &&
            `${selectedUser.first_name} ${selectedUser.last_name}`}
          {activeNotificationReceiver ===
            NOTIFICATION_RECEIVERS.FOR_ALL_USERS && 'all users'}
          .
        </Typography>
        <NotificationTypesToggler
          activeNotificationType={activeNotificationType}
          handleNotificationTypeClick={handleNotificationTypeTabClick}
        />
        <NotificationTextarea
          notification={notification}
          handleNotificationChange={handleNotificationChange}
        />
      </Box>
    </AppConfirmModal>
  )
}

export default NotificationModal
