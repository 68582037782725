import { useState } from 'react'
import {
  NOTIFICATION_RECEIVERS,
  NOTIFICATION_TYPES,
} from '../utils/appConstants'
import {
  appSendNotificationForAllUsers,
  appSendPersonalNotification,
} from '../api/notifications/appNotifications'

export const useNotification = ({
  userId,
  showSuccessNotify,
  showErrorNotify,
}) => {
  const [notification, setNotification] = useState('')
  const handleNotificationChange = e => setNotification(e.target.value)

  const [activeNotificationReceiver, setActiveNotificationReceiver] = useState(
    NOTIFICATION_RECEIVERS.PERSONAL,
  )
  const handleNotificationReceiverTabClick = (e, tab) =>
    tab !== null && setActiveNotificationReceiver(tab)

  const [activeNotificationType, setActiveNotificationType] = useState(
    NOTIFICATION_TYPES.WARNING,
  )
  const handleNotificationTypeTabClick = (e, tab) =>
    tab !== null && setActiveNotificationType(tab)

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const openConfirmModal = () => setIsConfirmModalOpen(true)
  const closeConfirmModal = () => setIsConfirmModalOpen(false)

  const [isNotificationSending, setIsNotificationSending] = useState(false)

  const handleSendPersonalNotification = async () => {
    setIsNotificationSending(true)

    try {
      await appSendPersonalNotification(userId, {
        description: notification,
        type: activeNotificationType,
      }).then(() => {
        showSuccessNotify('Notification send!')
        setIsConfirmModalOpen(false)
        setIsNotificationSending(false)
      })
    } catch (error) {
      showErrorNotify('Error sending notification!')
      setIsNotificationSending(false)
    }
  }

  const handleSendNotificationForAllUsers = async () => {
    setIsNotificationSending(true)

    try {
      await appSendNotificationForAllUsers({
        description: notification,
        type: activeNotificationType,
      }).then(() => {
        showSuccessNotify('Notification send!')
        setIsConfirmModalOpen(false)
        setIsNotificationSending(false)
      })
    } catch (error) {
      showErrorNotify('Error sending notification!')
      setIsNotificationSending(false)
    }
  }

  return {
    notification,
    activeNotificationReceiver,
    activeNotificationType,
    isConfirmModalOpen,
    openConfirmModal,
    closeConfirmModal,
    isNotificationSending,
    handleNotificationChange,
    handleNotificationReceiverTabClick,
    handleNotificationTypeTabClick,
    handleSendPersonalNotification,
    handleSendNotificationForAllUsers,
  }
}
