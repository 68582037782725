import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import { CryptoSafeCard } from "./components/CryptoSafeDialog/CryptoSafeCard";
import { MaxiProfitCard } from "./components/MaxiProfitDialog/MaxiProfitCard";
import { CryptoSafeCreateDialog } from "./components/CryptoSafeDialog/CryptoSafeCreateDialog";
import { useAxiosWrapper } from "../../helpers/axiosWrapper";
import LoadingButton from "@mui/lab/LoadingButton";

export const UserDialogBox = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogTitle-root": {
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
  },
}));

export const UserDialog = ({ open, code, handleClose }) => {
  const [user, setUser] = React.useState();
  const [cryptoSafe, setCryptoSafe] = React.useState();
  const [maxiProfit, setMaxiProfit] = React.useState();
  const [passport, setPassport] = React.useState();
  const axiosWrapper = useAxiosWrapper();
  const [recovery, setRecovery] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);

  const handleUploadPassport = (e) => {
    setUploading(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    axiosWrapper
      .put("users/adm/passport/" + code, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        axiosWrapper.get("users/passport/" + code).then(({ data }) => {
          setPassport(data);
          setUploading(false);
        });
      })
      .catch(() => {
        setUploading(false);
      });
  };

  const handlePassportRemove = () => {
    setUploading(true);
    axiosWrapper
      .delete("users/passport/" + code)
      .then(() => {
        setPassport(null);
        setUploading(false);
      })
      .catch(() => {
        setUploading(false);
      });
  };

  useEffect(() => {
    Promise.all([
      code &&
        axiosWrapper.get(`/users/${code}`).then(({ data }) => setUser(data)),
      code &&
        axiosWrapper.get("users/passport/" + code).then(({ data }) => {
          setPassport(data);
        }),
      code &&
        axiosWrapper
          .get("cryptosafe/" + code)
          .then(({ data }) => {
            if (data.length > 0) {
              setCryptoSafe(data);
              console.log(data);
            }
          })
          .catch(() => console.log("cant get cryptosafes")),
      code &&
        axiosWrapper
          .get(`maxiprofit/${code}`)
          .then(({ data }) => {
            if (data.length > 0) {
              setMaxiProfit(data);
              console.log(data);
            }
          })
          .catch(() => console.log("cant get maxiprofits")),
    ]);

    return () => {
      setPassport(undefined);
      setUser(undefined);
      setMaxiProfit(undefined);
      setCryptoSafe(undefined);
    };
  }, [code]);

  const handleRecovery = () => {
    setRecovery(true);
    axiosWrapper
      .post("auth/recovery", {
        email: user.email,
        code: code,
      })
      .then(({ data }) => {
        if (data.status) {
          setRecovery(false);
        }
      })
      .catch(() => setRecovery(false));
  };

  return (
    <UserDialogBox fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        User profile
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#FFF",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ display: "flex", marginBottom: 20 }}>
          {user && user.image ? (
            <Avatar
              sx={{
                width: { xs: 60, md: 80, xl: 120 },
                height: { xs: 60, md: 80, xl: 120 },
              }}
              src={user.image}
            />
          ) : (
            <Skeleton
              variant="circular"
              sx={{
                minWidth: { xs: 60, md: 80, xl: 120 },
                minHeight: { xs: 60, md: 80, xl: 120 },
              }}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              marginLeft: "10px",
            }}
          >
            <>
              <Typography variant={"h4"}>
                {user ? (
                  user.name + " " + user.surname
                ) : (
                  <Skeleton sx={{ minWidth: { xs: "150px", xl: "300px" } }} />
                )}
              </Typography>
              {user && (
                <Chip
                  sx={{ maxWidth: "100px", width: "fit-content" }}
                  color={user.verified ? "success" : "error"}
                  size="small"
                  label={user.verified ? "Verified" : "Unverified"}
                />
              )}
            </>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            justifyContent: "space-between",
          }}
        >
          {user ? (
            <TextField
              InputProps={{
                readOnly: true,
              }}
              label="Code"
              onClick={() => navigator.clipboard.writeText(code)}
              variant="outlined"
              defaultValue={code}
              sx={{ marginRight: "20px" }}
            />
          ) : (
            <Skeleton
              variant="text"
              sx={{
                width: "100%",
                marginRight: "20px",
                minWidth: { sm: "150px", xl: "245px" },
              }}
              height={56}
            />
          )}
          {user ? (
            <TextField
              InputProps={{
                readOnly: true,
              }}
              label="User ID"
              variant="outlined"
              defaultValue={user.id}
            />
          ) : (
            <Skeleton
              variant="text"
              sx={{ width: "100%", minWidth: { sm: "150px", xl: "245px" } }}
              height={56}
            />
          )}
        </div>

        {user ? (
          <TextField
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            label="Email"
            variant="outlined"
            defaultValue={user.email}
            sx={{ mb: "20px" }}
          />
        ) : (
          <Skeleton
            variant="text"
            sx={{
              width: "100%",
              marginRight: "20px",
              minWidth: { xs: "245px" },
            }}
            height={56}
          />
        )}
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            justifyContent: "space-between",
          }}
        >
          {!passport ? (
            <LoadingButton
              loadingIndicator="Uploading…"
              loading={uploading}
              component="label"
              disabled={!code}
            >
              Upload Passport
              <input
                onChange={handleUploadPassport}
                hidden
                accept="image/png, image/jpeg"
                type="file"
              />
            </LoadingButton>
          ) : (
            <>
              <Button
                disabled={!passport && !code}
                onClick={() => {
                  window.open(passport, "_blank").focus();
                }}
              >
                Open Passport
              </Button>
              <LoadingButton
                loadingIndicator="Deleting…"
                loading={uploading}
                disabled={!passport && !code}
                onClick={handlePassportRemove}
              >
                Delete Passport
              </LoadingButton>
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            justifyContent: "space-between",
          }}
        >
          <LoadingButton
            disabled={!user?.email}
            onClick={handleRecovery}
            loading={recovery}
            loadingIndicator="Recovering…"
          >
            Recover Password
          </LoadingButton>
          <CryptoSafeCreateDialog
            disabled={!user}
            code={code}
            setCryptoSafe={setCryptoSafe}
          />
        </div>
        <div>
          {cryptoSafe && cryptoSafe.length > 0 && (
            <CryptoSafeCard
              cryptosafe={cryptoSafe}
              setCryptoSafe={setCryptoSafe}
            />
          )}
          {maxiProfit && maxiProfit.length > 0 && (
            <MaxiProfitCard
              maxiprofitArray={maxiProfit}
              setMaxiProfitArray={setMaxiProfit}
            />
          )}
        </div>
      </DialogContent>
    </UserDialogBox>
  );
};
