import React, { useEffect, useState } from 'react'
import {
  appDepositPercentOrCommissionChange,
  appGetAllDeposits,
  appGetSettings,
  appToggleTechnicalWorks,
} from '../../api/settings/appSettings'
import Box from '@mui/material/Box'
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import toast, { Toaster } from 'react-hot-toast'
import { useRecoilState } from 'recoil'
import { mobAppIsAuthorizedAtom } from '../../states/appIsAuthorized'
import LogoutIcon from '@mui/icons-material/Logout'
import { validateAsFloatNumber } from '../../../utils/validateString'
import { APPS } from '../../utils/appConstants'
import SettingsInput from './SettingsInput'

const SettingsView = ({}) => {
  const [isLoggedIn, setLoggedIn] = useRecoilState(mobAppIsAuthorizedAtom)

  const [isFetching, setIsFetching] = useState(true)
  const [isFetchingError, setIsFetchingError] = useState(false)

  const [settings, setSettings] = useState([])
  const [isTechnicalWorks, setIsTechnicalWorks] = useState(
    settings.technical_work,
  )
  const handleChangeTechnicalWorks = () => setIsTechnicalWorks(prev => !prev)

  const [depositSettings, setDepositSettings] = useState([])
  const [fortis, setFortis] = useState({})
  const [crypto, setCrypto] = useState({})
  const [prime, setPrime] = useState({})

  const [fortisPercent, setFortisPercent] = useState(
    fortis?.yield_percentage ?? 0,
  )
  const handleFortisPercentChange = e =>
    validateAsFloatNumber(e.target.value, setFortisPercent)
  const handleFortisPerсentSave = async () => {
    setIsFetching(true)

    const data = {
      yield_percentage: +fortisPercent,
    }

    await appDepositPercentOrCommissionChange(fortis.id, data)
      .then(() => fetchAllDeposits())
      .catch(() => showErrorNotify('Error!'))
      .finally(() => setIsFetching(false))
  }

  const [cryptoPercent, setCryptoPercent] = useState(null)
  const handleCryptoPercentChange = e =>
    validateAsFloatNumber(e.target.value, setCryptoPercent)
  const handleCryptoPerсentSave = async () => {
    setIsFetching(true)

    const data = {
      yield_percentage: +cryptoPercent,
    }

    await appDepositPercentOrCommissionChange(crypto.id, data)
      .then(() => fetchAllDeposits())
      .catch(() => showErrorNotify('Error!'))
      .finally(() => setIsFetching(false))
  }

  const [primePercent, setPrimePercent] = useState(null)
  const handlePrimePercentChange = e =>
    validateAsFloatNumber(e.target.value, setPrimePercent)
  const handlePrimePerсentSave = async () => {
    setIsFetching(true)

    const data = {
      yield_percentage: +primePercent,
    }

    await appDepositPercentOrCommissionChange(prime.id, data)
      .then(() => fetchAllDeposits())
      .catch(() => showErrorNotify('Error!'))
      .finally(() => setIsFetching(false))
  }

  const [primeCommission, setPrimeCommission] = useState(null)
  const handlePrimeCommissionChange = e =>
    validateAsFloatNumber(e.target.value, setPrimeCommission)
  const handlePrimeCommissionSave = async () => {
    setIsFetching(true)

    const data = {
      commission: +primeCommission,
    }

    await appDepositPercentOrCommissionChange(prime.id, data)
      .then(() => fetchAllDeposits())
      .catch(() => showErrorNotify('Error!'))
      .finally(() => setIsFetching(false))
  }

  const handleTechnicalWorksSave = async () => {
    setIsFetching(true)

    await appToggleTechnicalWorks(isTechnicalWorks)
      .then(() => {
        setIsFetchingError(false)
        fetchAllSettings()

        showSuccessNotify(
          `Maintenance page ${isTechnicalWorks ? 'enabled' : 'disabled'}.`,
        )
      })
      .catch(() => {
        setIsFetchingError(true)

        showErrorNotify(
          `Error! Failed to ${
            isTechnicalWorks ? 'enable' : 'disable'
          } maintenance page.`,
        )
      })
      .finally(() => setIsFetching(false))
  }

  const fetchAllSettings = async () => {
    setIsFetching(true)

    await appGetSettings()
      .then(response => {
        setSettings(response.data)
        setIsFetchingError(false)
        setIsTechnicalWorks(response.data.technical_work)
      })
      .catch(error => {
        setIsFetchingError(true)
      })
      .finally(() => setIsFetching(false))
  }

  const fetchAllDeposits = async () => {
    setIsFetching(true)

    await appGetAllDeposits()
      .then(res => {
        setDepositSettings(res.data)

        const f = res.data.find(
          deposit => deposit.type.toLowerCase() === APPS.FORTIS.toLowerCase(),
        )
        const c = res.data.find(
          deposit => deposit.type.toLowerCase() === APPS.CRYPTO.toLowerCase(),
        )
        const p = res.data.find(
          deposit => deposit.type.toLowerCase() === APPS.PRIME.toLowerCase(),
        )

        setFortis(f)
        setFortisPercent(f.yield_percentage)

        setCrypto(c)
        setCryptoPercent(c.yield_percentage)

        setPrime(p)
        setPrimePercent(p.yield_percentage)
        setPrimeCommission(p.commission)
      })
      .catch(() => showErrorNotify('Fetch deposits error!'))
      .finally(() => setIsFetching(false))
  }

  const showSuccessNotify = text => toast.success(text)
  const showErrorNotify = text => toast.error(text)

  useEffect(() => {
    fetchAllSettings()
    fetchAllDeposits()
  }, [])

  return (
    <>
      {isFetchingError ? (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100dvh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5" sx={{ margin: 2 }}>
            Error! Failed to load page, please try later.
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            rowGap: 2,
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              columnGap: 1,
              width: '100%',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={isTechnicalWorks}
                  onChange={handleChangeTechnicalWorks}
                />
              }
              label="Technical works"
              disabled={isFetching || isFetchingError}
            />
            <Button
              sx={{ marginTop: 'auto' }}
              type="contained"
              disabled={
                isFetching || isTechnicalWorks === settings.technical_work
              }
              onClick={handleTechnicalWorksSave}
            >
              Save
            </Button>
            <Button
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 1,
                marginLeft: 'auto',
              }}
              variant="contained"
              onClick={() => setLoggedIn(false)}
            >
              <LogoutIcon />
              LOG OUT from TSFC APP
            </Button>
          </Box>

          {depositSettings.length > 0 && (
            <>
              <SettingsInput
                label="Fortis Percent"
                state={fortisPercent}
                initialState={fortis.yield_percentage}
                handleChange={handleFortisPercentChange}
                handleSave={handleFortisPerсentSave}
              />
              <SettingsInput
                label="Crypto Percent"
                state={cryptoPercent}
                initialState={crypto.yield_percentage}
                handleChange={handleCryptoPercentChange}
                handleSave={handleCryptoPerсentSave}
              />
              <SettingsInput
                label="Prime Percent"
                state={primePercent}
                initialState={prime.yield_percentage}
                handleChange={handlePrimePercentChange}
                handleSave={handlePrimePerсentSave}
              />
              <SettingsInput
                label="Prime Commission"
                state={primeCommission}
                initialState={prime.commission}
                handleChange={handlePrimeCommissionChange}
                handleSave={handlePrimeCommissionSave}
              />
            </>
          )}

          <Backdrop open={isFetching} sx={{ color: '#fff' }}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <Toaster position="top-right" />
        </Box>
      )}
    </>
  )
}

export default SettingsView
