import React, { useState } from 'react'
import { Button } from '@mui/material'
import AppInput from '../../ui/AppInput'
import {
  LS_MOB_APP_ACCESS_TOKEN_KEY,
  LS_MOB_APP_REFRESH_TOKEN_KEY,
  views,
} from '../../utils/appConstants'
import { useRecoilState } from 'recoil'
import { mobAppViewAtom } from '../../states/appViews'
import { mobAppIsAuthorizedAtom } from '../../states/appIsAuthorized'
import { appAccessToken } from '../../api/auth/appAuth'
import toast, { Toaster } from 'react-hot-toast'

const AuthView = () => {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [isSending, setIsSending] = useState(false)

  const [currentView, setCurrentView] = useRecoilState(mobAppViewAtom)
  const [isMobAppAuthorized, setIsMobAppAuthorized] = useRecoilState(
    mobAppIsAuthorizedAtom,
  )

  const showErrorNotify = text => toast.error(text);

  const handleLoginChange = e => setLogin(e.target.value)
  const handlePasswordChange = e => setPassword(e.target.value)
  const handleSubmit = async e => {
    e.preventDefault()
    setIsSending(true)

    try {
      await appAccessToken({
        username: login,
        password,
      }).then(res => {
        localStorage.setItem(LS_MOB_APP_ACCESS_TOKEN_KEY, res.data.accessToken)
        localStorage.setItem(
          LS_MOB_APP_REFRESH_TOKEN_KEY,
          res.data.refreshToken,
        )

        setCurrentView(views.users)
      })
    } catch (error) {
      localStorage.setItem(LS_MOB_APP_ACCESS_TOKEN_KEY, '')
      localStorage.setItem(LS_MOB_APP_REFRESH_TOKEN_KEY, '')

      showErrorNotify('Login error!')
    } finally {
      setIsMobAppAuthorized(!!localStorage.getItem(LS_MOB_APP_ACCESS_TOKEN_KEY))
      setIsSending(false)
    }
  }

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        rowGap: '12px',
        minHeight: '80dvh',
        margin: '0 auto',
      }}
      onSubmit={handleSubmit}
    >
      <AppInput label="Login" value={login} handleChange={handleLoginChange} />
      <AppInput
        label="Password"
        value={password}
        handleChange={handlePasswordChange}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSending}
      >
        Sign in
      </Button>
      <Toaster position="top-right" />
    </form>
  )
}

export default AuthView
