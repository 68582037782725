import React, { useState } from 'react'
import useUserHook from '../useUser.hook'
import toast, { Toaster } from 'react-hot-toast'
import { appDepositTitleChange } from '../../../api/user/appUser'
import Box from '@mui/material/Box'
import AppInput from '../../../ui/AppInput'
import { Button } from '@mui/material'

const UserDepositTitleChange = ({ contractId, title: contractTitle }) => {
  const [title, setTitle] = useState(contractTitle ?? '')
  const handleTitleChange = e => setTitle(e.target.value)

  const [inProgress, setInProgress] = useState(false)
  const { fetchUser } = useUserHook()
  const showErrorNotify = text => toast.error(text)

  const handleSaveChanges = async () => {
    setInProgress(true)

    await appDepositTitleChange(contractId, title)
      .then(() => fetchUser())
      .catch(() => showErrorNotify("Error the title didn't change!"))
      .finally(() => setInProgress(false))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        rowGap: 1,
      }}
    >
      <AppInput
        sx={{ marginBottom: 1 }}
        value={title}
        handleChange={handleTitleChange}
      />
      <Button
        variant="contained"
        disabled={title === contractTitle || inProgress}
        onClick={handleSaveChanges}
      >
        Save changes
      </Button>

      <Toaster position="top-right" />
    </Box>
  )
}

export default UserDepositTitleChange
