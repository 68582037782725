import React, { useState } from 'react'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import AppInput from '../ui/AppInput'
import CopyToClipboard from 'react-copy-to-clipboard'
import { appRemoveUser } from '../api/user/appUser'
import toast, { Toaster } from 'react-hot-toast'
import { LS_MOB_APP_CURRENT_USER_ID_KEY, views } from '../utils/appConstants'
import { useRecoilState } from 'recoil'
import { mobAppViewAtom } from '../states/appViews'

const RemoveUserModal = ({ isOpen, handleClose, user }) => {
  const [currentView, setCurrentView] = useRecoilState(mobAppViewAtom)

  const [input, setInput] = useState('')
  const handleInput = e => setInput(e.target.value)

  const keyToRemove = `${user.id}. ${user.first_name} ${user.last_name}`

  const isActionDisabled = input !== keyToRemove
  const [isRemoving, setIsRemoving] = useState(false)
  const showErrorNotify = text => toast.error(text)
  const handleUserRemove = async () => {
    setIsRemoving(true)

    await appRemoveUser(+user.id)
      .then(() => {
        setCurrentView(views.users)
        localStorage.removeItem(LS_MOB_APP_CURRENT_USER_ID_KEY)
        handleClose()
      })
      .catch(() => showErrorNotify('Error of deleting user!'))
      .finally(() => {
        setIsRemoving(false)
      })
  }

  return (
    <Dialog sx={{ minWidth: '300px' }} open={isOpen} onClose={handleClose}>
      <DialogTitle>Deleting confirmation</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: 2,
        }}
      >
        <DialogContentText>
          Click to copy:{'  '}
          <CopyToClipboard text={keyToRemove}>
            <Chip label={keyToRemove} />
          </CopyToClipboard>
        </DialogContentText>
        <AppInput
          sx={{ minWidth: '300px', width: '100%', marginTop: 2 }}
          focused
          variant="outlined"
          label={`To confirm, type "${keyToRemove}" in the box below`}
          value={input}
          handleChange={handleInput}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isActionDisabled || isRemoving}
          onClick={handleUserRemove}
        >
          Confirm
        </Button>
      </DialogActions>

      <Toaster position="top-right" />
    </Dialog>
  )
}

export default RemoveUserModal
