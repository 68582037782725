import Typography from '@mui/material/Typography'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'

const UserContractPaid = () => {
  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 1,
        height: '100%',
      }}
    >
      <MoneyOffIcon />
      User withdrawn all funds
    </Typography>
  )
}

export default UserContractPaid
