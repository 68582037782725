import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import { Divider } from '@mui/material'
import UserDepositInfo from './user-deposit/UserDepositInfo'
import AppRemoveButton from '../../ui/AppRemoveButton'
import React, { useState } from 'react'
import { appDepositRemove, appPrimeDepositRemove } from '../../api/user/appUser'
import useUserHook from './useUser.hook'
import toast, { Toaster } from 'react-hot-toast'
import { APPS } from '../../utils/appConstants'
import AppConfirmModal from '../../ui/AppConfirmModal'

const UserContractCard = ({
  deposit,
  contractId,
  contract_user,
  avatar,
  avatarBG,
  title,
  subtitle,
  children,
}) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const { fetchUser } = useUserHook()
  const showErrorNotify = text => toast.error(text)

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const handleConfirmModalClose = () => setIsConfirmModalOpen(false)
  const handleConfirmModalOpen = () => setIsConfirmModalOpen(true)
  const handleDepositRemove = async () => {
    setIsDeleting(true)

    appDepositRemove(+deposit.id, deposit.type)
      .then(() => fetchUser())
      .catch(() => showErrorNotify('Deposit removing error!'))
      .finally(() => setIsDeleting(false))
  }
  const handlePrimeDepositRemove = async () => {
    setIsDeleting(true)

    appPrimeDepositRemove(+deposit.id)
      .then(() => fetchUser())
      .catch(() => showErrorNotify('Deposit removing error!'))
      .finally(() => setIsDeleting(false))
  }

  return (
    <Card sx={{ height: 'max-content', position: 'relative' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: avatarBG ?? red[500] }} aria-label="recipe">
            {avatar}
          </Avatar>
        }
        title={`${deposit.id}. ${title}`}
        subheader={subtitle}
      />
      <AppRemoveButton
        sx={{ position: 'absolute', right: '6px', top: '4px' }}
        tooltipText="Remove Deposit?"
        handleClick={handleConfirmModalOpen}
      />
      <Divider />
      <CardContent>
        <UserDepositInfo
          deposit={deposit}
          title={title}
          contract_user={contract_user}
          contractId={contractId}
        />
        <Typography variant="body2" color="text.secondary">
          {children}
        </Typography>
      </CardContent>

      <AppConfirmModal
        isOpen={isConfirmModalOpen}
        isConfirmActionDisabled={isDeleting}
        handleCloseModal={handleConfirmModalClose}
        handleApproveAction={
          deposit.type.toLowerCase() === APPS.PRIME.toLowerCase()
            ? handlePrimeDepositRemove
            : handleDepositRemove
        }
      >
        <Typography variant="subtitle1">
          Confirm deletion of the deposit {deposit.id} with type{' '}
          {deposit.type.toUpperCase()}?
        </Typography>
      </AppConfirmModal>

      <Toaster position="top-right" />
    </Card>
  )
}

export default UserContractCard
