import Box from '@mui/material/Box'
import AppInput from '../../ui/AppInput'
import { Button } from '@mui/material'

const SettingsInput = ({
  label,
  state,
  initialState,
  handleChange,
  handleSave,
}) => {
  const isActionDisabled = initialState?.toString() === state.toString()

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        columnGap: 2,
        rowGap: 1,
      }}
    >
      <AppInput
        sx={{ minWidth: '300px' }}
        variant="outlined"
        label={label}
        value={state}
        handleChange={handleChange}
      />
      <Button type="contained" disabled={isActionDisabled} onClick={handleSave}>
        Save
      </Button>
    </Box>
  )
}
export default SettingsInput
