export const formatDateToString = dateString => {
  const dateObject = new Date(dateString)

  const year = dateObject.getUTCFullYear()
  let day = dateObject.getUTCDate()
  let month = dateObject.getUTCMonth() + 1
  let hours = dateObject.getUTCHours()
  let minutes = dateObject.getUTCMinutes()
  let seconds = dateObject.getUTCSeconds()

  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day
  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
}
