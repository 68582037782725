import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Launch, Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authAtom, newAtom, unverifiedAtom, verifiedAtom } from "../states";
import { ReactComponent as LogoWhite } from "../assets/logo_white.svg";
import { SearchField } from "./SearchField";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Company = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const Icons = styled("div")(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const Navbar = ({ handleDrawerToggle }) => {
  let navigate = useNavigate();
  const setAuth = useSetRecoilState(authAtom);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const _new = useRecoilValue(newAtom);
  const unverified = useRecoilValue(unverifiedAtom);
  const verified = useRecoilValue(verifiedAtom);

  return (
    <AppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      position="fixed"
    >
      <StyledToolbar>
        <Company>
          <IconButton
            onClick={handleDrawerToggle}
            aria-label="open drawer"
            edge="start"
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <LogoWhite width={30} height={20} />
          </IconButton>
          <IconButton
            onClick={() => window.open("https://da-capital.io", "_blank")}
            aria-label="open drawer"
            edge="start"
            style={{ padding: "8px", marginRight: "16px", cursor: "pointer" }}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <LogoWhite width={30} height={20} />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              display: {
                xs: "none",
                md: "block",
                flexGrow: 1,
                fontFamily: "Montserrat",
              },
            }}
            style={{ userSelect: "none" }}
          >
            DAC Admin
          </Typography>
        </Company>
        <SearchField
          array={[..._new, ...unverified, ...verified].filter(
            (
              (s) => (o) =>
                ((k) => !s.has(k) && s.add(k))(
                  ["code"].map((k) => o[k]).join("|")
                )
            )(new Set())
          )}
        />
        <Icons>
          <IconButton
            size="small"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => window.open("https://da-capital.io", "_blank")}
          >
            <Tooltip title="Open DAC">
              <Launch />
            </Tooltip>
          </IconButton>
          <IconButton
            size="small"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => {
              navigate("login", { replace: true });
              localStorage.removeItem("user");
              setAuth("");
            }}
          >
            <Tooltip title="Logout">
              <Logout />
            </Tooltip>
          </IconButton>
          <Avatar
            src="https://s3.da-capital.io/static/extra/admin.jpg"
            width="30"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
          />
        </Icons>
        <UserBox onClick={handleClick} style={{ cursor: "pointer" }}>
          <Avatar
            src="https://s3.da-capital.io/static/extra/admin.jpg"
            width="30"
          />
          <Typography
            variant="span"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Manager
          </Typography>
        </UserBox>
      </StyledToolbar>
      <StyledMenu
        anchorEl={anchorEl}
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => window.open("https://da-capital.io", "_blank")}
          disableRipple
        >
          <Launch />
          Open DAC
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            localStorage.removeItem("user");
            setAuth("");
            navigate("login", { replace: true });
          }}
          disableRipple
        >
          <Logout />
          Logout
        </MenuItem>
      </StyledMenu>
    </AppBar>
  );
};

export default Navbar;
